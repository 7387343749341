import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { COLORS } from 'config/constants';
import { Link } from '@material-ui/core';
import Modal from 'components/Modal';

const useStyles = makeStyles((theme) => ({
  checkLabel: {
    color: COLORS.white,
    font: 'normal normal 300 12px/14px Roboto',
    width: 290,
    lineHeight: '50px',
    '& a:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      minWidth: 'fit-content'
    }
  },
  tacWrap: {
    width: '85vw',
    maxWidth: 800,
    height: '80vh',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(3)
    }
  },
  tacBody: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  }
}));

const AgreeTermLabel = () => {
  const classes = useStyles();
  const [tacModal, setTacModal] = useState(0);

  const getTacUrl = () => {
    switch (tacModal) {
      case 1:
        return '/htmls/terms_of_service.html';
      case 2:
        return '/htmls/privacy_policy.html';
      default:
        return null;
    }
  }

  const modalTacClose = () => {
    setTacModal(0);
  };

  return (
    <>
      <span className={classes.checkLabel}>
        <Link
          color="inherit"
          onClick={() => setTacModal(1)}
          underline="always"
        >
          Terms of Use
        </Link>
        &nbsp;and&nbsp;
        <Link
          color="inherit"
          onClick={() => setTacModal(2)}
          underline="always"
        >
          Privacy Policy.
        </Link>
      </span>
      <Modal
        body={
          (
            <iframe
              frameBorder={0}
              height="100%"
              src={getTacUrl()}
              width="100%"
            />
          )
        }
        classes={{
          wrap: classes.tacWrap,
          body: classes.tacBody,
        }}
        maxWidth={'xl'}
        onClose={modalTacClose}
        open={tacModal > 0}
      />
    </>
  );
};

AgreeTermLabel.defaultProps = {
  className: null,
};

AgreeTermLabel.propTypes = {
  className: PropTypes.string,
};

export default AgreeTermLabel;
