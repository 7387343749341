import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Typography, TextField } from '@material-ui/core';
import { CustomButton } from 'components';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import MessageRow from './MessageRow';
import styles from './styles';

const useStyles = makeStyles(styles);

const ChatBox = ({ lessonName, title, discussionText, messages, onKeyPress, handleChange, handleSubmit, message, disabled, classNames }) => {
  const classes = useStyles();
  const bottomRef = useRef(null);

  useEffect(() => {
    if (messages.length) {
      bottomRef.current?.scrollToItem(messages.length);
    }
  }, [messages, bottomRef.current])

  const getItemSize = (index) => {
    // default size of MessageRow item
    let itemSize = 95;

    // vars for calculating additonal size for longer messages
    const charsBeforeLineBreak = 33;
    const extraLineHeight = 20;
    const msgCharSize = messages.length && messages[index].message.length;
    const extraLines = Math.round(msgCharSize / charsBeforeLineBreak);

    // check if msgCharSize will break message onto multiple lines 
    // and calculates item size based on if item has extra lines
    if (extraLines < 1) {
      return itemSize;
    } else {
      return itemSize += extraLineHeight * extraLines;
    }
  }
  return (
    <div className={clsx(classes.discussionContainer, classNames?.root)}>
      <div className={clsx(classes.discussionTitleWrapper, classNames?.header)}>
        <p className={disabled ? clsx(classes.discussionTitle, classes.disabledColor): classes.discussionTitle}>{title}</p>
      </div>
      <div>
        <div className={clsx(classes.discussionBoardWrapper, classNames?.content)}>
          {lessonName?.length > 0 && discussionText?.length > 0 && (
            <div className={classes.subTitleLine}>
              <Typography className={clsx(classes.subtitle, classes.txtLessonName)}>
                {lessonName}:&nbsp;
              </Typography>
              <Typography className={clsx(classes.subtitle)}>
                {ReactHtmlParser(discussionText)}
              </Typography>
            </div>
          )}
          <div style={{ flex: '1 1 auto', height: '100%', width: '100%' }}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className="List"
                  height={height}
                  itemCount={messages.length}
                  itemData={messages}
                  itemSize={getItemSize}
                  ref={bottomRef}
                  width={width}
                >
                  {MessageRow}
                </List>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
      <div
        className={clsx(classes.discussionBoardFooter, classNames?.footer)}
        style={disabled ? { pointerEvents: 'none'} : {}}
      >
        <TextField
          InputProps={{ disableUnderline: true, onKeyPress: onKeyPress, inputProps: { maxLength: 500 } }}
          fullWidth
          onChange={handleChange}
          placeholder="Message"
          value={message}
        />
        <CustomButton
          className={disabled ? clsx(classes.sendBtn, classes.disabledButton): classes.sendBtn}
          disabled={disabled}
          onClick={handleSubmit}
          rounded
          title="SEND"
        />
      </div>
    </div>
  );
};
ChatBox.defaultProps = {
  messages: [],
  disabled: false,
  lessonName: '',
  classNames: {}
}

ChatBox.propTypes = {
  disabled: PropTypes.bool,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number,
      name: PropTypes.string,
      message: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  lessonName: PropTypes.string,
  discussionText: PropTypes.string,
  message: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  classNames: PropTypes.object,
}
export default ChatBox;
