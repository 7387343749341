import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
});

const SmallProgress = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.top}
        size={24}
        thickness={4}
        value={100}
        variant="determinate"
        {...props}
      />
      <CircularProgress
        className={classes.bottom}
        disableShrink
        size={24}
        thickness={4}
        variant="indeterminate"
        {...props}
      />
    </div>
  );
};

SmallProgress.defaultProps = {

};

SmallProgress.propTypes = {

};

export default SmallProgress;
