import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import TradingBanner from '../TradingBanner';
import styles from './styles';

const useStyles = makeStyles(styles);

/**
 * Magic Container: sets the responsive horizontal padding
 * Here are props to use.
 * @leftOnly: {bool} apply only left padding
 * @rightOnly: {bool} apply only right padding
 * @top: {bool} sets the top padding of the container
 * @debug: {bool} shows border black if TRUE
 * @styles: {object} sets the styles of the container:
 *    you can set background color or background image
 * */
const MagicContainer = props => {
  const classes = useStyles();
  const both = !props.leftOnly && !props.rightOnly;
  const mainCls = clsx(classes.main, {
    [classes.topWrap]: props.top,
    [classes.leftOnly]: props.leftOnly || both,
    [classes.rightOnly]: props.rightOnly || both,
    [classes.debugWrap]: props.debug,
  });
  const contentCls = clsx('debug--magic');
  return (
    <div
      className={clsx(classes.root, props.className)}
      style={props.styles}
    >
      <div
        className={mainCls}
      >
        <div className={contentCls}>
          {props.children}
        </div>
      </div>
      {props.banner && <TradingBanner className={classes.tradingBannerWrap}/>}
    </div>
  );
};

MagicContainer.defaultProps = {
  styles: null,
  top: false,
  leftOnly: false,
  rightOnly: false,
  banner: false,
};

MagicContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  debug: PropTypes.bool,
  leftOnly: PropTypes.bool,
  rightOnly: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.any),
  top: PropTypes.bool,
  banner: PropTypes.bool,
};

export default MagicContainer;
