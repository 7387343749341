import { colors } from '@material-ui/core';
import { COLORS } from 'config/constants';

const white = COLORS.white;
const black = COLORS.black;

export default {
  type: 'light',
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: COLORS.lightBlue,
    main: COLORS.lightBlue,
    light: COLORS.lightBlue
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    // primary: white,
    secondary: colors.grey[900],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: colors.common.white
  },
  divider: colors.grey[200]
};
