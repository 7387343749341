import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { COLORS, FONTS } from 'config/constants';
import { DESIGN_UTILS } from 'utils';
import { GridContainer, GridItem } from '../..';

const pxToRem = DESIGN_UTILS.pxToRem;

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'left',
    margin: theme.spacing(3, 0, 12),
    minWidth: 374,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3, 0, 14),
    },
    [theme.breakpoints.down('sm')]: {
      margin:  '-42px 0px 32px',
      minWidth: 352
    },
    [theme.breakpoints.down('xs')]: {
      margin:  '-42px 0px 5px',
      minWidth: 'fit-content'
    },
  },
  mainText: {
    color: COLORS.white,
    fontFamily: 'Oswald',
    fontWeight: 400,
    fontSize: 36,
    letterSpacing: '-0.2px',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
      textAlign: 'center',
      marginTop: '-40px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      marginTop: '10px'
    }
  },
  description: {
    color: COLORS.white,
    fontFamily: FONTS.SourceSansPro,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.9,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      textAlign: 'center',
    }
  },
  link: {
    fontSize: pxToRem(12),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(12)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(10)
    },
  },
  title: {
    fontSize: pxToRem(20),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(18)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(16)
    },
  },
  subtitle: {
    fontSize: pxToRem(10),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(9)
    }
  }
}));

const FooterLeft = (props) => {
  const { description } = props;
  const classes = useStyles();

  return (
    <GridContainer
      className={classes.root}
      justifyContent="center"
      spacing={0}
    >
      <GridItem
        m={10}
        xs={12}
      >
        <Typography className={classes.mainText}>{'OUR ALTRUISTIC APPROACH'}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </GridItem>
    </GridContainer>
  );
};

FooterLeft.propTypes = {
  description: PropTypes.string,
};

export default FooterLeft;
