import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';

const useStyles = makeStyles(styles);

const BannerFlex = props => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.gradient && classes.gradient, props.className)}>
      {
        props.hasLogo && (
          <Box className={clsx(classes.logoWrapper, props.classes.logo)}>
            <img
              alt="transparent logo"
              className={classes.transparentLogo}
              src="/images/white_fintron.png"
            />
          </Box>
        )
      }
      {props.children}
    </div>
  );
};

BannerFlex.defaultProps = {
  classes: {
    logo: null
  },
  gradient: false,
  hasLogo: false,
};

BannerFlex.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.shape({
    logo: PropTypes.string,
  }),
  gradient: PropTypes.bool,
  hasLogo: PropTypes.bool,
};

export default BannerFlex;
