import { alpha, withStyles } from '@material-ui/core/styles';
import { InputBase, colors } from '@material-ui/core';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(5),
    },
    width: '100%',
  },
  multiline: {
    padding: 0,
  },
  input: {
    color: colors.grey[800],
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    // border: '1px solid #ced4da',
    fontSize: 17,
    // width: 'auto',
    padding: '12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0rem`,
      borderColor: theme.palette.primary.main,
      backgroundColor: `${theme.palette.white} !important`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 8,
      fontSize: 15,
    },
  },
}))(InputBase);

export default BootstrapInput;
