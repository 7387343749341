import { all, call, put, takeLatest } from 'redux-saga/effects';
import { UserApi } from 'api';
import PointsActions from './actions';

export function* GET_LATEST_POINTS({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(UserApi.getPointDetails(userId));
    if (response.status === 200) {
      const { available_points } = response.data;

      yield put(PointsActions.setLatestPoints(available_points));
    }
  } catch (err) {
    console.log('Failed to fetch user point details', err);
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(PointsActions.getLatestPoints, GET_LATEST_POINTS),
  ]);
}