import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import ReactQuill from 'react-quill';
import { FormHelperText } from '@material-ui/core';

const FormRichText = (props) => {
  const { name, fieldProps, minLength, maxLength, helperText } = props;

  function validate(value) {
    const content = value ? value.replace(/(<([^>]+)>)/ig, '') : '';
    if (!content.length && minLength) return 'This field is required.';
    if (minLength && content.length < minLength) return `You have to input at least ${maxLength} characters`;
    if (maxLength && content.length > maxLength) return `You have exceeded the ${maxLength} Max. Character Limit`;
    return undefined;
  }

  return (
    <Field
      validate={validate}
      {...fieldProps}
      name={name}
    >
      {({ input: { value, onChange, onBlur, onFocus }, meta}) => {
        const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

        return (
          <>
            <ReactQuill
              onBlur={onBlur}
              onChange={(newVal, delta, source) => {
                if (source === 'user') {
                  onChange(newVal)
                }
              }}
              onFocus={onFocus}
              value={value}
              {...props}
            />
            <FormHelperText style={{color: 'red'}}>{showError ? meta.error || meta.submitError : helperText}</FormHelperText>
          </>
        )
      }}
    </Field>
  )
};

FormRichText.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  fieldProps: PropTypes.object,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};

export default FormRichText;
