import { all, call, put, takeLatest } from 'redux-saga/effects';
import { replace } from 'react-router-redux';

import { analytics, sentry } from 'utils';
import { removeCookie, setCookie } from 'utils/cookie';
import { CONSTANTS } from 'config/global';
import { setAuthHeaders, resetAuthHeaders } from 'api/http';
import { AuthApi } from 'api';
import SessionActions from './actions';

export function* GET_ZOGO_TOKEN() {
  try {
    const response = yield call(AuthApi.getZogoToken());
    if (response.status === 200) {
      setCookie(CONSTANTS.ZOGO, response.data.zogo_token);
    }
  } catch (err) {
    console.log('Failed to get Zogo token', err);
  }
}

export function* LOGIN({ payload }) {
  const { user, token, redirect } = payload;
  try {
    if (user && token) {
      setCookie(CONSTANTS.TOKEN, token);
      setAuthHeaders(`JWT ${token}`);
      yield put(SessionActions.getZogoToken());
      analytics.identify(`${user.id}`, { name: user.username });
      sentry.setUser(user);
      if (redirect) yield put(replace(redirect));
    }
  } catch (err) {
    yield put(SessionActions.logout(redirect));
  }
}

export function* LOGOUT({ payload }) {
  analytics.reset();
  sentry.clearUser();
  removeCookie(CONSTANTS.TOKEN);
  removeCookie(CONSTANTS.ZOGO);
  resetAuthHeaders();

  const { redirect } = payload;
  if (redirect) {
    yield put(replace(`/public/login?redirect=${redirect}`));
  } else {
    yield put(replace('/'));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(SessionActions.login, LOGIN),
    takeLatest(SessionActions.logout, LOGOUT),
    takeLatest(SessionActions.getZogoToken, GET_ZOGO_TOKEN),
  ]);
}
