
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, FormHelperText, TextField as MuiTextField, makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import styles from '../styles';
import BootstrapInput from '../BootstrapInput';

const useStyles = makeStyles(styles);

export default function TextFieldWrapper(props) {
  const classes = useStyles();
  const {
    input: { name, onChange, value, type = 'text', ...restInput },
    className,
    meta,
    mui,
    label,
    white,
    isFull = true,
    ...rest
  } = props;

  const { helperText, ...lessRest } = rest;
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || value);
  if (mui) {
    return (
      <MuiTextField
        error={showError}
        fullWidth={isFull}
        helperText={showError ? meta.error || meta.submitError : helperText}
        inputProps={restInput}
        label={label}
        margin="normal"
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        {...lessRest}
      />
    )
  }
  return (
    <FormControl
      className={className}
      error={showError}
      fullWidth={isFull}
    >
      <InputLabel
        className={clsx(classes.label, white && classes.white)}
        htmlFor={name}
        shrink
      >
        {label}
      </InputLabel>
      <BootstrapInput
        inputProps={restInput}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        {...lessRest}
      />
      <FormHelperText>{showError ? meta.error || meta.submitError : helperText}</FormHelperText>
    </FormControl>
  );
}

TextFieldWrapper.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
  className: PropTypes.any,
  mui: PropTypes.bool,
  label: PropTypes.string,
  white: PropTypes.bool,
  isFull: PropTypes.bool,
};
