import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.75)'
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.6)'
    }
  }
}));

const ResponsiveIcon = props => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.root}>
      {props.children}
    </SvgIcon>
  );
};

ResponsiveIcon.defaultProps = {

};

ResponsiveIcon.propTypes = {
  children: PropTypes.node
};

export default ResponsiveIcon;
