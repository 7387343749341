
const styles = () => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh'
  },
  paper: {
    height: '400vh',
    border: '1px dotted pink',
    backgroundColor: 'rgba(0,0,0,0)'
  }
});

export default styles
