import { Group } from '@vx/group';
import { RadialGradient } from '@vx/gradient';
import { useWindowDimensions } from 'utils';

function PieArc({ idx, arc, pie, transform, style, fill, onMouseEnter, onMouseLeave }) {
  const opacity = 1;

  return (
    <g>
      <path
        d={pie.path(arc)}
        fill={fill}
        fillOpacity={opacity}
        idx={idx}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
        transform={transform}
         
      />
    </g>
  );
}
const PieChartFallback = () => {
  const { width } = useWindowDimensions();
  const handleSmScreens = () => {
    if (width < 560) {
      return 'translate(235.758 -163.059)'
    } else if (width < 1200) {
      return 'translate(295.758 -163.059)'
    } else {
      return 'translate(375.758 -163.059)'
    }
  }
  return (
    <>
      <RadialGradient
        from="#ccc"
        fromOffset={'29%'}
        id="gradient-0"
        r="50%"
        to="#e6e6e6"
      />
      <Group
        style={{transform: 'scale(0.4)'}}
        transform="translate(-424.465 -1464.822)"
      >
        <g
          id="budget-graph"
          transform="translate(-424.465 -1464.822)"
        > 
          <path
            d="M600.254,1735.533q-2.206-5.064-4.7-9.946c-.094-.185-.2-.363-.289-.548a179.062,179.062,0,0,0-40.822-52.362c-2.054-1.812-4.137-3.591-6.273-5.306a179.093,179.093,0,0,0-17.5-12.41q-6.84-4.263-14.068-7.907-2.406-1.212-4.854-2.355c-2.3-1.075-4.642-2.086-7-3.065a179.19,179.19,0,1,0,95.508,93.9ZM435.936,1857.449a50.339,50.339,0,1,1,50.34-50.338A50.338,50.338,0,0,1,435.936,1857.449Z"
            data-name="Path 129"
            fill="url(#gradient-0)"
            id="Path_129"
            transform={handleSmScreens()}
          />
        </g>
      </Group>
    </>
  )
}
export {
  PieChartFallback,
  PieArc
}