import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { RadioWrapper } from './Wrapper';
import { COLORS } from 'config/constants';

export default function Radios(props) {
  const {
    required,
    label,
    data,
    name,
    formLabelProps,
    fieldProps,
    formControlProps,
    formControlLabelProps,
    radioGroupProps,
    formHelperTextProps,
    labelWrapClassName,
    customLabelClassName
  } = props;

  const [error, setError] = useState(null);

  return (
    <FormControl
      error={!!error}
      margin="normal"
      required={required}
      {...formControlProps}
    >
      {label !== undefined ? <FormLabel {...formLabelProps}>{label}</FormLabel> : <></>}
      <RadioGroup {...radioGroupProps}>
        {data.map((item, idx) => (
          <div
            className={labelWrapClassName}
            key={idx}
          >
            <FormControlLabel
              control={
                <Field
                  name={name}
                  render={fieldRenderProps => {
                    const { meta } = fieldRenderProps;

                    const showError =
                      ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
                      meta.touched;

                    setError(showError ? fieldRenderProps.meta.error : null);

                    return <RadioWrapper {...fieldRenderProps} />;
                  }}
                  type="radio"
                  {...fieldProps}
                />
              }
              label={item.label}
              value={item.value}
              {...formControlLabelProps}
            />
            {item.show && (
              <Typography
                className={customLabelClassName}
                style={{ color: COLORS.lightBlue}}
                variant={'h4'}
              >
                {item.show}
              </Typography>
            )}
          </div>
        ))}
      </RadioGroup>
      {error ? <FormHelperText {...formHelperTextProps}>{error}</FormHelperText> : <></>}
    </FormControl>
  );
}

Radios.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
  formLabelProps: PropTypes.objectOf(PropTypes.any),
  formControlProps: PropTypes.objectOf(PropTypes.any),
  fieldProps: PropTypes.objectOf(PropTypes.any),
  radioGroupProps: PropTypes.objectOf(PropTypes.any),
  formHelperTextProps: PropTypes.objectOf(PropTypes.any),
  formControlLabelProps: PropTypes.objectOf(PropTypes.any),
  customLabelClassName: PropTypes.string,
  labelWrapClassName: PropTypes.string
}