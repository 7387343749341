const formatDecimal = x => {
  return Math.round(x * 1e2) / 1e2;
};

const formatPercentDecimal = x => {
  return Math.round(x * 1e4) / 1e2;
};

const thousandFormat = (value) => {
  if (!value) return '0.00';

  return parseFloat(value)
    .toFixed(2) // always two decimal digits
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
};

const thousandFormatWithSign = (value, sign = '$') => {
  let formattedValue = '0.00';
  if (value && !isNaN(value)) {
    const parsedValue = parseFloat(value);
    formattedValue = Math.abs(parsedValue).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    if (parsedValue < 0) return `-${sign}${formattedValue}`;
  }
  return sign + formattedValue;
}

const domainFromUrl = (url) => {
  let result;
  let match;
  // eslint-disable-next-line no-cond-assign
  if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im)) {
    result = match[1]
    // eslint-disable-next-line no-cond-assign
    if (match = result.match(/^[^.]+\.(.+\..+)$/)) {
      result = match[1]
    }
  }
  return result
}

const generateArray = (length) => Array.from(Array(length).keys());

function checkForDuplicates(array) {
  return new Set(array).size !== array.length
}

function prependZeroNumberFormat(number) {
  return  number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}
function formatData (arr) {
  return arr.map((i, index) => ({ key: `${index}`, label: i, value: i}))
}
export {
  formatDecimal,
  formatPercentDecimal,
  thousandFormat,
  thousandFormatWithSign,
  domainFromUrl,
  generateArray,
  checkForDuplicates,
  prependZeroNumberFormat,
  formatData
};
