import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Modal from 'components/Modal';
import CustomButton from 'components/Button';
import { COLORS } from 'config/constants';


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 26,
  },
  infoIcon: {
    fill: 'white',
    width: 20,
    height: 20,
    marginTop: -3
  },
  modalButtonContainer: {
    margin: '45px 30px 0',
    '& button': {
      width: 334,
      height: 45,
      boxShadow: '0px 2px 4px #00000040',
      borderRadius: 23,
      '& span': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 21,
        lineHeight: '24px',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: 2.1,
      },
      [theme.breakpoints.down('sm')]: {
        width: 247,
        height: 33,
        '& span': {
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 14,
          lineHeight: '16px',
          fontFamily: 'Roboto, sans-serif',
          letterSpacing: 1.4,
        }
      }
    }
  },
  infoModalPaper: {
    borderRadius: 26,
  },
  infoModalWrapper: {
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 26,
    width: 462,
    height: 439,
    '& h5': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '26px',
      fontFamily: 'Roboto, sans-serif',
      letterSpacing: 0.4,
      color: '#0B243F',
      textTransform: 'uppercase',
      padding: '5px 0 10px'
    },
    '& img': {
      marginBottom: 4
    },
    [theme.breakpoints.down('sm')]: {
      width: 354,
      height: 473
    }
  },
  infoLable: {
    color: COLORS.white,
    fontSize: 18,
    letterSpacing: 0.36,
    lineHeight: '24px',
    paddingBottom: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'Roboto, sans-serif',
      letterSpacing: 0.28
    }
  },
  iconBtnWrap: {
    padding: 5,
  },
}));

const InfoModal = (props) => {
  const { label, title, body } = props
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const _toggleModal = () => {
    setOpen(!open);
  }

  return (
    <>
      <div className={props.className}>
        <span className={classes.infoLable}>{label}</span>
        <IconButton
          className={classes.iconBtnWrap}
          ml={2}
          onClick={_toggleModal}
        >
          <InfoOutlinedIcon className={classes.infoIcon} />
        </IconButton>
      </div>
      <Modal
        body={body}
        bordered
        classes={{
          wrap: classes.infoModalWrapper
        }}
        footer={
          <div className={classes.modalButtonContainer}>
            <CustomButton
              onClick={_toggleModal}
              rounded
              title="Close"
            />
          </div>
        }
        icon="/images/my-info-iconog.png"
        id="more-data"
        onClose={_toggleModal}
        open={open}
        title={title}
      />
    </>
  );
};

InfoModal.defaultProps = {
  className: null
};

InfoModal.propTypes = {
  className: PropTypes.string,
  body: PropTypes.node,
  title: PropTypes.string,
  label: PropTypes.string,
  labelRootClass: PropTypes.string,
};

export default InfoModal;
