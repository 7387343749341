import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import _ from 'lodash'

import { DESIGN_UTILS, RequestHelper, useWindowDimensions, useIsomorphicLayoutEffect } from 'utils';
import { PortfolioContext } from 'context/PortfolioContext';
import { SecurityApi } from 'api';
import { DetailTable } from 'components';
import styles from './styles';

const ModalSlides = lazy(() => import('components/ModalSlides'));
const useStyles = makeStyles(styles);

const StockPicker = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const requestHelper = new RequestHelper();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { modal, setModal } = useContext(PortfolioContext);
  const { width } = useWindowDimensions();
  const { pdX } = DESIGN_UTILS.calDesignPixels(width);
  const [chartUI, setChartUI] = useState({
    height: 275,
    width: 476,
  });
  const [news, setNews] = useState({});
  const [marketData, setMarketData] = useState({});

  useIsomorphicLayoutEffect(() => {
    setModal({open: false, security: null, selectedIdx: null});
  },[]);

  useEffect(() => {
    if (mobileDevice && width >= 560) {
      const w = width - 3.15 * pdX;
      setChartUI({
        width: 385,
        height: w * 0.85
      })
    } else if (mobileDevice  && width < 560) {
      const w = width - 3.75 * pdX;
      setChartUI({
        width: w,
        height: w * 0.75
      })
    } else {
      setChartUI({
        height: 275,
        width: 476
      })
    }
  }, [width]);

  const fetchMore = _.debounce(() => {
    if (props.fetching) return;
    props.loadMore();
  }, 300, { leading: true });

  useEffect(() => {
    function getSecurity(securityId) {
      requestHelper
        .registerRequest(SecurityApi.findById(securityId))
        .then(res => {
          setMarketData(res.data);
        })
        .catch(err => {
          console.log('---err', err);
        });
    }

    function getNews(securityId) {
      requestHelper
        .registerRequest(SecurityApi.getNews(securityId))
        .then(res => {
          setNews(res.data);
        })
        .catch(err => {
          console.log('---err', err);
        });
    }

    if (modal?.security && modal.security?.id) {
      getNews(modal.security.id);
      getSecurity(modal.security.id);
    }
  }, [modal.security])

  const tableHeaders = ['Stock Name', 'Ticker', 'Buy', 'Price', 'Daily Change', '% Change', 'Info', 'Data', 'Chart', 'News'];

  return (
    <>
      <DetailTable
        className={classes.newTheadRow}
        contestUser={props.contestUser}
        fetchItems={fetchMore}
        hasMoreItems={props.hasMore}
        isLoading={props.fetching}
        isOwner={false}
        searchPage
        securities={props.securities}
        tableHeaders={tableHeaders}
      />
      <Suspense fallback={<p />}>
        <ModalSlides
          chartUI={chartUI}
          contestUser={props.contestUser}
          modalMktData={marketData}
          modalNews={news}
        />
      </Suspense>
    </>
  );
}

StockPicker.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  contestUser: PropTypes.objectOf(PropTypes.any),
  hasMore: PropTypes.bool,
  fetching: PropTypes.bool,
  loadMore: PropTypes.func,
  securities: PropTypes.arrayOf(PropTypes.any)
}

export default StockPicker;
