import React, { useEffect, useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, Button } from '@material-ui/core';
import DehazeRoundedIcon from '@material-ui/icons/DehazeRounded';
import { makeStyles } from '@material-ui/styles';

import { useWindowDimensions } from 'utils';
import { COLORS } from 'config/constants';
import CustomDropdown from '../CustomDropdown';
import ZogoPointsButton from '../ZogoPointsButton';
import magicStyles from '../MagicContainer/styles';
import Menu from './data';
import { PointsActions } from 'store/actions';
const NavDrawer = lazy(() => import('./NavDrawer'));

const useStyles = makeStyles(theme => ({
  ...magicStyles(theme),
  root: {
    background: 'transparency',
    position: 'relative',
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  logoContainer: {
    width: 138,
    '& img': {
      width: '100%',
      imageRendering: 'auto',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '5%',
    },
  },
  disabledLogoContainer: {
    touchAction: 'none',
    pointerEvents: 'none',
    imageRendering: 'auto',
    '& img': {
      width: 138,
      touchAction: 'none',
      pointerEvents: 'none',
    },
  },
  menuBar: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      backgroundColor: 'transparent !important',
    },
  },
  menuButton: {
    color: COLORS.white,
    marginBottom: '-3px',
    fontSize: '12px',
    fontWeight: 900,
    padding: '7px 7px',
    borderRadius: 0,
    margin: 0,
    textTransform: 'uppercase',
    display: 'none',
    boxShadow: 'none',
    '& > span': {
      lineHeight: '20px',
      height: '20px',
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:focus': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: 2,
      padding: theme.spacing(1, 2),
      fontSize: 14,
    },

  },
  myLoginBtn: {
    color: '#26A7DF',
    backgroundColor: 'transparent',
    marginBottom: '-3px',
    fontSize: '12px',
    fontWeight: 900,
    padding: '7px 7px',
    borderRadius: 0,
    margin: 0,
    textTransform: 'uppercase',
    display: 'none',
    boxShadow: 'none',
    '&:focus': {
      borderRadius: '25px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: 2,
      padding: theme.spacing(1, 2),
      fontSize: 14,
    },
  },
  activeMenu: {
    color: COLORS.white,
  },
  signupButton: {
    border: '1px solid white',
    color: '#26A7DF',
    borderRadius: '15px',
    minWidth: '97px',
    padding: 0,
    margin: 0,
    height: '20px',
    marginLeft: 15,
    marginTop: 2,
    backgroundColor: '#FFFFFF',
    '& svg': {
      marginBottom: '2px',
      height: 20,
    },
    '&:target': {
      '& > span': {
        marginBottom: '5px',
        fontSize: '12px',
        letterSpacing: '1.2px'
      },
    },
    '&:active': {
      backgroundColor: '#FFFFFF',
    },
    '&:focus': {
      '& > span': {
        fontSize: '12px',
        letterSpacing: '1.2px'
      },
    },
    '& > span': {
      height: '20px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '14px',
      fontFamily: 'Roboto, sans-serif',
      paddingLeft: 10,
      letterSpacing: '1.2px'
    },
  },
  hamburgerIcon: {
    display: 'block',
    color: COLORS.white,
    fontSize: 28,
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dropdownLink: {
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    lineHeight: '24px',
    padding: '0 10px',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '12px',
    color: 'white',
    letterSpacing: '1.2px',
    '&:hover': {
      padding: '0 10px',
      width: '100%'
    },
  },
  navLink: {
    color: 'inherit',
    position: 'relative',
    padding: '0.9375rem',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'rgba(200, 200, 200, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  stickyIcon: {
    width: '63px',
    height: '63px',
    borderRadius: '50%',
    top: 20,
    /* UI Properties */
    background: 'transparent linear-gradient(180deg, #51A5DA 0%, #4B87C4 0%, #466DB1 100%, #404C99 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000029',
    position: 'fixed',
    right: 10,
    marginLeft: '50%',
    zIndex: 9999,
    transition: '0.5s all ease-out',
    '&:hover': {
      cursor: 'pointer',
    },
    '& > svg': {
      fill: '#FFFFFF',
      fontSize: 36,
      margin: '5% 22%',
      height: 60,
    },
  },
}));

const Header = (props) => {
  const { path, sticky } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authorized, user } = useSelector(({ session }) => session);
  const { points } = useSelector(({ points }) => points);

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const [prevPoints, setPrevPoints] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (authorized && user.id) {
      dispatch(PointsActions.getLatestPoints({ userId: user.id }));
    }
  },[authorized, user.id])

  // if store's points are greater than 0, set stored prevPoints state
  useEffect(() => {
    if (points) {
      setPrevPoints(points);
    }
  },[points])

  /*
    Check if store's points are greater than local stored
    prevPoints to trigger points increase animation and
    checks prevPoints value is greater than
    initial state value of 0 to prevent animating on login.
  */
  useEffect(() => {
    if (prevPoints > 0 && points > prevPoints) {
      setShouldAnimate(true);
    }
  },[points, prevPoints])

  // Reset shouldAnimate state after 3 full pulse rotations
  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => {
        setShouldAnimate(false);
      }, 6000)
    }
  },[shouldAnimate])

  /*
    Store prevValue state in localstorage to handle edge case of
    different users logging in and out while
    using the same machine without closing browser.
  */
  useEffect(() => {
    setPrevPoints(JSON.parse(localStorage.getItem('prev-points') || 0));
  }, []);

  useEffect(() => {
    localStorage.setItem('prev-points', prevPoints);
  }, [prevPoints]);
  useEffect(() => {
    if (authorized && user.is_teacher) {
      setMenuItems(Menu.teacherAuthItems);
    } else if (authorized) {
      setMenuItems(Menu.userAuthItems);
    } else {
      setMenuItems(Menu.menuItems);
    }
  }, [authorized, user.is_teacher]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    handleDrawerClose();
  }, [location.pathname])

  const headerCls = clsx(classes.root, classes.topWrap, classes.leftOnly, classes.rightOnly);
  const navigate = useNavigate();
  function getDropdownList(items) {
    return items.map(i => (
      <Link
        className={classes.dropdownLink}
        key={i.title}
        to={i.redirect}
      >
        {i.title}
      </Link>
    ));
  }

  return (
    <Toolbar
      className={headerCls}
    >
      <div
        className={open ? classes.disabledLogoContainer : classes.logoContainer}
      >
        <img
          alt={''}
          onClick={() => {
            navigate('/public/home')
          }}
          src={'/images/white_logo.png'}
        />
      </div>
      <div className={classes.grow} />
      <div className={classes.menuBar}>

        {menuItems.map((menu, index) => {
          if (menu.type === 'drop') {
            return (
              <CustomDropdown
                buttonProps={{
                  className: clsx(
                    menu.title.includes('SIGN UP') || menu.title.includes('PROFILE') ? classes.myLoginBtn : classes.menuButton,
                    classes[menu.className],
                    menu.items.map(i => i.redirect).indexOf(path) > -1 && classes.activeMenu
                  ),
                  color: 'transparent',
                }}
                buttonText={menu.title === 'PROFILE' ? user.username : menu.title}
                dropdownList={getDropdownList(menu.items)}
                key={index}
                noLiPadding
              />
            )
          }
          return (
            <Link
              key={index}
              to={menu.redirect}
              underline="none"

            >
              <Button
                className={
                  clsx(
                    classes[menu.className],
                    path === menu.redirect && classes.activeMenu,
                    menu.title === 'SIGNUP' && classes.signupButton
                  )}
                onClick={handleDrawerClose}
              >{menu.title}</Button>
            </Link>
          )
        })}

        {authorized && user.id && (
          <ZogoPointsButton
            points={points}
            shouldAnimate={shouldAnimate}
          />
        )}

        {sticky && width < 750 && !open ? (
          <div
            className={classes.stickyIcon}
            style={open ? {
              right: '70%',
              marginLeft: '50%',
              transition: '0.3s all ease-in-out'
            } : {}}
          >
            <DehazeRoundedIcon
              className={classes.hamburgerIcon}
              onClick={handleDrawerOpen}
            />
          </div>
        ) : (
          <DehazeRoundedIcon
            className={classes.hamburgerIcon}
            onClick={handleDrawerOpen}
          />
        )}
      </div>
      <Suspense fallback={<p />}>
        <NavDrawer
          closeDrawer={handleDrawerClose}
          menuItems={menuItems}
          screenSize={{ width: width, height: height, open: open }}
          user={user}
          {...props}
        />
      </Suspense>

    </Toolbar>
  );
};

Header.propTypes = {
  path: PropTypes.string,
  sticky: PropTypes.bool
};

export default Header;
