import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { RESTS } from 'config/constants';

const analytics = Analytics({
  app: 'FinTronEdu',
  version: 1,
  plugins: [
    googleAnalytics({
      trackingId: RESTS.GOOGLE_ANALYTICS_TRACKING_ID
    })
  ],
  debug: process.env.NODE_ENV !== 'production'
})
export default analytics;
