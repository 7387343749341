import { createActions } from 'redux-actions';

const creators = createActions({
  LOGIN: ({ token, user, redirect }) => ({ token, user, redirect }),
  LOGOUT: (redirect = null) => ({ redirect }),
  UPDATE_USER: (user) => user,
  GET_ZOGO_TOKEN: () => ({}),
});

export default creators;
