
const styles = theme => ({
  root: {
    maxWidth: 444,
    width: '100%',
    padding: theme.spacing(0),
    paddingRight: 4,
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: '6px',
    minWidth: 315,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 364,
      width: '100%',
      borderRadius: 4,
      paddingRight: 0,
    }
  },
  stockInputWrap: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  bootyLabel: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '21px',
    letterSpacing: 0.36,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: 'normal',
      fontFamily: 'Roboto, sans-serif',
      lineHeight: '16px',
      letterSpacing: 0.28,
      marginBottom: 10,
    },
  },
  roundInput: {
    borderRadius: '6px !important',
    [theme.breakpoints.down('sm')]: {
    }
  },
  searchIcon: {
    color: '#000000',
    fontWeight: 'bold',
    marginRight: 10,
    zIndex: 1
  },
  adorn: {
    marginLeft: -theme.spacing(3),
    backgroundColor: 'white'
  }
});

export default styles
