const commonGamingItems = [
  {
    title: 'My Public Contests',
    redirect: '/my-contests?type=public',
  },
  {
    title: 'My Private Contests',
    redirect: '/my-contests?type=private',
  },

  {
    title: 'Join Contest',
    redirect: '/join',
  },
  {
    title: 'Create Contest',
    redirect: '/create',
  },
];

const menuItems = [
  {
    title: 'GAMING',
    className: 'menuButton',
    icon: 'border_color',
    type: 'drop',
    items: commonGamingItems,
  },
  {
    title: 'CLASSROOM',
    className: 'menuButton',
    icon: 'border_color',
    type: 'drop',
    items: [
      {
        title: 'My Classrooms',
        redirect: '/my-classrooms',
      },
    ]
  },
  {
    title: 'LOGIN',
    className: 'menuButton',
    icon: 'input',
    redirect: '/public/login',
  },
  {
    title: 'SIGN UP',
    className: 'signupButton',
    icon: 'input',
    type: 'drop',
    items: [
      {
        title: 'SIGN UP',
        redirect: '/public/signup',
      },
      {
        title: 'SIGN UP - TEACHER',
        redirect: '/public/signup-teacher',
      },
    ],
  },
];


const teacherAuthItems = [
  {
    title: 'ADMIN',
    className: 'menuButton',
    icon: 'book',
    redirect: '/assessment',
  },
  {
    title: 'LEARN & EARN',
    className: 'menuButton',
    icon: 'book',
    redirect: '/learn-and-earn?widget_type=learn_and_earn',
  },
  {
    title: 'MASTER BUDGET',
    className: 'menuButton',
    icon: 'book',
    redirect: '/budgeting',
  },
  {
    title: 'CLASSROOM',
    className: 'menuButton',
    icon: 'border_color',
    type: 'drop',
    items: [
      {
        title: 'Join Classroom',
        redirect: '/join-classroom',
      },
      {
        title: 'Create Classroom',
        redirect: '/create-classroom',
      },
      {
        title: 'My Classrooms',
        redirect: '/my-classrooms',
      },
    ]
  },
  {
    title: 'GAMING',
    className: 'menuButton',
    icon: 'border_color',
    type: 'drop',
    items: [
      ...commonGamingItems,
    ],
  },
  {
    title: 'PROFILE',
    className: 'signupButton',
    icon: 'input',
    type: 'drop',
    items: [
      {
        title: 'LOG OUT',
        redirect: '/logout',
      },
    ],
  },
];

const userAuthItems = [
  {
    title: 'LEARN & EARN',
    className: 'menuButton',
    icon: 'book',
    redirect: '/learn-and-earn?widget_type=learn_and_earn',
  },
  {
    title: 'MASTER BUDGET',
    className: 'menuButton',
    icon: 'book',
    redirect: '/budgeting',
  },
  {
    title: 'CLASSROOM',
    className: 'menuButton',
    icon: 'border_color',
    type: 'drop',
    items: [
      {
        title: 'My Classrooms',
        redirect: '/my-classrooms',
      },
      {
        title: 'Join Classroom',
        redirect: '/join-classroom',
      },
    ],
  },
  {
    title: 'GAMING',
    className: 'menuButton',
    icon: 'border_color',
    type: 'drop',
    items: [
      ...commonGamingItems,
    ],
  },
  {
    title: 'PROFILE',
    className: 'signupButton',
    icon: 'input',
    type: 'drop',
    items: [
      {
        title: 'LOG OUT',
        redirect: '/logout',
      },
    ],
  },
]

export default {
  menuItems,
  teacherAuthItems,
  userAuthItems,
};
