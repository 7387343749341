import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import message from './message/reducers';
import session from './session/reducers';
import points from './points/reducers';

export default combineReducers({
  routing: routerReducer,
  message,
  session,
  points
})
