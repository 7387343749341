import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';
import { GridLine, MagicContainer } from '../index';

const useStyles = makeStyles(styles);

const DesignGrid = props => {
  const classes = useStyles();
  if (!props.show) return null;
  return (
    <div className={classes.root}>
      <MagicContainer>
        <GridLine
          className={classes.paper}
        />
      </MagicContainer>
    </div>
  );
};

DesignGrid.defaultProps = {
  show: false
};

DesignGrid.propTypes = {
  show: PropTypes.bool
};

export default DesignGrid;
