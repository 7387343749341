import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Typography
} from '@material-ui/core';
import { BoundBox } from '../index';
import { COLORS, FONTS } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3)
    }
  },
  content: {
    font: FONTS.defaultSansFont,
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.white,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    }
  },
  bBox: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    padding: 16,
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      padding: 12
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      padding: 10
    }
  }
}));
const BoundBoxCard = props => {
  const classes = useStyles();
  const { img, description } = props;
  return (
    <div className={props.className}>
      <div className={classes.imgContainer}>
        <BoundBox
          className={classes.bBox}
          img={img}
        />
      </div>
      <Typography
        className={classes.content}
        component="h5"
        varient="h5"
      >
        {description}
      </Typography>
    </div>
  );
};

BoundBoxCard.defaultProps = {

};

BoundBoxCard.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
};

export default BoundBoxCard;
