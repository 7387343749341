import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Typography, CardActions, colors } from '@material-ui/core';

import LinkButton from 'components/LinkButton';
import { FONTS } from 'config/constants';
import { prependZeroNumberFormat } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2, 2),
    backgroundColor: colors.grey[200],
    borderRadius: 0
  },
  nomer: {
    fontWeight: 600
  },
  title: {
    marginTop: theme.spacing(1),
    color: colors.grey[900],
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    height: '2.5em',
    lineHeight: '1.2em',
    fontFamily: FONTS.SourceSansPro,
    fontSize: 28
  },
  description: {
    color: colors.grey[900],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    height: '4.8em',
    lineHeight: '1.2em',
    fontWeight: 400,
    fontSize: 20,
  },
  link: {
    color: colors.grey[900],
    fontWeight: 500,
    fontSize: 15,
    textTransform: 'capitalize'
  }
}));

const BlogBox = props => {
  const classes = useStyles();
  const { data } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.nomer}
          color="textSecondary"
          gutterBottom
        >
          {prependZeroNumberFormat(data.order)}
        </Typography>
        <Typography
          className={classes.title}
          component="p"
          gutterBottom
          variant="h3"
        >
          {data.title}
        </Typography>
        <Typography
          className={classes.description}
          component="p"
          variant="h4"
        >
          {data.sub_title}
        </Typography>
      </CardContent>
      <CardActions>
        <LinkButton
          className={classes.link}
          to={`/public/lessons/${data.link}`}
        >
          Read Lesson
        </LinkButton>
      </CardActions>
    </Card>
  );
};

BlogBox.defaultProps = {
  className: null
};

BlogBox.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    order: PropTypes.number,
    title: PropTypes.string,
    sub_title: PropTypes.string,
    link: PropTypes.string
  })
};

export default BlogBox;
