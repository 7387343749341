import React, { useState, Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Outlet, useLocation } from 'react-router-dom';

import { useEventListener, useWindowDimensions, debounce } from 'utils';
import { DesignGrid, AuthGuard, Header, ScrollToTop } from 'components';
import { PortfolioContext } from 'context/PortfolioContext';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  header: {
    width: '100%',
    position: 'absolute',
    margin: '8px 0',
    zIndex: 10,
    height: 'fit-content',
    transition: '0.3s all ease-out',
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
  },
  headerSticky: {
    position: 'fixed',
    width: '100%',
    marginTop: 10,
    backgroundColor: '#466DB1',
    transition: '0.3s all ease-in',
    zIndex: 9999,
    '& :nth-child(1)': {
      paddingBottom: 0,
      paddingTop: 0,
      '& img': {
        imageRendering: 'auto',
        width: 118,
      },
    },
  },
  container: {},
  footer: {},
  zogoHeader: {
    position: 'absolute',
    top: '-100px',
    width: '100%',
    backgroundColor: '#466DB1',
    margin: 0,
  },
  zogoFixed: {
    position: 'fixed',
    width: '100%',
    top: 0,
    height: 72,
    backgroundColor: '#466DB1',
    transition: '0.3s all ease-in',
    zIndex: 9999,
    '& :nth-child(1)': {
      paddingBottom: 0,
      paddingTop: 0,
      '& img': {
        imageRendering: 'auto',
        width: 118,
      },
    },
  },
}));

const MainLayout = ({ auth }) => {
  const classes = useStyles();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { modal } = useContext(PortfolioContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEventListener('scroll', debounce(handleScroll, 250, false));

  let headerClass;
  if (!modal.open && scrollPosition > 575 && width > 768 && !location.pathname.includes('/learn-and-earn')) {
    headerClass = classes.headerSticky;
  } else if (location.pathname.includes('/learn-and-earn') && scrollPosition < 575) {
    headerClass = classes.zogoHeader;
  } else if (location.pathname.includes('/learn-and-earn') && scrollPosition > 575 && width > 768) {
    headerClass = classes.zogoFixed;
  } else {
    headerClass = classes.header;
  }

  return (
    <div className={classes.root}>
      {!location.state?.lessonId && (
        <ScrollToTop />
      )}
      <DesignGrid />
      <div className={headerClass}>
        <Header
          path={location.pathname}
          sticky={!modal.open && scrollPosition > 576 && width < 767}
        />
      </div>
      <div>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress color={'secondary'} />}>
            <AuthGuard auth={auth}>
              <Outlet />
            </AuthGuard>
          </Suspense>
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  auth: PropTypes.bool,
};

export default MainLayout;
