import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Box, Chip, FormControl, FormHelperText } from '@material-ui/core';
import BootstrapInput from 'components/FormElement/BootstrapInput';

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 600,
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1)
  },
  input: {
    border: '1px solid #ced4da',
  }
}));

// eslint-disable-next-line react/display-name
const MultiEmailInput = React.forwardRef((props, ref) => {
  ref.current = {
    getValue: () => items,
    setErr: (err) => setErr(err)
  }
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState('');
  const [err, setErr] = useState(props.err);

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      const value = query.trim();

      if (value && isValid(value)) {
        setItems(v=> [...v, value]);
        setQuery('');
        setErr(null);
      }
    }
  }

  const handleChange = evt => {
    setQuery(evt.target.value);
    setErr(null);
  }

  const handleDelete = item => {
    setItems(v => {
      return v.filter(i => i!== item);
    })
  }

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setErr(error);

      return false;
    }

    return true;
  }

  const isInList = (email) => {
    return items.includes(email);
  }

  const isEmail = (email) => {
    return /[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/.test(email);
  }

  return (
    <div className={clsx(classes.root, props.className)}>
      <Box className={classes.chipsContainer}>
        {items.map((email) => (
          <Chip 
            className={classes.chip}
            key={email}
            label={email}
            onDelete={() => handleDelete(email)}
          />
        ))}
      </Box>
      <Box mt={1}>
        <FormControl
          error={!!err}
          fullWidth
        >
          <BootstrapInput
            classes={{
              input: classes.input
            }}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={'Type email addresses and press `Enter`...'}
            value={query}
          />
          <FormHelperText>{err}</FormHelperText>
        </FormControl>
      </Box>
    </div>
  );
});

MultiEmailInput.defaultProps = {
  className: null
};

MultiEmailInput.propTypes = {
  className: PropTypes.string,
  err: PropTypes.string,
};

export default MultiEmailInput;
