import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withWidth } from '@material-ui/core';
import {
  InstagramIcon,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  GridItem,
  MagicContainer, GridContainer,
} from '../..';
import { Typography } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { DESIGN_UTILS } from '../../../utils';


const useStyles = makeStyles(theme => ({
  socialWrapper: {
    // flexBasis: '100%'
    width: '100vw',
  },
  root: {
    backgroundColor: 'transparent',
    padding: '50px 0 28px 0',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0'
    },

  },
  content: {
    margin: 0,
    display: 'flex'
  },
  iconWrap: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row-reverse'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: 200,
    imageRendering: 'auto',
    marginBottom: -25,
    [theme.breakpoints.down('md')]: {
      width: 150,
      marginBottom: 'unset'
    },
  },
  logoWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  disclaimer: {
    color: '#FFFFFF',
    opacity: 0.8,
    textAlign: 'center',
    fontSize: '10px',
    paddingBottom: 20,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10
    }
  },
  socialContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
  }
}));

const socials = [
  {
    key: 'instagram',
    icon: <InstagramIcon />,
    url: 'https://www.instagram.com/fintroninvest/?hl=en'
  },
  {
    key: 'facebook',
    icon: <FacebookIcon />,
    url: 'https://www.facebook.com/FinTronApp/?ref=bookmarks'
  },
  {
    key: 'twitter',
    icon: <TwitterIcon />,
    url: 'https://twitter.com/FinTronInvest'
  },
  {
    key: 'linkedin',
    icon: <LinkedinIcon />,
    url: 'https://www.linkedin.com/company/fintronu'
  },
  {
    key: 'hidden',
    icon: null
  }
];

const FooterSocial = (props) => {

  const classes = useStyles();
  const pdLeft = DESIGN_UTILS.calGridSpacingPixel(props.width);
  const iconStyle = {
    width: pdLeft,
    textAlign: 'center',
    cursor: 'pointer'
  };
  const contentStyle = {
    marginRight: - pdLeft,
  };
  const discloserText = `This website is operated by FinTron, Inc., Please consider your objectives before investing, Past performance does not guarantee future results. Investment outcomes and projections are hypothetical in nature. FinTron reserves the right to restrict or revoke access to our site at anytime. Copyright material of FinTron. 
  This product is an investing GAME and data is not guaranteed accurate. ©2022 FinTron’s | Disclosure`
  return (
    <MagicContainer className={classes.socialWrapper}>
      <GridContainer className={classes.socialContainer}>
        <GridItem
          lg={6}
          md={6}
          sm={10}
          xs={'auto'}
        >
          <GridItem>
            <div className={clsx(classes.logoWrap, 'debug--item')}>
              <img
                alt={''}
                className={classes.logo}
                src={'/images/white_logo.png'}
              />
            </div>
          </GridItem>
          <div
            className={classes.root}
          >
            <div
              className={classes.content}
              style={contentStyle}
            >

              {socials.map(social => (
                <div
                  className={clsx(classes.iconWrap)}
                  key={social.key}
                >
                  <div
                    onClick={() => social.url && window.open(social.url)}
                    style={iconStyle}
                  >
                    {social.icon}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <GridItem >
            <Typography
              className={classes.disclaimer}
              component="p"
            >
              {discloserText}
            </Typography>
          </GridItem>
        </GridItem>
        <Hidden smDown>
          <GridItem
            lg={4}
            md={3}
          />
        </Hidden>
      </GridContainer>
    </MagicContainer>
  );
};

FooterSocial.propTypes = {
  route: PropTypes.object,
  width: PropTypes.node
};

export default withWidth()(FooterSocial);
