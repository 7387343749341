import React from 'react';
import { ResponsiveIcon } from '../index';
const InstagramIcon = props => {
  return (
    <ResponsiveIcon {...props}>
      <svg
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M256 46.144531c68.351562 0 76.457031.253907 103.464844 1.492188
            69.378906 3.15625 101.78125 36.074219 104.941406 104.9375 1.238281
            26.988281 1.472656 35.09375 1.472656 103.445312 0 68.375-.257812
            76.460938-1.472656 103.445313-3.179688 68.800781-35.5 101.78125-104.941406
            104.941406-27.007813 1.238281-35.070313 1.492188-103.464844 1.492188-68.351562
            0-76.457031-.253907-103.445312-1.492188-69.546876-3.179688-101.78125-36.246094-104.9375-104.960938C46.378906
            332.457031 46.121094 324.375 46.121094 256c0-68.351562.277344-76.4375 1.496094-103.445312 3.175781-68.84375
            35.496093-101.78125 104.9375-104.9375C179.5625 46.398438 187.648438 46.144531 256 46.144531zM256 0c-69.523438
            0-78.230469.296875-105.535156 1.535156C57.492188 5.800781 5.824219 57.386719 1.558594 150.441406.296875 177.769531
            0 186.476562 0 256s.296875 78.25 1.535156 105.558594c4.265625 92.96875 55.851563 144.640625 148.90625
            148.90625C177.769531 511.703125 186.476562 512 256 512s78.25-.296875 105.558594-1.535156c92.882812-4.265625
            144.679687-55.851563 148.882812-148.90625C511.703125 334.25 512 325.523438 512 256s-.296875-78.230469-1.535156-105.535156C506.28125
            57.578125 454.632812 5.824219 361.578125 1.558594 334.25.296875 325.523438 0 256 0zm0 124.542969c-72.597656 0-131.457031
            58.859375-131.457031 131.457031S183.402344 387.476562 256 387.476562 387.457031 328.617188 387.457031
            256c0-72.597656-58.859375-131.457031-131.457031-131.457031zm0 216.789062c-47.125 0-85.332031-38.183593-85.332031-85.332031
            0-47.125 38.207031-85.332031 85.332031-85.332031S341.332031 208.875 341.332031 256c0 47.148438-38.207031
            85.332031-85.332031 85.332031zM392.660156 88.640625c-16.980468 0-30.738281 13.757813-30.738281 30.71875s13.757813
            30.71875 30.738281 30.71875c16.960938 0 30.699219-13.757813 30.699219-30.71875s-13.738281-30.71875-30.699219-30.71875zm0 0"
          fill={'white'}
        />
      </svg>
    </ResponsiveIcon>

  );
};

InstagramIcon.defaultProps = {

};

InstagramIcon.propTypes = {

};

export default InstagramIcon;
