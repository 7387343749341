import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { CircularProgress, colors } from '@material-ui/core';

import { COLORS, FONTS } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.eduBlue,
    color: COLORS.white,
    letterSpacing: '1.4px',
    borderRadius: theme.spacing(2),
    textTransform: 'uppercase',
    fontFamily: FONTS.Roboto,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    },
    '&>span': {
      letterSpacing: '1.4px',
      fontFamily: FONTS.Roboto,
      fontWeight: 'bold',
    },
    '&&:hover': {
      backgroundColor: colors.lightBlue[200],
    }
  },
  disabled: {
    backgroundColor: COLORS.white,
    color: COLORS.grey,
  },
  rounded: {
    borderRadius: theme.spacing(2)
  },
  loading: {
    color: 'gray',
    position: 'absolute'
  },
  shadow: {
    boxShadow: '0px 2px 4px #00000040'
  }
}));

const CustomButton = props => {
  const { classNames, disabled, rounded, title, type, loading, shadow, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, classNames.main, disabled && classes.disabled, rounded ? classes.rounded : '', shadow ? classes.shadow : '' )}
      component={props.to ? RouterLink: 'button'}
      disabled={disabled}
      onClick={() => type === 'button' && !loading &&!props.to && props.onClick()}
      type={type}
      {...rest}
    >
      {loading && (
        <CircularProgress
          className={classes.loading}
          size={16}
        />
      )}
      {title}
    </Button>
  );
};

CustomButton.defaultProps = {
  classNames: {},
  disabled: false,
  rounded: false,
  type: 'button',
  onClick: () => null
};

CustomButton.propTypes = {
  classNames: PropTypes.shape({
    main: PropTypes.any
  }),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  to: PropTypes.string,
  shadow: PropTypes.bool,
};

export default CustomButton;
