import { DESIGNS } from 'config/constants';

const { pageSpacing } = DESIGNS;

const styles = theme => ({
  root: {
    flex: 1,
    overflow:'hidden',
    zIndex: 1
  },
  main: { },
  topWrap: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  debugWrap: {
    border: '1px solid green'
  },
  debugContent: {
    border: '1px dashed yellow'
  },
  tradingBannerWrap: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  leftOnly: {
    paddingLeft: theme.spacing(pageSpacing.md),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(pageSpacing.xs),
    },
    [theme.breakpoints.between('md', 'sm')]: {
      paddingLeft: theme.spacing(pageSpacing.sm),
    },
    [theme.breakpoints.between('lg', 'md')]: {
      paddingLeft: theme.spacing(pageSpacing.md),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(pageSpacing.lg),
    }
  },
  rightOnly: {
    paddingRight: theme.spacing(pageSpacing.md),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(pageSpacing.xs)
    },
    [theme.breakpoints.between('md', 'sm')]: {
      paddingRight: theme.spacing(pageSpacing.sm),
    },
    [theme.breakpoints.between('lg', 'md')]: {
      paddingRight: theme.spacing(pageSpacing.md),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(pageSpacing.lg),
    }
  }
});

export default styles
