import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import routes from '../routes';

function init(history) {
  if (process.env.NODE_ENV !== 'production') return;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_RELEASE_VERSION,
    integrations: [new Integrations.BrowserTracing({
      tracingOrigins: [
        process.env.REACT_APP_API_HOST,
        process.env.REACT_APP_CMS_API_HOST,
      ],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes),
    })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    autoSessionTracking: true,
  });
}

function setUser(user) {
  if (process.env.NODE_ENV !== 'production') return;
  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: user.username,
  });
}

function clearUser() {
  if (process.env.NODE_ENV !== 'production') return;
  Sentry.configureScope(scope => scope.setUser(null));
}

export default {
  init,
  setUser,
  clearUser,
};
