import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  MagicContainer,
  GridItem,
  GridContainer
} from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4)
  },
  buttonGrp: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '300px',
    marginLeft: '-125px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-100px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-60px',
      alignItems: 'flex-end',
    }
  },
  buttonItem: {
    width: 'fit-content',
    minWidth: '114px',
    padding: 0,
    order: 'unset !important',
    '&:hover': {
      borderRadius: 50,
    }
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize: 13
    }
  },
  buttonActive: {
    border: '2px solid #FFFFFF',
    borderRadius: 50,
    order: 'unset !important',
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #FFFFFF',
    }
  },
}));

const NavSegment = props => {
  const classes = useStyles();
  const { data, selected } = props;

  return (
    <div className={clsx(classes.root, props.className)}>
      <MagicContainer styles={{ overflow: 'initial' }}>
        <GridContainer>
          <GridItem
            container
          >
            <div className={classes.buttonGrp}>
              {
                data.map((item, index) => (
                  <Button
                    className={clsx(classes.buttonItem, index === selected && classes.buttonActive)}
                    classes={{
                      text: classes.buttonText,
                    }}
                    component={RouterLink}
                    key={item.title}
                    to={item.link}
                  >
                    {item.title}
                  </Button>
                ))
              }
            </div>
          </GridItem>
        </GridContainer>
      </MagicContainer>

    </div>
  );
};

NavSegment.defaultProps = {
  className: null,
  shadow: false,
};

NavSegment.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string
  })),
  selected: PropTypes.number,
  shadow: PropTypes.bool,
};

export default NavSegment;
