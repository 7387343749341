import React from 'react';
import MaskedInput from 'react-text-mask';

const PhoneMaskInput = (props) => {
  
  return (
    <MaskedInput      
      {...props}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
};

export default PhoneMaskInput;
