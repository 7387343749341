import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ReactPlayer from 'react-player';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}));

const FtYoutube = ({ source, classes: { mask, root }, children }) => {

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, root)}
    >
      <ReactPlayer
        controls={false}
        height="100%"
        loop
        muted
        playing
        playsinline
        stopOnUnmount={false}
        url={source}
        width="100%"
      />
      <div className={clsx(classes.mask, mask)}>
        {children}
      </div>
    </div>
  );
};

FtYoutube.defaultProps = {
  source: '',
  classes: {
    mask: null,
  }
};

FtYoutube.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
  source: PropTypes.string,
};

export default FtYoutube;
