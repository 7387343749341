import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline'
  }
}));

const TextLink = ({ className, ...rest}) => {
  const classes = useStyles();

  return (
    <Link
      className={clsx(classes.link, className )} 
      {...rest}
    />
  );
};

TextLink.defaultProps = {
  className: null
};

TextLink.propTypes = {
  className: PropTypes.string
};

export default TextLink;
