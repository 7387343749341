import React from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
} from '@material-ui/pickers';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { COLORS } from 'config/constants';

const theme = createTheme({
  overrides: {
    MuiPopover: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      paper: {
        borderRadius: '26px',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#FFFFFF',
        borderRadius: '26px 26px 0px 0px',
        backgroundColor: '#26A7DF',
        textTransform: 'uppercase',
        marginTop: -5,
      },
      iconButton: {
        color: '#FFFFFF',
        backgroundColor: '#26A7DF',
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#26A7DF',
        '&:hover': {
          backgroundColor: '#26A7DF'
        },
        '& > span > p': {
          color: COLORS.white,
          letterSpacing: '2.4px',
          fontWeight: 300,
          fontSize: 12,
          lineHeight: '16px',
        },
      },
      day: {
        width: 28,
        height: 28,
        margin: '7px 6px',
        '& > span > p': {
          letterSpacing: '2.6px',
          fontWeight: 300,
          fontSize: 13,
          lineHeight: '18px',
          color: COLORS.black
        }
      }
    },
    MuiTypography: {
      body1: {
        letterSpacing: '3.4px',
        fontWeight: 900,
        fontSize: 17,
        lineHeight: '23px'

      },
    },
  },
});

export default function CustomDatePicker(props) {
  const { icon, handleDateChange, ...rest } = props;
  return (
    <ThemeProvider theme={theme}>
      <DatePicker
        keyboardIcon={icon}
        onChange={handleDateChange}
        {...rest}
      />
    </ThemeProvider>
  );
}

CustomDatePicker.propTypes = {
  icon: PropTypes.node,
  handleDateChange: PropTypes.func,
};
