import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { DESIGN_UTILS } from '../../utils';

const pxToRem = DESIGN_UTILS.pxToRem;

const breakpoints = createBreakpoints({});

export default {
  h1: {
    fontSize: pxToRem(100),
    [breakpoints.down('md')]: {
      fontSize: pxToRem(72)
    },
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(56)
    },
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(38)
    },
  },
  h2: {
    fontSize: pxToRem(52),
    [breakpoints.down('md')]: {
      fontSize: pxToRem(44)
    },
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(30)
    },
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(28)
    },
  },
  h3: {
    fontSize: pxToRem(32),
    [breakpoints.down('md')]: {
      fontSize: pxToRem(28)
    },
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(22)
    }
  },
  h5: {
    fontSize: pxToRem(20),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(12)
    }
  },
  body2: {
    fontSize: pxToRem(30),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(16)
    }
  }
};
