import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'config/constants';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  imageCropper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '50%',
    textAlign: 'center',
    backgroundColor: COLORS.white,
  },
  img: {
    width: '100%'
  }
}));

const BoundBox = props => {
  const { img } = props;
  const classes = useStyles();
  return (
    <div className={clsx(props.className, classes.imageCropper)}>
      <img
        alt=""
        className={classes.img}
        src={img}
      />
    </div>
  );
};

BoundBox.defaultProps = {

};

BoundBox.propTypes = {
  className: PropTypes.string,
  img: PropTypes.string
};

export default BoundBox;
