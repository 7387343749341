import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { DESIGN_UTILS } from '../../utils';
import clsx from 'clsx';
import { Hidden } from '@material-ui/core';

const styles = {
  grid: {

  }
};

const useStyles = makeStyles(styles);

function GridContainer(props) {
  const classes = useStyles();
  const { children, className, hidden, ...rest } = props;
  let spacing = props.spacing;
  if (!spacing) {
    // eslint-disable-next-line react/prop-types
    spacing = DESIGN_UTILS.getGridSpacing(props.width)
  }

  return (
    <Grid
      container
      spacing={spacing}
      {...rest}
      className={clsx(classes.grid, className, {'debug--grid-container': props.debug})}
    >
      <Hidden xsUp={hidden}>
        {children}
      </Hidden>
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: ''
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  debug: PropTypes.bool,
  hidden: PropTypes.bool,
  spacing: PropTypes.number,
};

export default withWidth()(GridContainer)
