import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { MenuItem, SvgIcon, createTheme } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { SelectFieldWrapper } from 'components';
import styles from './styles';

const useStyles = makeStyles(styles);

const theme = createTheme({
  overrides: {
    MuiPopover: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: 'none',
      },
      root: {
        backgroundColor: 'transparent',
        minWidth: 'unset !important',
      },
    },
    MuiMenu: {
      list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingRight: '15px !important',
        position: 'fixed',
        marginLeft: '8%',
        minHeight: 450,
        transition: '0.3s all ease-in-out',
        width: 'fit-content !important',
      },
    },
    // Applied to the <li> elements
    MuiSelect: {
      select: {
        textAlign: 'center',
        '&:focus': {
          backgroundColor: 'white !important',
        },
      },
      icon: {
        color: '#000000',
        marginRight: 10,
      },
    },
  },
});

const SectorSelector = ({ className, sectors, handleChange, children, selected, clearSelection, ...rest }) => {
  const classes = useStyles();

  function findValue(id) {
    const selectedSector = sectors.find(sector => sector.value === id);
    return selectedSector?.text;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.clearSelectWrapper}>
        <h4 className={classes.bootyLabel}>Select Category</h4>
        {selected && (
          <SvgIcon
            className={classes.imgClose}
            component={ClearIcon}
            onClick={clearSelection}
          />
        )}
        <SelectFieldWrapper
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            classes: {
              list: classes.listWrappa,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: undefined
          }}
          className={clsx(classes.root, className)}
          defaultValue={''}
          displayEmpty
          input={{
            classes: {
              root: classes.roundInput,
            },
          }}
          onChange={handleChange}
          renderValue={(val) => {
            if (val.length === 0) return <span className={classes.placeholder}>Select A Category </span>
            return findValue(val)
          }}
          value={selected || ''}
          {...rest}
        >
          {sectors ?
            sectors.map(item => (
              <MenuItem
                className={classes.sectorItem}
                key={item.value}
                value={item.value}
              >
                <img
                  alt="icon"
                  src={item.icon}
                />
                {item.text}
              </MenuItem>
            ))
            :
            children
          }
        </SelectFieldWrapper>
      </div>
    </ThemeProvider>
  );
};

SectorSelector.defaultProps = {
  className: null,
};

SectorSelector.propTypes = {
  className: PropTypes.string,
  sectors: PropTypes.arrayOf(PropTypes.any),
  handleChange: PropTypes.func,
  children: PropTypes.node,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  clearSelection: PropTypes.func,
};

export default SectorSelector;
