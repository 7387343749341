import http from './http'

const ContestUser = {
  getLeaders: () => http.get('/api/contest-users/leaderboard/'),

  findById: (id) => http.get(`/api/contest-users/${id}/`),

  getByParams: (params) => http.get('/api/contest-users/', { params }),

  createOne: (data) => http.post('/api/contest-users/', { data }),

  updateDescription: (id, data) => http.patch(`/api/contest-users/${id}/description/`, { data }),

  deleteOne: (id) => http.delete(`/api/contest-users/${id}/`)
};

export default ContestUser;
