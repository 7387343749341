import { ZogoApi } from 'api';
import { useEffect, useState  } from 'react'
import { RequestHelper } from 'utils';

const useCompletedModules = (moduleData) => {
  const requestHelper =  new RequestHelper();
  const [completedModules, setCompletedModules] = useState([]);
  function fetchCompletedModsBySkill() {
    const skillIds = moduleData.reduce((acc, itm) => {
      if (!acc.includes(itm.skillId)) {
        acc.push(itm.skillId);
      }
      return [...acc];
    }, [])
    if (skillIds.length) {
      requestHelper
        .registerRequest(ZogoApi.getCompletedModulesBySkills({skills: skillIds }))
        .then(res => {
          setCompletedModules(res.data)
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    fetchCompletedModsBySkill();
  },[moduleData])
  return completedModules;
}

export default useCompletedModules;
