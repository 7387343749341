import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card
} from '@material-ui/core'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    backgroundColor: 'rgb(0,168,204)',
    background: 'linear-gradient(130deg, rgba(0,168,204,1) 0%, rgba(33,32,76,1) 100%)',
    borderRadius: theme.spacing(1)
  },
}));

const GradientBox = ({ className, ...rest}) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
};

GradientBox.defaultProps = {
  children: null,
  className: null,
};

GradientBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default GradientBox;
