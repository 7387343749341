import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { CONSTANTS } from 'config/global';
import { RequestHelper } from 'utils';
import { getCookie } from 'utils/cookie';
import { SessionActions } from 'store/actions';
import { AuthApi } from 'api';

const AuthGuard = props => {
  const { auth, children } = props;
  const [loading, setLoading] = useState(false);
  const unmounted = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const { authorized } = useSelector(({ session }) => session);
  const requestHelper = new RequestHelper();

  useEffect(() => {
    return () => { unmounted.current = true }
  }, []);

  useEffect(() => {
    const token = getCookie(CONSTANTS.TOKEN);

    if (!loading) {
      if (auth && !token && pathname !== '/logout') {
        navigate(`/public/signup?from=${pathname}`, { replace: true })
      }

      if (token && !authorized) {
        setLoading(true);
        requestHelper
          .registerRequest(AuthApi.verifyToken({ token }))
          .then(res => {
            dispatch(SessionActions.login(res.data));
          })
          .catch(err => {
            console.log('~~~~verify token failed', err);
            dispatch(SessionActions.logout(pathname));
          })
          .finally(() => {
            if (!unmounted.current) setLoading(false);
          });
      }
    }
  }, [pathname, authorized]);

  return (
    <Fragment>
      {!loading && children}
    </Fragment>
  );
};

AuthGuard.defaultProps = {
  auth: false,
};

AuthGuard.propTypes = {
  auth: PropTypes.bool,
  children: PropTypes.node,
};

export default AuthGuard;
