import { handleActions } from 'redux-actions';

const initState = {
  token: null,
  authorized: false,
  user: {},
};

export default handleActions(
  {
    LOGIN: (state, action) => {
      const { token, user } = action.payload;
      return {
        ...state,
        token,
        user,
        authorized: true,
      };
    },

    LOGOUT: () => initState,

    UPDATE_USER: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },

  },
  initState,
);
