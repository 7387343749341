import { FONTS, COLORS } from 'config/constants';

const styles = theme => ({
  label: {
    fontSize: 24,
    fontFamily: FONTS.Roboto,
    padding: '16px 0',
    fontWeight: 400,
    color: COLORS.black,
    letterSpacing: 0.36,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    }
  },
  white: {
    color: COLORS.white
  },
  selectInput: {
    'label + &': {
      marginTop: theme.spacing(0)
    }
  },
  showLabel: {
  }
});

export default styles;