import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { COLORS } from 'config/constants';

const BlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: COLORS.darkBlue,
    fontSize: 16,
    height: theme.spacing(6),
    '&:disabled': {
      color: COLORS.darkGray,
      backgroundColor: COLORS.grey,
      opacity: 0.5
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      height: theme.spacing(5)
    },
    color: COLORS.white,
    '&&:hover': {
      backgroundColor: COLORS.middleDarkBule,
    },
    ...theme.styles.shadow,
  }
}))(Button);

const SubmitButton = ({ loading, children, white, type, onClick, ...rest}) => {
  return (
    <BlueButton
      {...rest}
      onClick={() => type === 'button' && !loading && onClick()}
      style={white?{
        backgroundColor: COLORS.white,
        color: COLORS.darkBlue
      }: null}
      type={type}
    >
      {loading && (
        <CircularProgress
          color={white ? 'primary' : 'secondary'}
          size={18}
          variant="indeterminate"
        />
      )}
      {children}
    </BlueButton>
  )};

SubmitButton.defaultProps = {
  loading: false,
  type: 'submit',
  white: false,
};

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button']),
  white: PropTypes.bool,
};

export default SubmitButton;
