import http from './http'
import { cmsApiHost, pageSize } from 'config/global';

const CMS = {
  getFlyers: (params) => {
    params['page_size'] = pageSize;
    return http.get(`${cmsApiHost}/api/flyers/web/`, { params, disableAuthorization: true });
  },

  retrieveFlyer: (link) => http.get(`${cmsApiHost}/api/flyers/web/${link}/`, { disableAuthorization: true }),

  getLessons: (params, page_size = pageSize) => {
    params['page_size'] = page_size;
    return http.get(`${cmsApiHost}/api/lessons/`, { params, disableAuthorization: true });
  },

  retrieveLesson: (link) => http.get(`${cmsApiHost}/api/lessons/${link}/`, { disableAuthorization: true }),
}

export default CMS;
