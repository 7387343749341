import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomButton from '../Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 150,
    height: 32,
    margin: 'auto',
    marginTop: theme.spacing(3),
  }
}));

const ModalButton = ({ className, ...rest}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <CustomButton {...rest} />
    </div>
  );
};

ModalButton.defaultProps = {
  className: null,
};

ModalButton.propTypes = {
  className: PropTypes.string,
};

export default ModalButton;
