import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { formatPercentDecimal,thousandFormat, thousandFormatWithSign } from 'utils/functions';
import { COLORS } from 'config/constants';
import { PortfolioContext } from 'context/PortfolioContext';
import { BuySellButton, LinkButton, TableDarkContainer } from 'components';
import useWindowDimensions from 'utils/useWindowDimensions';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    display: 'flex',
    boxShadow: 'unset',
    overflowY: 'hidden',
    margin: '27px 10px',
    overflowX: 'auto !important',
    padding: 15,
    minWidth: 1100,
    [theme.breakpoints.down('md')]: {
      padding: '15px 5px',
      minWidth: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
      overflowX: 'hidden !important',
      minWidth: 'fit-content',
    },
  },
  table: {
    minWidth: 655,
    maxWidth: 1000,
    width: '100%',
    maxHeight: 418,
    boxSizing: 'border-box',
    // minHeight: 415,
    padding: '17px 29px',
    margin: '0 2px 70px 50px',
    [theme.breakpoints.down('md')]: {
      //   margin: '10px 0',
    },
    '& thead, tbody': {
      display: 'block',
    },
    '& tbody': {
      backgroundClip: 'padding-box',
      width: 'fit-content',
      boxShadow: '0px 2px 4px #00000040',
      '-webkit-box-shadow': '0px 2px 4px #00000040',
      backgroundColor: '#FFFFFF',
      borderRadius: 26,
      maxHeight: '418px',
      minHeight: 'fit-content',
      height: '100%',
      '-webkit-overflow-scrolling': 'touch',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 0,
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'transparent'
      },
      overflowX: 'hidden',
      '& tr:first-of-type': {
        '& td:first-of-type': {
          borderTopLeftRadius: 26,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        '& td:last-of-type': {
          borderTopRightRadius: 26,
          borderTopLeftRadius: 0,
        }
      },
      '& tr:last-of-type': {
        '& td:first-of-type': {
          borderBottomLeftRadius: 26,
        },
        '& td:last-of-type': {
          borderBottomRightRadius: 26,
        },
      },
    },
    '&& td': {
      borderBottom: 0,
      // backgroundClip: 'padding-box',
      marginRight: '8px',
      padding: '8px',
      margin: 0,
      whiteSpace: 'nowrap',
      '&:first-child': {
        paddingLeft: 30,
        maxWidth: 70,
      },
      '&:nth-child(3)': {
        minWidth: 80,
        width: 80,
      },
      '&:nth-child(2)': {
        minWidth: 200,
        width: 200
      },
      '&:nth-child(4)': {
        minWidth: 94,
        width: 100
      },
      '&:nth-child(5)': {
        width: 100
      },
      '&:nth-child(6)': {
        maxWidth: 105,
        width: 105
      },
      '&:nth-child(7)': {
        maxWidth: 100,
      },
      '&:nth-child(8)': {
        width: 95
      },
      '&:nth-child(9)': {
        width: 95
      },
      '&:nth-child(10)': {
        width: 100,
      },
      '&:nth-child(11)': {
        width: 95,
      },
      '&:nth-child(12)': {
        width: 95
      },
    },
    [theme.breakpoints.down('sm')]: {
      borderCollapse: 'separate',
      borderSpacing: '0px 10px',
      minWidth: 350,
      maxWidth: 365,
      width: '100%',
      margin: '0 auto',
      padding: '17px 0',
      maxHeight: 450,
      minHeight: 415,
      '& thead': {
        visibility: 'hidden',
        width: 1,
        height: 1,
      },
      '& tbody': {
        boxShadow: '1px 12px 2px -10px rgb(63 63 68 / 5%)',
        '-webkit-box-shadow': '1px 12px 2px -10px rgb(63 63 68 / 5%)',
        '-webkit-appearance': 'none',
        backgroundColor: 'transparent',
        margin: '0 3%',
        height: 458,
        minHeight: 'unset',
        maxWidth: 375,
        width: 375,
        '-webkit-overflow-scrolling': 'touch',
        borderRadius: 0,
        '& tr:first-of-type': {
          '& td:first-of-type': {
            borderTopLeftRadius: '16px !important',
          },
          '& td:last-of-type': {
            borderTopRightRadius: '16px !important',
          },
        },
        '& tr:last-of-type': {
          '& td:first-of-type': {
            borderBottomLeftRadius: '16px !important',
          },
          '& td:last-of-type': {
            borderBottomRightRadius: '16px !important',
          },
        },
        '& tr': {
          width: 364,
          boxShadow: 'none',
          '-webkit-box-shadow': 'none',
          '-webkit-appearance': 'none !important',
          transform: 'scale(1,1)',
          '-webkit-transform': 'scale(1,1)',
          borderRadius: 16,
          height: 90,
          '& td': {
            '&:first-child': {
              paddingLeft: '10px !important',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              boxShadow: '1px 3px 7px -1px #00000040',
            },
            '&:nth-child(2)': {
              borderRadius: 0,
              boxShadow: '-2px 0px 5px -1px #fff, 4px 3px 7px -1px #00000040, -1px 0px 5px -1px #fff'
            },
            '&:last-child': {
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              boxShadow: '4px 3px 5px -1px #00000040, -10px 0px 5px -2px #fff'
            },
            borderRadius: 16,
            padding: '0px !important',
            '& div': {
              height: 'unset',
            },
          },
        },
      },
    },
  },
  theadRow: {
    '&& th': {
      letterSpacing: 1,
      font: 'normal normal bold 10px/11px Roboto',
      margin: 0,
      fontWeight: 'bold',
      color: '#26A7DF',
      textAlign: 'center',
      borderBottom: 0,
      paddingBottom: 5,
      '&:first-child': {
        paddingLeft: 30,
        minWidth: 70,
      },
    },
    '& :nth-child(2)': {
      minWidth: 200,
      width: 200
    },
    '& :nth-child(3)': {
      width: 80,
    },
    '& :nth-child(4)': {
      minWidth: 94,
      width: 100
    },
    '& :nth-child(5)': {
      width: 100
    },
    '& :nth-child(6)': {
      minWidth: 100,
      width: 115
    },
    '& :nth-child(7)': {
      width: 110
    },
    '& :nth-child(8)': {
      width: 95
    },
    '& :nth-child(9)': {
      width: 95
    },
    '& :nth-child(10)': {
      width: 100,
    },
    '& :nth-child(11)': {
      width: 95,
    },
    '& :nth-child(12)': {
      width: 95
    },
    '& :nth-child(13)': {
      paddingRight: 20,
      paddingLeft: 20,
    },
    '& :nth-child(14)': {
      paddingRight: 14,
    },
    '& :nth-child(15)': {
      paddingRight: 10,
    },
  },
  nameCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200,
    minWidth: 200,
    width: 'fit-content',
    '& :first-child': {
      width: 150,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  mobileNameCell: {
    whiteSpace: 'nowrap',
  },
  tableCellRoot: {
    backgroundColor: '#FFFFFF',
  },
  mobileCellContent: {
    boxShadow: '0px 2px 4px #00000040',
    '-webkit-box-shadow': '0px 2px 4px #00000040',
    '-webkit-appearance': 'none',
    borderRadius: 19,
    padding: '10px 17px',
    height: 32,
    color: COLORS.black,
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: 0.22,
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      borderRadius: 0,
      padding: 0,
    },
  },
  cellContent: {
    boxShadow: '0px 2px 4px #00000040',
    '-webkit-box-shadow': '0px 2px 4px #00000040',
    '-webkit-appearance': 'none',
    borderRadius: 19,
    padding: '10px 17px',
    height: 32,
    color: COLORS.black,
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: 0.22,
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      borderRadius: 0,
      padding: 0,
    },
  },
  searchTableCell: {
    width: 75,
  },
  stockModal: {
    '& .MuiDialog-paper': {
      maxWidth: 900,
      borderRadius: theme.spacing(8),
    },
  },
  actionButton: {
    fontSize: 13,
    color: `${COLORS.middleDarkBule}!important`,
  },
  actionCell: {
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  tableScrollWrapper: {
    position: 'relative',
    top: 45,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '415px',
    width: 30,
    marginLeft: '1%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1%'
    },
  },
  scrollArrow: {
    width: '28px',
    background: '#26A7DF',
    height: '28px',
    borderRadius: '50%',
    boxShadow: '0px 3px 6px #00000040',
    right: '15%',
    visible: 'visible',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  scrollIcon: {
    fill: COLORS.white,
    height: '1.25em',
    width: '1.20em',
  },
  stockIcon: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    '& img': {
      margin: '12% 16%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 61,
      height: '61px !important',
      '& img': {
        height: 40,
        width: 40,
      },
    },
  },
  popupIcon: {
    margin: '11px 1px 7px 0',
  },
  stockName: {
    letterSpacing: 0.36,
    fontSize: 18,
    marginTop: 10,
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'start',
    width: 'fit-content',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 205,
  },
  stockBtnWrapper: {
    textAlign: 'start',
  },
  stockPrice: {
    letterSpacing: 0.36,
    fontSize: 18,
    marginTop: '-10px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'right',
    paddingRight: 10,
  },
  priceDiff: {
    color: '#808080',
    letterSpacing: 0.32,
    fontSize: 16,
    paddingRight: 5,
  },
  buyOnlyBtn: {
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 19,
    backgroundColor: '#26A7DF',
    color: 'white',
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      color: '#26A7DF',
    },
  },
  modalTableIcon: {
    imageRendering: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const DetailTable = (props) => {
  const {
    isOwner,
    contestUser,
    securities,
    tableHeaders,
    className,
    fetchItems,
    hasMoreItems,
    loader,
    isLoading,
    searchPage,
  } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const classes = useStyles();
  const tableScrollRef = useRef(null);

  const onScroll = () => {
    if (!hasMoreItems || isLoading) return;
    if (tableScrollRef.current?.scrollHeight - tableScrollRef.current?.scrollTop < tableScrollRef.current?.clientHeight + 150) {
      fetchItems();
    }
  }

  const scrollFunc = (e, ref, dir) => {
    if (dir === 'down') ref.scrollTo(0, ref?.scrollTop + 20)
    if (dir === 'up') ref.scrollTo(0, ref?.scrollTop - 20)
  }

  const { setModal } = useContext(PortfolioContext);
  const { width } = useWindowDimensions();
  return (
    <>
      {securities && securities.length > 0 && (
        <TableDarkContainer
          className={classes.tableContainer}
          style={searchPage && width < 900 ? { marginLeft: 'auto' } : { marginLeft: '-99px' }}
        >
          <InfiniteScroll
            dataLength={15}
            hasMore={hasMoreItems}
            isLoading={isLoading}
            loader={isLoading ? loader : undefined}
            next={fetchItems}
            scrollableTarget="scrollable-tbody"
            style={{ width: 'fit-content', overflow: 'hidden' }}
          >
            <Table
              className={classes.table}
              style={searchPage ? { width: 'fit-content', margin: '0 5px 60px 5px' } : {}}
            >
              <TableHead className={className ? className : ''}>
                <TableRow className={classes.theadRow}>
                  <TableCell />
                  {tableHeaders.map((th, idx) => (
                    <TableCell key={`header-${th}-${idx}`}>{th}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                id="scrollable-tbody"
                onScroll={() => searchPage && onScroll()}
                ref={tableScrollRef}
              >
                {securities && securities.length > 0 && securities.map((row, idx) => (
                  <TableRow
                    key={`${row.id}-${idx}`}
                  >
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                    >
                      <img
                        alt="row-icon"
                        className={classes.stockIcon}
                        onClick={() => (searchPage && row.hyperlink) && window.open(row.hyperlink, '_blank')}
                        src={row?.image_logo}
                        style={row?.hyperlink ? { cursor: 'pointer' } : {}}
                      />
                    </TableCell>
                    <TableCell
                      className={mobileDevice ? classes.mobileNameCell : classes.nameCell}
                      classes={{ root: classes.tableCellRoot }}
                      scope="row"
                    >
                      <div
                        className={mobileDevice ? classes.mobileCellContent : classes.cellContent}
                        style={!mobileDevice ? { width: '190px', maxWidth: '190px' } : {}}
                      >
                        {mobileDevice ?
                          <>
                            <p
                              className={classes.stockName}
                              onClick={() => {
                                if (mobileDevice && searchPage) navigate(`/place-order/BUY/${contestUser?.contest}/${row.id}`);
                              }}
                            >
                              {row.name}
                            </p>
                            <div className={classes.stockBtnWrapper}>
                              {!searchPage && (
                                <img
                                  alt="mb-usr-data"
                                  className={classes.popupIcon}
                                  onClick={() => {
                                    setModal({
                                      open: true,
                                      selectedIdx: 0,
                                      security: row
                                    })
                                  }}
                                  src="/images/mobile-stock-owned-off.png"
                                />
                              )}
                              <img
                                alt="mb-ptf-info"
                                className={classes.popupIcon}
                                onClick={() => {
                                  setModal({
                                    open: true,
                                    selectedIdx: 1,
                                    security: row,
                                  });
                                }}
                                src="/images/mobile-stock-info.png"
                              />
                              <img
                                alt="mb-ptf-data"
                                className={classes.popupIcon}
                                onClick={() => {
                                  setModal({
                                    open: true,
                                    selectedIdx: 2,
                                    security: row,
                                  });
                                }}
                                src="/images/mobile-stock-data.png"
                              />
                              <img
                                alt="mb-ptf-graph"
                                className={classes.popupIcon}
                                onClick={() => {
                                  setModal({
                                    open: true,
                                    selectedIdx: 3,
                                    security: row,
                                  });
                                }}
                                src="/images/mobile-stock-graph.png"
                              />
                              <img
                                alt="mb-ptf-news"
                                className={classes.popupIcon}
                                onClick={() => {
                                  setModal({
                                    open: true,
                                    selectedIdx: 4,
                                    security: row,
                                  });
                                }}
                                src="/images/mobile-stock-news.png"
                              />
                            </div>
                          </>
                          :
                          row.name
                        }
                      </div>
                    </TableCell>

                    {!mobileDevice ?
                      <>
                        <TableCell classes={{ root: classes.tableCellRoot }} >
                          <div
                            className={classes.cellContent}
                            style={searchPage ? { width: 65 } : {}}
                          >
                            {row.symbol}
                          </div>
                        </TableCell>
                        <TableCell classes={{ root: clsx(classes.tableCellRoot, classes.actionCell) }}>
                          {isOwner ? (
                            <BuySellButton
                              isOwner={isOwner}
                              link={{
                                buy: `/place-order/BUY/${contestUser?.contest}/${row.id}`,
                                sell: `/place-order/SELL/${contestUser?.contest}/${row.id}`
                              }}
                            />
                          ) : (
                            <LinkButton
                              className={classes.buyOnlyBtn}
                              to={`/place-order/BUY/${contestUser?.contest}/${row.id}`}
                            >
                              BUY
                            </LinkButton>
                          )}
                        </TableCell>
                        {!searchPage && (
                          <>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              <div className={mobileDevice ? classes.mobileCellContent : classes.cellContent}>
                                {thousandFormat(row.share)}
                              </div>
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              <div className={mobileDevice ? classes.mobileCellContent : classes.cellContent}>
                                ${thousandFormat(row.share * row.last_price)}
                              </div>
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              <div className={mobileDevice ? classes.mobileCellContent : classes.cellContent}>
                                ${thousandFormat(row.cost_basis)}
                              </div>
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              <div
                                className={mobileDevice ? classes.mobileCellContent : classes.cellContent}
                                style={(row.last_price - row.cost_basis) / row.cost_basis < 0 ? { color: '#B01700' } : (row.last_price - row.cost_basis) / row.cost_basis > 0 ? { color: '#00B853' } : {}}
                              >
                                {row.cost_basis ? formatPercentDecimal((row.last_price - row.cost_basis) / row.cost_basis) : 0}{' '}%
                              </div>
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              <div
                                className={mobileDevice ? classes.mobileCellContent : classes.cellContent}
                              >
                                {formatPercentDecimal(
                                  (row.share * row.last_price) / contestUser.value
                                )}{' '}
                                %
                              </div>
                            </TableCell>
                          </>
                        )}
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <div className={mobileDevice ? classes.mobileCellContent : classes.cellContent} >
                            ${thousandFormat(row.last_price)}
                          </div>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <div
                            className={mobileDevice ? classes.mobileCellContent : clsx(classes.cellContent, searchPage ? classes.searchTableCell : '')}
                            style={row.last_price - row.previous_day_close_price < 0 ? { color: '#B01700' } : row.last_price - row.previous_day_close_price > 0 ? { color: '#00B853' } : {}}
                          >
                            {thousandFormatWithSign(row.last_price - row.previous_day_close_price)}
                          </div>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <div
                            className={mobileDevice ? classes.mobileCellContent : clsx(classes.cellContent, searchPage ? classes.searchTableCell : '')}
                            style={(row.last_price - row.previous_day_close_price) /
                              row.previous_day_close_price < 0 ? { color: '#B01700' } : (row.last_price - row.previous_day_close_price) /
                                row.previous_day_close_price > 0 ? { color: '#00B853' } : {}}
                          >
                            {formatPercentDecimal(
                              (row.last_price - row.previous_day_close_price) /
                              row.previous_day_close_price
                            )}{' '}
                            %
                          </div>
                        </TableCell>

                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <img
                            alt="ptf-data"
                            className={classes.modalTableIcon}
                            onClick={() => {
                              setModal({
                                open: true,
                                selectedIdx: 1,
                                security: row
                              })
                            }}
                            src="/images/button-info.png"
                          />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <img
                            alt="ptf-data"
                            className={classes.modalTableIcon}
                            onClick={() => {
                              setModal({
                                open: true,
                                selectedIdx: 2,
                                security: row
                              })
                            }}
                            src="/images/button-data-chart.png"
                          />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <img
                            alt="ptf-chart"
                            className={classes.modalTableIcon}
                            onClick={() => {
                              setModal({
                                open: true,
                                selectedIdx: 3,
                                security: row
                              })
                            }}
                            src="/images/button-graph.png"
                          />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <img
                            alt="ptf-news"
                            className={classes.modalTableIcon}
                            onClick={() => {
                              setModal({
                                open: true,
                                selectedIdx: 4,
                                security: row
                              })
                            }}
                            src="/images/button-news.png"
                          />
                        </TableCell>
                      </>
                      :
                      <TableCell
                        className={classes.mobileNameCell}
                        classes={{ root: classes.tableCellRoot }}
                        scope="row"
                      >
                        <div className={classes.mobileCellContent}>
                          <p className={classes.stockPrice}>${thousandFormat(row.last_price)}</p>
                          <div style={{ display: 'flex', marginTop: 10 }}>
                            <img
                              alt="trend-arrow"
                              src={(row.last_price - row.previous_day_close_price) /
                                row.previous_day_close_price > 0 ? '/images/stock-panel-arrow-gain.png' : '/images/stock-panel-arrow-loss.png'}
                            />
                            <p className={classes.priceDiff}>
                              {formatPercentDecimal(
                                (row.last_price - row.previous_day_close_price) /
                                row.previous_day_close_price
                              )}{' '}
                              %</p>
                          </div>

                        </div>
                      </TableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </InfiniteScroll>
          {!mobileDevice && securities.length > 7 && (
            <div className={classes.tableScrollWrapper}>
              <div
                className={classes.scrollArrow}
                onClick={(e) => scrollFunc(e, tableScrollRef?.current, 'up')}
              >
                <KeyboardArrowUpIcon className={classes.scrollIcon} />
              </div>
              <div
                className={classes.scrollArrow}
                onClick={(e) => scrollFunc(e, tableScrollRef?.current, 'down')}
              >
                <KeyboardArrowDownIcon className={classes.scrollIcon} />
              </div>
            </div>
          )}
        </TableDarkContainer>
      )}
    </>
  )
}
DetailTable.propTypes = {
  className: PropTypes.string,
  contestUser: PropTypes.objectOf(PropTypes.any),
  fetchItems: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOwner: PropTypes.bool,
  loader: PropTypes.any,
  searchPage: PropTypes.bool,
  securities: PropTypes.arrayOf(PropTypes.object),
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
};

DetailTable.defaultProps = {
  isOwner: false,
  searchPage: false,
};

export default DetailTable;
