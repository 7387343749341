import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Hidden } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';

const GridHidden = ({ lg, className, ...rest}) => {
  return (
    <GridItem 
      className={clsx(className)}
      component={Hidden}
      implementation="css"
      lg={lg}
      mdDown
      {...rest}
    />
  );
};

GridHidden.defaultProps = {
  className: null,
  lg: 1
};

GridHidden.propTypes = {
  className: PropTypes.string,
  lg: PropTypes.number
};

export default GridHidden;
