/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import { Field } from 'react-final-form';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import clsx from 'clsx';

import styles from './styles';
import palette from 'theme/palette';
import { SelectFieldWrapper } from './Wrapper';

const useStyles = makeStyles(styles);

const theme = createTheme({
  palette: {
    ...palette,
    type: 'light',
    background: {
      paper: 'white'
    }
  }
});

export default function SelectField(props) {
  const {
    name,
    label,
    data,
    children,
    required,
    multiple,
    fieldProps,
    inputLabelProps,
    formControlProps,
    formHelperTextProps,
    menuItemProps,
    labelWidth,
    white,
    handleChange,
    ...restSelectProps
  } = props;
  const classes = useStyles();
  if (!data && !children) {
    throw new Error('Please specify either children or data as an attribute to the Select component.');
  }

  const [error, setError] = useState(null);

  // This is for supporting the special case of variant="outlined"
  // Fixes: https://github.com/lookfirst/mui-rff/issues/91
  const { variant } = restSelectProps;
  const inputLabel = React.useRef(null);
  const [labelWidthState, setLabelWidthState] = React.useState(0);
  React.useEffect(() => {
    if (inputLabel.current) {
      setLabelWidthState(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        error={!!error}
        fullWidth
        required={required}
        {...formControlProps}
      >
        <InputLabel
          className={clsx(classes.label, white && classes.white)}
          htmlFor={name}
          ref={inputLabel}
          {...inputLabelProps}
          shrink
        >
          {label}
        </InputLabel>
        <Field
          name={name}
          render={fieldRenderProps => {
            const { meta, input } = fieldRenderProps;

            input.multiple = multiple;

            const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

            setError(showError ? meta.error : null);

            return (
              <SelectFieldWrapper
                {...fieldRenderProps}
                handleChange={handleChange}
                labelWidth={variant === 'outlined' ? labelWidthState : labelWidth}
                {...restSelectProps}
              />
            );
          }}
          {...fieldProps}
        >
          {data
            ? data.map(item => (
              <MenuItem
                key={item.value}
                value={item.value}
                {...menuItemProps}
              >
                {item.label}
              </MenuItem>
            ))
            : children
          }
        </Field>
        {error ? <FormHelperText {...formHelperTextProps}>{error}</FormHelperText> : <></>}
      </FormControl>
    </ThemeProvider>
  );
}

SelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelWidth: PropTypes.any,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  fieldProps: PropTypes.objectOf(PropTypes.any),
  formControlProps: PropTypes.objectOf(PropTypes.any),
  inputLabelProps: PropTypes.objectOf(PropTypes.any),
  formHelperTextProps: PropTypes.objectOf(PropTypes.any),
  menuItemProps: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  white: PropTypes.bool,
  handleChange: PropTypes.func,
  children: PropTypes.node,
}
