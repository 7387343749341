import { COLORS } from 'config/constants';
import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    padding: theme.spacing(0)
  },
  modal: {
  },
  cardRoot: {
    backgroundColor: COLORS.white,
    borderRadius: 0,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  fullName: {
    fontSize: 24,
    fontWeight: 700
  },
  content: {
    padding: theme.spacing(12, 8, 8),
    minHeight: 400,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4, 4)
    }
  },
  description: {
    margin: theme.spacing(4, 0)
  },
  main: {
    position: 'relative',
    maxWidth: 750,
    width: '80vw',
    '&:focus': {
      outline: 'none'
    },
    margin: 'auto',
    marginTop: 30,
    overflow: 'visible',
  },
  photo: {
    width: 180,
    height: 180,
    position: 'absolute',
    top: theme.spacing(4.5),
    left: '50%',
    transform: 'translate(-50%, -35%)',
    [theme.breakpoints.down('sm')]: {
      width: 130,
      height: 130
    }
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 5, 0.7)',
  },
  close: {
    color: grey[800]
  },
  dialogContent: {
    padding: 0,
  },
  cardBox: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5)
    }
  }
});

export default styles
