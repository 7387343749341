import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import styles from './styles';
import BootstrapInput from 'components/FormElement/BootstrapInput';
import { InputAdornment } from '@material-ui/core';

const useStyles = makeStyles(styles);

const StockSelector = ({ className, handleSearchChange }) => {
  const classes = useStyles();

  function handleSearch(e) {
    handleSearchChange(e.target.value);
  }

  return (
    <div className={classes.stockInputWrap}>
      <h4 className={classes.bootyLabel}>Search Stock</h4>
      <BootstrapInput
        className={clsx(classes.root, className)}
        classes={{
          input: classes.roundInput
        }}
        endAdornment={
          <InputAdornment
            className={classes.adorn}
            position="end"
          >
            <img
              alt="search-icon"
              className={classes.searchIcon}
              src="/images/new-search-icon.png"
            />
          </InputAdornment>
        }
        onChange={_.debounce(handleSearch, 500, {
          leading: true
        })}
        placeholder="Search"
      />
    </div>
  );
};

StockSelector.propTypes = {
  className: PropTypes.string,
  handleSearchChange: PropTypes.func
};

StockSelector.defaultProps = {
  className: null,
  handleSearchChange: () => null
};

export default StockSelector;
