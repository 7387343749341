import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  ClickAwayListener, Divider, Grow, Icon, MenuItem, MenuList, Paper, Popper,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '../MkrButton';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomDropdown = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = param => {
    setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(param);
    }
  };

  const handleCloseAway = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const {
    buttonText,
    buttonIcon,
    dropdownList,
    buttonProps,
    dropup,
    dropdownHeader,
    hoverColor,
    left,
    rtlActive,
    noLiPadding
  } = props;

  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverColor + 'Hover']]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive,
  });

  let icon;
  switch (typeof buttonIcon) {
    case 'object':
      icon = <props.buttonIcon className={classes.buttonIcon} />;
      break;
    case 'string':
      icon = <Icon className={classes.buttonIcon}>{props.buttonIcon}</Icon>;
      break;
    default:
      icon = null;
      break;
  }

  return (
    <div>
      <div>
        <Button
          aria-haspopup="true"
          aria-label="Notifications"
          aria-owns={anchorEl ? 'menu-list' : null}
          {...buttonProps}
          onClick={handleClick}
          //style={buttonProps && !buttonProps.className?.includes('signup') ? {} : anchorEl !== null && buttonProps?.className.includes('signup') ? { backgroundColor: 'transparent', color: 'white' } : { backgroundColor: 'white', color: '#26A7DF' }}
        >
          {icon}
          {buttonText !== undefined ? buttonText : null}
          {anchorEl !== null ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
      </div>
      <div className={classes.popperWrapper}>
        <Popper
          anchorEl={anchorEl}
          className={classNames({
            [classes.popperClose]: !anchorEl,
            [classes.popperResponsive]: true
          })}
          disablePortal
          open={Boolean(anchorEl)}
          placement={
            dropup
              ? left
                ? 'top-start'
                : 'top'
              : left
                ? 'bottom-start'
                : 'bottom'
          }
          transition
        >
          {() => (
            <Grow
              id="menu-list"
              in={Boolean(anchorEl)}
              style={
                dropup
                  ? { transformOrigin: '0 100% 0' }
                  : { transformOrigin: '0 0 0' }
              }
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseAway}>
                  <MenuList
                    className={classes.menuList}
                    role="menu"
                  >
                    {dropdownHeader !== undefined ? (
                      <MenuItem
                        className={classes.dropdownHeader}
                        onClick={() => handleClose(dropdownHeader)}
                      >
                        {dropdownHeader}
                      </MenuItem>
                    ) : null}
                    {dropdownList.map((prop, key) => {
                      if (prop.divider) {
                        return (
                          <Divider
                            className={classes.dropdownDividerItem}
                            key={key}
                            onClick={() => handleClose('divider')}
                          />
                        );
                      }
                      return (
                        <MenuItem
                          className={dropdownItem}
                          key={key}
                          onClick={() => handleClose(prop)}
                        >
                          {prop}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

CustomDropdown.defaultProps = {
  hoverColor: 'primary',
};

CustomDropdown.propTypes = {
  buttonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  buttonProps: PropTypes.object,
  buttonText: PropTypes.node,
  dropdownHeader: PropTypes.node,
  dropdownList: PropTypes.array,
  dropup: PropTypes.bool,
  hoverColor: PropTypes.oneOf([
    'black',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  left: PropTypes.bool,
  noLiPadding: PropTypes.bool,
  // function that returns the selected item
  onClick: PropTypes.func,
  rtlActive: PropTypes.bool,
};

export default CustomDropdown;
