import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const ScrollToTop = props => {

  const { pathname } = useLocation();
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {props.children}
    </>
  );
};

ScrollToTop.defaultProps = {
  className: null
};

ScrollToTop.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default ScrollToTop;
