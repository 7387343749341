import React from 'react';
import PropTypes from 'prop-types';
import {
  Select as MuiSelect
} from '@material-ui/core';
import BootstrapInput from '../BootstrapInput';
import { makeStyles } from '@material-ui/styles';
import styles from '../styles';
import clsx from 'clsx';

const useStyles = makeStyles(styles);

export default function SelectWrapper(props) {
  const {
    className,
    input: { name, onChange, ...restInput },
    // meta,
    handleChange,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <MuiSelect
      input={(
        <BootstrapInput
          className={clsx(classes.input, className)}
        />)
      }
      inputProps={restInput}
      name={name}
      onChange={(event) => {onChange(event); handleChange && handleChange(event);}}
      {...rest}
    />);
}

SelectWrapper.propTypes = {
  handleChange: PropTypes.func,
  input: PropTypes.objectOf(PropTypes.any),
  meta: PropTypes.any,
  className: PropTypes.string,
};
