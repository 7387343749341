import { handleActions } from 'redux-actions'

const initState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 6000,
    message: 'Hi',
    variant: null,
  },
};

export default handleActions(
  {
    SHOW_MESSAGE: (state, action) => {
      return {
        state: true,
        options: {
          ...initState.options,
          ...action.payload,
        },
      };
    },
    HIDE_MESSAGE: state => {
      return {
        ...state,
        state: null,
      };
    },
  },
  initState,
);
