import { colors } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: theme.spacing(0),
    paddingRight: 4,
    maxWidth: 444,
    width: '100%',
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: '6px',
    minWidth: 315,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 364,
      borderRadius: 4,
      paddingRight: 0,
    }
  },
  listWrappa: {
    maxHeight: '600px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '99%',
    paddingRight: '78%',
    [theme.breakpoints.down('md')]: {
      maxHeight: '500px',
      paddingRight: '26% !important',
      marginLeft: '16%'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '500px',
      marginLeft: '45px'
    },
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'transparent'
    },
  },
  bootyLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '21px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 0.36,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontSize: 14,
      lineHeight: '16px',
      letterSpacing: 0.28,
      marginBottom: 10,
    },
  },
  placeholder: {
    color: colors.grey[500],
    position: 'absolute',
    left: 15,
  },
  roundInput: {
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      borderRadius: 4
    },
    [theme.breakpoints.up('md')]: {
      padding: 12
    },
    '&&:focus': {
      borderRadius: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        borderRadius: 4
      },
    }
  },
  sectorItem: {
    height: 35,
    display: 'flex',
    justifyContent: 'space-around',
    minHeight: 35,
    minWidth: '100px',
    marginTop: '10px',
    maxWidth: 'max-content',
    backgroundColor: 'white !important',
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 23,
    color: '#26A7DF',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '16px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 1.4,
    textTransform: 'uppercase',
    paddingLeft: 0,
    '& li:last-child': {
      marginBottom: 20,
    },
    '& img': {
      marginRight: 10,
      marginTop: 2
    },
    '&:hover': {
      backgroundColor: '#26A7DF !important',
      color: 'white !important',
    },
    '&:active': {
      backgroundColor: '#26A7DF !important',
    }
  },
  clearSelectWrapper: {
    position: 'relative',
    zIndex: 9,
  },
  imgClose: {
    position: 'absolute',
    cursor: 'pointer',
    color: 'black',
    fill: '#26A7DF',
    zIndex: 9,
    left: 15,
    bottom: 10,
    '&:hover': {
      borderRadius: '50%',
      fill: '#26A7DF',
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    [theme.breakpoints.down('md')]: {
      left: 35,
      bottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      left: 15,
      bottom: 5,
    },
    '& svg': {
      color: 'black',
      fill: '#26A7DF'
    }
  }
});

export default styles
