import { COLORS } from 'config/constants';

const pageStyles = theme => ({
  root: {
  },
  blueWrap: {
    background: 'url("/images/white_fintron.png") no-repeat fixed, linear-gradient(270deg, #F719FF, #1BAAE2)',
    minHeight: '100vh',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  blueWrapSmall: {
    backgroundColor: COLORS.darkBlue,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(32, 0, 5)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(24, 0, 3)
    }
  },
  banner: {
    color: COLORS.white,
    font: 'normal normal 300 48px/71px Oswald',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal 300 39px/58px Oswald',
    }
  },
  subBanner: {
    marginTop: theme.spacing(2),
    color: COLORS.white,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '21px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 0.36,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '16px',
      letterSpacing: 0.28,
    }
  },
  topWrap: {
    maxWidth: '900px'
  },
  contestList: {
    marginTop: theme.spacing(8)
  },
  back: {
    margin: theme.spacing(3, 0, 4)
  },
  green: {
    color: COLORS.green
  },
  red: {
    color: COLORS.red
  },
  modalButtonContainer: {
    paddingTop: theme.spacing(2),
    width: 334,
    margin: 'auto',
    '& button': {
      width: 334,
      height: 44,
      boxShadow: '0px 3px 3px #00000026',
      borderRadius: 23,
      font: 'normal normal bold 21px/24px Roboto',
      letterSpacing: 2.1
    },
    [theme.breakpoints.down('sm')]: {
      width: 247,
      '& button': {
        width: 247,
        height: 33,
        boxShadow: '0px 3px 3px #00000026',
        borderRadius: 17,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '16px',
        letterSpacing: 1.4
      },
    }
  },
  modalButtonSet: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
  },
  modalButtonAround: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  primaryGradient: {
    background: 'linear-gradient(130deg, #26A8DF 0%, #F719FF 100%)'
  },
});

export default pageStyles;
