import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import withWidth from '@material-ui/core/withWidth';
import { MagicContainer, GridItem, GridContainer } from '..';
import FooterLeft from './FooterLeft';
import FooterSocial from './FooterSocial';
import FooterRight from './FooterRight';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: 'transparent url("/images/footer-04.png") 0% 0% no-repeat padding-box',
    backgroundSize: 'cover',
    minHeight: 960,
    zIndex: 11,
    margin: '0 -10px'
  },
  socialWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  social: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    fontSize: '14px',
    fontWeight: '300',
    [theme.breakpoints.down('sm')]: {
      left: '3%'
    }
  },
  logoWrap: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: theme.spacing(0),
      left: 0,
      paddingTop: theme.spacing(5)
    },
  },
  logo: {
    width: 130,
    '&>img': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 100
    }
  },
  footerHomeLogo: {
    width: '100%',
    height: '100%',
    maxWidth: '514px',
    maxHeight: '264px',
    margin: '0 15% 5% 0',
    objectFit: 'scale-down',
    imageRendering: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      maxWidth: 'unset',
      margin: '0 auto 10%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto 10%',
    }
  },
  imageBubble: {
    position: 'absolute',
    left: '65%',
    bottom: '2%',
    height: 'calc(100% - 30px)',
  }
}));

const description = {
  left: 'Us founders here at FinTron are on a mission to make personal finance, career readiness and entrepreneurship understandable, accessible, and doable, through our all-in-one equitable education technology platform.\n\nWe strive to accelerate financial security for the Mobile-First Generation through better education, affordable products, and philanthropic give-back programs.\n\nFor generations, personal finance, career education and entrepreneurship have been taught the old, complicated way. For our generation, there’s FinTron. \n\n201 Broad Street, Floor 6 Suite 604\nStamford, CT 06901',
  right: 'This website is operated by FinTron, Inc., Please consider your objectives before investing, Past performance does not guarantee future results. Investment outcomes and projections are hypothetical in nature. FinTron reserves the right to restrict or revoke access to our site at anytime. Copyright material of FinTron. This product is an investing GAME and data is not guaranteed accurate.\n©2022 FinTron’s | Disclosure'
};

const Footer = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MagicContainer>
        <GridContainer
          justifyContent="space-between"
        >
          <GridItem
            md={5}
            sm={12}
            xs={12}
          >
            <img
              alt="finton-u-footer"
              className={classes.footerHomeLogo}
              src="/images/fintronu-home-footer-illustration.png"
            />
            <FooterLeft description={description.left} />
          </GridItem>
          <GridItem
            md={6}
            xs={12}
          >
            <div>
              <FooterRight description={description.right} />
            </div>
          </GridItem>
          <GridItem
            md={8}
            xs={11}
          >
            <div className={classes.social}>
              <FooterSocial />
            </div>
          </GridItem>
        </GridContainer>
      </MagicContainer>
    </div>
  );
};

Footer.propTypes = {
  route: PropTypes.object,
  width: PropTypes.any
};

export default withWidth()(Footer);
