import React, { useState, useLayoutEffect } from 'react'
import { BrowserRouterProps, Router } from 'react-router-dom'
import { BrowserHistory } from 'history';

interface Props extends BrowserRouterProps {
  history: BrowserHistory;
}

const CustomRouter = ({
  basename,
  children,
  history, }: Props) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
export default CustomRouter;