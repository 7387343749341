import MuiPaper from './MuiPaper';
import MuiTypography from './MuiTypography';

export default {
  MuiPaper,
  MuiTypography,
  MuiListItemIcon: {
    root: {
      minWidth: 32
    }
  },
};
