import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(8)
  },
}));

export default function ContentProgress({ color, size}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        color={color}
        size={size}
      />
    </div>
  );
}
ContentProgress.defaultProps = {
  color: 'secondary'
}
ContentProgress.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}
