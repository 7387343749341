import http from './http';

const Zogo = {
  getCompletedModulesBySkills:(data) => http.post('/api/zogo/completed-modules/by-skills/', { data }),
  getModules:(id) => http.get(`/api/zogo/modules/${id}`),
  getCategories: (id) => http.get(`/api/zogo/categories/${id}`),
  getSkills: (id) => http.get(`/api/zogo/skills/${id}`),
  updateZogoProgress: (data) => http.put('/api/points/points/zogo/', { data }),
};

export default Zogo;
