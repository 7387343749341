import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Card, Slide, Typography, useMediaQuery } from '@material-ui/core';

import { useWindowDimensions } from 'utils';
import { thousandFormat, formatPercentDecimal, thousandFormatWithSign } from 'utils/functions';
import { PortfolioContext } from 'context/PortfolioContext';
import { COLORS } from 'config/constants';
import { CustomButton, FinTooltip, Modal, StockPriceModal, ContentProgress } from 'components';

const useStyles = makeStyles(theme => ({
  popupCard: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 536,
    minWidth: '470px',
    height: 'calc(100vh - 325px)',
    width: 'fit-content',
    minHeight: 610,
    boxShadow: '0px 2px 4px #00000040 !important',
    borderRadius: '26px !important',
    background: '#FFFFFF !important',
    padding: '0 31px',
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      maxWidth: '450px',
      minWidth: '420px',
      height: 'fit-content',
      maxHeight: 585,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 350,
      maxHeight: 510,
      overflowY: 'auto',
      height: 'calc(100% - 80px)',
      minHeight: '470px',
      minWidth: 'calc(100vw - 17%)',
      padding: '0 20px',
      marginLeft: '35px !important',
      position: 'unset !important',
      opacity: 1,
      top: 'unset !important',
      left: 'unset !important',
      right: 'unset !important',
    },
  },
  popupCardNotFocused: {
    maxWidth: 392,
    minWidth: 375,
    height: 510,
    boxShadow: '0px 2px 4px #00000040 !important',
    borderRadius: '26px !important',
    background: '#FFFFFF !important',
    padding: '0 31px',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      top: '115px !important',
      position: 'absolute !important',
      left: '95% !important',
      maxWidth: 'min-content',
      minWidth: 305,
      opacity: '1 !important',
      padding: '0 29px',
    },
    [theme.breakpoints.down('sm')]: {
      left: '95% !important',
      maxWidth: 'min-content',
      minWidth: 260,
      height: 411,
    },
  },
  popupCardPrevSlide: {
    [theme.breakpoints.down('md')]: {
      top: '115px !important',
      height: 510,
      maxWidth: 450,
      minWidth: 420,
      left: 'unset !important',
      right: '95% !important',
      position: 'absolute !important',
      boxShadow: '0px 2px 4px #00000040 !important',
      borderRadius: '26px !important',
      background: '#FFFFFF !important',
      '& div': {
        visibility: 'hidden',
      },
    },
    [theme.breakpoints.down('sm')]: {
      left: '-95% !important',
      right: 'unset !important',
      width: 260,
      height: 411,
    },
  },
  popupModal: {
    boxShadow: 'none !important',
    borderRadius: 0,
  },
  modalIcon: {
    height: 61,
    width: 61,
    imageRendering: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 56,
      height: 56,
    },
    [theme.breakpoints.down('xs')]: {
      width: 52,
      height: 52,
    },
  },
  modalBtn: {
    width: 150,
    height: 62,
  },
  modalBtnWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-evenly',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly !important',
      margin: '5px -2px 0 2%',
      '& img': {
        imageRendering: 'auto',
        margin: '0 20px',
      },
    },
  },
  modalRoot: {
    '& div:nth-child(3)': {
      '& div:first-child': {
        boxShadow: 'none ',
        borderRadius: '0px ',
        background: 'transparent',
        marginRight: 16,
        width: '100%',
        maxWidth: 'unset',
        [theme.breakpoints.up('lg')]: {
          maxWidth: 'unset',
          marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
        },
      },
    }
  },
  portfolioDeets: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 0',
    '& :first-child': {
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'Roboto',
    },
    '& :last-child': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'Roboto, sans-serif',
    },
  },
  portfolioDataDeets: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 0',
    '& img': {
      imageRendering: 'auto',
      position: 'absolute',
      marginTop: 1,
      [theme.breakpoints.down('sm')]: {
        left: 'unset',
      },
    },
    '& :last-child': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'Roboto, sans-serif',
    },
  },
  dataLabel: {
    fontSize: 14,
    marginLeft: 20,
    lineHeight: '16px',
    fontFamily: 'Roboto',
  },
  chartWrappa: {
    position: 'relative',
    borderRadius: 26,
    boxShadow: '0px 2px 4px #00000040',
    height: 275,
    width: 326,
    [theme.breakpoints.down('sm')]: {
      margin: '0 -20px',
    },
  },
  modalTextContent: {
    width: 'fit-content',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '22px',
    fontFamily: 'Roboto, sans-serif',
    overflowY: 'auto',
    letterSpacing: 0.28,
    height: '100%',
    flexGrow: 2,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
      overflowY: 'unset',
      width: 'fit-content',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'Roboto, sans-serif',
    },
  },
  modalDataStats: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%'
  },
  modalFooter: {
    backgroundColor: 'transparent',
    marginTop: '-15px',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  modalFooterBtn: {
    boxShadow: '0px 2px 4px #00000040',
    backgroundColor: '#26A7DF',
    color: COLORS.white,
    width: 352,
    borderRadius: 26,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 21,
    lineHeight: '24px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 2.1,
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: '#81d4fa'
    },
  },
  modalScreenImg: {
    margin: '15% 35% 0',
    height: 70,
    width: 105,
    alignSelf: 'center',
    imageRendering: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '15% 30% 0',
    },
  },
  modalTitleImg: {
    marginBottom: 12,
    imageRendering: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 'unset',
    },
  },
  modalTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '26px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 0.4,
    color: '#0B243F',
    textTransform: 'uppercase',
    marginLeft: 10,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 5,
      marginLeft: 0,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'Roboto, sans-serif',
      letterSpacing: 0,
    },
  },
  linkBtn: {
    fontWeight: 800,
    fontSize: 10,
    letterSpacing: 1,
    textDecoration: 'underline',
    color: '#26A7DF',
    paddingVertical: 0,
    padding: 0,
    margin: '10px 0',
  },
  hiddenCard: {
    height: 0,
    width: 0,
    visibility: 'hidden',
  },
  modalLogoTop: {
    position: 'sticky',
    zIndex: 9999,
    height: 40,
    width: 90,
    margin: '0 47%',
    [theme.breakpoints.down('md')]: {
      margin: '0 46% 20px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 39% 20px',
    },
    '& img': {
      width: 100,
      height: 100,
      imageRendering: 'auto',
    },
  },
  modBody: {
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 'inherit'
    },
  },
  noInfoTxt: {
    marginTop: '5%',
    textAlign: 'center',
    width: '100%',
    fontSize: 18,
    letterSpacing: 0.29,
    fontWeight: 400
  }
}));

const ModalSlides = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { contestUser, modalMktData, modalNews, chartUI } = props;
  const { setModal, modal } = useContext(PortfolioContext);

  const closeModal = () => {
    setModal({ open: false, selectedIdx: null, security: {} })
  }

  const modalTitleBySelection = (row) => {
    switch (modal?.selectedIdx) {
      case 1:
        return { modalTitle: 'INFO', img: '/images/securities-popup-illustration-info.png' }
      case 2:
        return { modalTitle: 'DATA', img: '/images/securities-popup-illustration-data.png' }
      case 3:
        return { modalTitle: 'GRAPH', img: '/images/securities-popup-illustration-graph.png' }
      case 4:
        return { modalTitle: 'NEWS', img: '/images/securities-popup-illustration-news.png' }
      default:
        return { modalTitle: row?.name, ticker: row?.symbol, img: '/images/securities-popup-illustration-owned.png' }
    }
  };

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleSmallScreens = mobileDevice || mediumScreen;
  return (
    <Modal
      body={
        <div>
          {modal.open && modalMktData?.image_logo && (
            <>
              <div
                className={classes.modalLogoTop}
              >
                <img
                  alt="row-icon"
                  src={modalMktData.image_logo}
                />
              </div>
              {mobileDevice && (
                <div style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', margin: '-55px 50px', zIndex: 9999, }}>
                    <img
                      alt="close-icon"
                      onClick={() => setModal({ open: false, selectedIdx: null, security: {} })}
                      src="/images/closeX.svg"
                    />
                  </div>
                </div>
              )}
            </>
          )}

          {modal?.open && mobileDevice && Object.keys(modal?.security).length > 0 && modal?.selectedIdx >= 0 && (
            <Slide
              appear
              direction={modal?.selectedIdx <= 1 ? 'left' : 'right'}
              in
              keepmounted="true"
              timeout={500}
            >
              <Card
                className={modal?.selectedIdx === 0 ? classes.popupCard : handleSmallScreens && modal?.selectedIdx === 1 ? classes.popupCardPrevSlide : mobileDevice && modal?.selectedIdx !== 4 ? classes.hiddenCard : classes.popupCardNotFocused}
                onClick={() => {
                  if (modal?.selectedIdx !== 0) setModal({ ...modal, selectedIdx: 0 });
                }}
                style={modal?.selectedIdx !== 0 && modal?.selectedIdx === 1 ? { top: 140, left: 10, position: 'absolute' } : modal?.selectedIdx === 1 && mobileDevice ? { left: '-336px', position: 'absolute' } : mobileDevice && modal?.selectedIdx === 4 ? { top: 140, right: 10, position: 'absolute' } : { top: 'unset', left: 'unset', position: 'unset', margin: '0px auto', }}
              >
                <img
                  className={classes.modalScreenImg}
                  src={'/images/securities-popup-illustration-owned.png'}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgray', margin: '10px 0' }}>
                  <Typography
                    className={classes.modalTitle}
                    gutterBottom
                    variant={'h3'}
                  >
                    {modalTitleBySelection(modal?.security)?.modalTitle}
                  </Typography>
                  {modalTitleBySelection(modal?.security)?.ticker && (
                    <Typography
                      gutterBottom
                      style={{
                        textAlign: 'end', font: 'normal normal bold 14px/16px Roboto',
                        letterSpacing: 0
                      }}
                      variant="p"
                    >
                      {modal?.security?.symbol}
                    </Typography>
                  )}
                </div>
                <div className={classes.modalDataStats}>
                  <span className={classes.portfolioDeets}>
                    <p>Shares:</p>
                    <p>{modal?.security?.share}</p>
                  </span>
                  <span className={classes.portfolioDeets}>
                    <p>Current Market Price:</p>
                    <p>${thousandFormat(modal?.security?.last_price)}</p>
                  </span>
                  <span className={classes.portfolioDeets}>
                    <p>Total Value:</p>
                    <p>${thousandFormat(modal?.security?.share * modal?.security?.last_price)}</p>
                  </span>
                  <span className={classes.portfolioDeets}>
                    <p>Cost Basis Price:</p>
                    <p>${thousandFormat(modal?.security?.cost_basis)}</p>
                  </span>
                  <span className={classes.portfolioDeets}>
                    <p>Gain/Loss %:</p>
                    <p
                      style={(modal?.security?.last_price - modal?.security?.cost_basis) / modal?.security?.cost_basis < 0 ? { color: '#B01700' } : (modal?.security?.last_price - modal?.security?.cost_basis) / modal?.security?.cost_basis > 0 ? { color: '#00B853' } : {}}
                    >
                      {modal?.security?.cost_basis ?
                        formatPercentDecimal((modal?.security?.last_price - modal?.security?.cost_basis) / modal?.security?.cost_basis)
                        :
                        0
                      }{' '}%
                    </p>
                  </span>
                  <span className={classes.portfolioDeets}>
                    <p>Portfolio Weight:</p>
                    <p>
                      {formatPercentDecimal((modal?.security?.share * modal?.security?.last_price) / contestUser?.value)}{' '}%
                    </p>
                  </span>
                  <span className={classes.portfolioDeets}>
                    <p>Daily Change ($):</p>
                    <p
                      style={modal?.security?.last_price - modal?.security?.previous_day_close_price < 0 ? { color: '#B01700' } : modal?.security?.last_price - modal?.security?.previous_day_close_price > 0 ? { color: '#00B853' } : {}}
                    >
                      {thousandFormatWithSign(modal?.security?.last_price - modal?.security?.previous_day_close_price)}
                    </p>
                  </span>
                  <span className={classes.portfolioDeets}>
                    <p>Daily Change (%):</p>
                    <p
                      style={(modal?.security?.last_price - modal?.security?.previous_day_close_price) /
                        modal?.security?.previous_day_close_price < 0 ? { color: '#B01700' } : (modal?.security?.last_price - modal?.security?.previous_day_close_price) /
                          modal?.security?.previous_day_close_price > 0 ? { color: '#00B853' } : {}}
                    >
                      {formatPercentDecimal((modal?.security?.last_price - modal?.security?.previous_day_close_price) / modal?.security?.previous_day_close_price)}{' '}%
                    </p>
                  </span>
                </div>

                {!mobileDevice && modal?.selectedIdx === 0 &&
                  <CustomButton
                    className={classes.modalFooterBtn}
                    onClick={closeModal}
                    rounded
                    title="CLOSE"
                  />
                }
              </Card>
            </Slide>
          )}

          {modal.open && modal?.selectedIdx >= 0 && (
            <Slide
              appear
              direction={modal?.selectedIdx <= 0 ? 'left' : 'right'}
              in
              keepmounted="true"
              timeout={500}
            >
              <Card
                className={modal?.selectedIdx === 1 ? classes.popupCard : handleSmallScreens && modal?.selectedIdx === 2 ? classes.popupCardPrevSlide : (mobileDevice && modal?.selectedIdx === 4) || (!mobileDevice && modal?.selectedIdx === 3 && modal?.selectedIdx !== 4) ? classes.hiddenCard : classes.popupCardNotFocused}
                onClick={() => {
                  if (modal?.selectedIdx !== 1) setModal({ ...modal, selectedIdx: 1 })
                }}
                style={modal?.selectedIdx !== 0 && modal?.selectedIdx === 2 ? { top: 140, left: 10, position: 'absolute' } : !mobileDevice && modal?.selectedIdx === 4 ? { top: 140, right: 10, position: 'absolute' } : { top: 'unset', left: 'unset', position: 'unset', margin: '0px auto', transition: '0.2s position ease-in' }}
              >
                <img
                  className={classes.modalScreenImg}
                  src={'/images/securities-popup-illustration-info.png'}
                />
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightgray', margin: '10px 0' }}>
                  {!mobileDevice && (
                    <img
                      alt="info"
                      className={classes.modalTitleImg}
                      src="/images/full-modal-icon-info.png"
                    />
                  )}
                  <Typography
                    className={classes.modalTitle}
                    gutterBottom
                    variant={'h3'}
                  >
                    Info
                  </Typography>
                </div>
                <div className={classes.modalTextContent}>
                  {modalMktData && modalMktData.description?.length ? (
                    <p>{modalMktData?.description}</p>
                  ) : (
                    <Typography
                      className={classes.noInfoTxt}
                      gutterBottom
                      variant={'h5'}
                    >
                      {`No description found for ${modal?.security.name}`}
                    </Typography>
                  )}

                </div>
                {!mobileDevice && modal?.selectedIdx === 1 &&
                  <CustomButton
                    className={classes.modalFooterBtn}
                    onClick={closeModal}
                    rounded
                    title="CLOSE"
                  />
                }
              </Card>
            </Slide>
          )}

          {modal.open && modal?.selectedIdx >= 1 && modal?.selectedIdx !== 4 && modalMktData && Object.keys(modalMktData).length > 0 && (
            <Slide
              appear
              direction={modal?.selectedIdx <= 2 ? 'left' : 'right'}
              in
              keepmounted="true"
              timeout={500}
            >
              <Card
                className={modal?.selectedIdx === 2 ? classes.popupCard : handleSmallScreens && modal?.selectedIdx === 3 ? classes.popupCardPrevSlide : classes.popupCardNotFocused}
                onClick={() => {
                  if (modal?.selectedIdx !== 2) setModal({ ...modal, selectedIdx: 2 })
                }}
                style={modal?.selectedIdx === 1 ? { top: 140, right: 10, position: 'absolute' } : modal?.selectedIdx === 3 ? { top: 140, right: 'unset', left: 10, position: 'absolute' } : { top: 'unset', right: 'unset', left: 'unset', position: 'unset', margin: '0px auto', }}
              >
                <img
                  className={classes.modalScreenImg}
                  src={'/images/securities-popup-illustration-data.png'}
                />
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightgray', margin: '10px 0' }}>
                  {!mobileDevice && (
                    <img
                      alt="data"
                      className={classes.modalTitleImg}
                      src="/images/full-modal-icon-data.png"
                    />
                  )}
                  <Typography
                    className={classes.modalTitle}
                    gutterBottom
                    variant="h3"
                  >
                    Data
                  </Typography>
                </div>
                <div className={classes.modalDataStats}>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="The total value of a company's outstanding shares."
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>Market Cap:</p>
                    <p>${thousandFormat(modalMktData?.market_cap)}</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="A sum of money paid to shareholders on a quarterly basis by a company. This sum of money is usually displayed as a percentage of their stock price."
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>Dividends:</p>
                    <p>${thousandFormat(modalMktData?.dividends)}</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="The amount by which revenue from sales exceeds costs in a business."
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>Profit Margin:</p>
                    <p>{formatPercentDecimal(modalMktData?.profit_margin)}%</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="Income generated from business operations."
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>Revenues:</p>
                    <p>${thousandFormat(modalMktData?.revenues)}</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="Earnings before interest, taxes, depreciation, and amortization."
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>EBITDA:</p>
                    <p>${thousandFormat(modalMktData?.ebitda)}</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="The value of a company’s assets minus liabilities and intangible assets. (intellectual property, patents ETC)"
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>Book Value/Share:</p>
                    <p>${thousandFormat(modalMktData?.book_value_per_share)}</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="Price of the individual security compared to the companies earnings per share (earnings per share is the total profit of the company divided by the total number of shares for a company)"
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>PE Ratio:</p>
                    <p>{thousandFormat(modalMktData?.pe_ratio)}</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="The total profit of the company divided by the total number of shares for a company."
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>Earnings/Share:</p>
                    <p>${thousandFormat(modalMktData?.earnings_per_basic_share)}</p>
                  </span>
                  <span
                    className={classes.portfolioDataDeets}
                    style={modal?.selectedIdx !== 2 ? { pointerEvents: 'none' } : {}}
                  >
                    <FinTooltip
                      arrow
                      className={classes.tooltip}
                      enterTouchDelay={200}
                      title="The metric that represents the volatility of an individual stock when compared to the stock market as a whole (volatility, when talking about stocks, refers to how dramatic a stock's price might go up or down). As a rule of thumb, the overall market is given a “beta rating” of 1. So, if an individual stock receives a beta rating of 1.5, the stock (in theory) is 50% more volatile than the market as a whole. And if a stock receives a beta rating of .5 (in theory) it is 50% less volatile than the market as a whole."
                    >
                      <img
                        alt="info-icon"
                        src="/images/porty_info_icon.png"
                      />
                    </FinTooltip>
                    <p className={classes.dataLabel}>Beta:</p>
                    <p>{thousandFormat(modalMktData?.beta)}</p>
                  </span>
                </div>
                {!mobileDevice && modal?.selectedIdx === 2 &&
                  <CustomButton
                    className={classes.modalFooterBtn}
                    onClick={closeModal}
                    rounded
                    title="CLOSE"
                  />
                }
              </Card>
            </Slide>
          )}

          {modal.open && modal?.security && Object.keys(modal?.security).length > 0 && modal?.selectedIdx >= 2 && (
            <Slide
              appear
              direction={modal?.selectedIdx <= 3 ? 'left' : 'right'}
              in
              keepmounted="true"
              timeout={300}
            >
              <Card
                className={modal?.selectedIdx === 3 ? classes.popupCard : handleSmallScreens && modal?.selectedIdx === 4 ? classes.popupCardPrevSlide : classes.popupCardNotFocused}
                onClick={() => {
                  if (modal?.selectedIdx !== 3) setModal({ ...modal, selectedIdx: 3 });
                }}
                style={modal?.selectedIdx === 2 ? { top: 140, right: 10, position: 'absolute' } : modal?.selectedIdx === 4 ? { top: 140, right: 'unset', left: 10, position: 'absolute' } : { top: 'unset', right: 'unset', left: 'unset', position: 'unset', margin: '0px auto', }}
              >
                <img
                  className={classes.modalScreenImg}
                  src={'/images/securities-popup-illustration-graph.png'}
                />
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightgray', margin: '0 0 10px', maxHeight: 40 }}>
                  {!mobileDevice && (
                    <img
                      alt="graph"
                      className={classes.modalTitleImg}
                      src="/images/full-modal-icon-graph.png"
                    />
                  )}
                  <Typography
                    className={classes.modalTitle}
                    gutterBottom
                    variant="h3"
                  >
                    Graph
                  </Typography>
                </div>
                <div style={modal?.selectedIdx !== 3 ? { height: 'fit-content', pointerEvents: 'none' } : {}}>
                  <StockPriceModal
                    className={classes.chartWrappa}
                    height={!mobileDevice && modal.open && modal?.selectedIdx !== 3 ? 250 : chartUI.height}
                    margin={{ top: 10, bottom: 0, left: 6, right: 0 }}
                    security={modal?.security}
                    width={!mobileDevice && modal.open && modal?.selectedIdx !== 3 ? 350 : chartUI.width}
                  />
                </div>
                {!mobileDevice && modal?.selectedIdx === 3 &&
                  <CustomButton
                    className={classes.modalFooterBtn}
                    onClick={closeModal}
                    rounded
                    title="CLOSE"
                  />
                }
              </Card>
            </Slide>
          )}

          {modal.open && modal?.selectedIdx >= 3 && (
            <Slide
              appear
              direction="left"
              in
              keepmounted="true"
              timeout={500}
            >
              <Card
                className={modal?.selectedIdx === 4 ? classes.popupCard : classes.popupCardNotFocused}
                onClick={() => {
                  if (modal?.selectedIdx !== 4) setModal({ ...modal, selectedIdx: 4 });
                }}
                style={modal?.selectedIdx === 3 ? { top: 140, right: 10, position: 'absolute' } : { top: 'unset', right: 'unset', left: 'unset', position: 'unset', margin: '0px auto', }}
              >
                <img
                  className={classes.modalScreenImg}
                  src={'/images/securities-popup-illustration-news.png'}
                />
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightgray', margin: '10px 0' }}>
                  {!mobileDevice && (
                    <img
                      alt="news"
                      className={classes.modalTitleImg}
                      src="/images/full-modal-icon-news.png"
                    />
                  )}
                  <Typography
                    className={classes.modalTitle}
                    gutterBottom
                    variant={'h3'}
                  >
                    {'News'}
                  </Typography>
                </div>
                {modalNews && Object.keys(modalNews).length > 0 ? (
                  <div className={classes.modalTextContent}>
                    <Typography
                      gutterBottom
                      style={{
                        textAlign: 'end', font: 'normal normal bold 14px/16px Roboto',
                        letterSpafcing: 0
                      }}
                      variant="p"
                    >
                      {`${modalNews?.title}\n`}
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 300,
                        fontSize: 14,
                        lineHeight: '22px',
                        fontFamily: 'Roboto, sans-serif',
                        letterSpacing: 0.28,
                        marginTop: '10px',
                      }}
                      variant="p"
                    >
                    By: {modalNews?.source}
                    </Typography>
                    <img
                      alt="stock-news-img"
                      src={modalNews?.imgUrl?.length ? modalNews?.imgUrl : '/images/securities-popup-illustration-news.png'}
                      style={{ imageRendering: 'auto', display: 'flex', margin: '10px 0' }}
                    />
                    <p>{modalNews?.description}</p>
                    <Button
                      className={classes.linkBtn}
                      onClick={() => window.open(modalNews?.url, '_blank')}
                      size="small"
                    >
                      {modalNews?.source}
                    </Button>
                    <p>{moment(modalNews?.publishedDate).format('MM-DD-YYYY: HH:mm')}</p>
                  </div>
                ) : (
                  <ContentProgress />
                )}
                {
                  !mobileDevice && modal?.selectedIdx === 4 && (
                    <CustomButton
                      className={classes.modalFooterBtn}
                      onClick={closeModal}
                      rounded
                      title="CLOSE"
                    />
                  )
                }
              </Card>
            </Slide>
          )}
        </div>
      }
      classes={{
        root: clsx(classes.root, classes.modalRoot),
        title: classes.header,
        wrap: classes.modBody,
      }}
      footer={
        <>
          {modal.open && (
            <>
              <div
                className={classes.modalFooter}
                style={width < 376 ? { marginTop: '-32px' } : {}}
              >
                {mobileDevice && modalMktData.description?.length > 0 && (
                  <img
                    alt="mb-usr-data"
                    className={classes.modalIcon}
                    onClick={() => {
                      setModal(
                        { open: modal?.open, security: modal?.security, selectedIdx: 0 }
                      )
                    }}
                    src={modal?.selectedIdx === 0 ? '/images/stock-button-owned-blue.png' : '/images/person-mod.png'}
                  />
                )}
                <img
                  alt="mb-ptf-info"
                  className={classes.modalIcon}
                  onClick={() => {
                    setModal(
                      { open: modal?.open, security: modal?.security, selectedIdx: 1 }
                    )
                  }}
                  src={modal?.selectedIdx === 1 ? '/images/stock-button-info-blue.png' : '/images/info-mod.png'}
                />
                <img
                  alt="mb-ptf-data"
                  className={classes.modalIcon}
                  onClick={() => {
                    setModal(
                      { open: modal?.open, security: modal?.security, selectedIdx: 2 }
                    )
                  }}
                  src={modal?.selectedIdx === 2 ? '/images/stock-button-data-blue.png' : '/images/graph-mod.png'}
                />
                <img
                  alt="mb-ptf-graph"
                  className={classes.modalIcon}
                  onClick={() => {
                    setModal(
                      { open: modal?.open, security: modal?.security, selectedIdx: 3 }
                    )
                  }}
                  src={modal?.selectedIdx === 3 ? '/images/stock-button-graph-blue.png' : '/images/chart-mod.png'}
                />
                <img
                  alt="mb-ptf-news"
                  className={classes.modalIcon}
                  onClick={() => {
                    setModal(
                      { open: modal?.open, security: modal?.security, selectedIdx: 4 }
                    )
                  }}
                  src={modal?.selectedIdx === 4 ? '/images/stock-button-news-blue.png' : '/images/news-mod.png'}
                />
              </div>
              {mobileDevice &&
                <div
                  className={classes.modalBtnWrapper}
                  style={width < 376 ? { left: 25, margin: 'unset', bottom: 0 } : {}}
                >
                  <img
                    onClick={() => navigate(`/place-order/BUY/${contestUser.contest}/${modal?.security?.id}`)}
                    src="/images/securities-button-buy.png"
                    style={width < 376 ? { height: 47.5 } : {}}
                  />
                  <img
                    onClick={() => navigate(`/place-order/SELL/${contestUser.contest}/${modal?.security?.id}`)}
                    src="/images/securities-button-sell.png"
                    style={width < 376 ? { height: 47.5 } : {}}
                  />
                </div>
              }
            </>
          )}
        </>
      }
      hasTransition={modal?.open}
      id="stock-info"
      onClose={closeModal}
      open={modal?.open}
    />
  );
};

ModalSlides.propTypes = {
  contestUser: PropTypes.objectOf(PropTypes.any),
  modalMktData: PropTypes.objectOf(PropTypes.any),
  modalNews: PropTypes.objectOf(PropTypes.any),
  chartUI: PropTypes.objectOf(PropTypes.any),
};

export default ModalSlides;
