/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import ErrorLayout from './layouts/Error';
import MainLayout from './layouts/MainLayout';
// components
const CreateContest = lazy(() => import('views/CreateContest'));
const CreateClassRoom = lazy(() => import('views/CreateClassRoom'));
const Lessons = lazy(() => import('views/Lessons'));
const Assessment = lazy(() => import('views/Assessment'));
const JoinClassRoom = lazy(() => import('views/JoinClassRoom'));
const JoinContest = lazy(() => import('views/JoinContest'));
const JoinFinTronChallenge = lazy(() => import('views/JoinFinTronChallenge'));
const MyClassrooms = lazy(() => import('views/MyClassrooms'));
const MyContests = lazy(() => import('views/MyContests'));
const Classroom = lazy(() => import('views/Classroom'));
const Contest = lazy(() => import('views/Contest'));
const ContestOrderHistory = lazy(() => import('views/ContestOrderHistory'));
const PlaceOrder = lazy(() => import('views/PlaceOrder'));
const ContestPortfolio = lazy(() => import('views/ContestPortfolio'));
const PortfolioStats = lazy(() => import('views/PortfolioStats'));
const Logout = lazy(() => import('views/Logout'));
const Development = lazy(() => import('views/Development'));
const FakeView = lazy(() => import('./views/FakeView'));
const EduHome = lazy(() => import('views/EduHome'));
const LearnAndEarn = lazy(() => import('views/LearnAndEarn'));
const Login = lazy(() => import('views/Login'));
const Signup = lazy(() => import('views/Signup'));
const SignupTeacher = lazy(() => import('views/SignupTeacher'));
const ResetPassword = lazy(() => import('views/ResetPassword'));
const Support = lazy(() => import('views/Support'));
const BlogDetail = lazy(() => import('views/BlogDetail'));
const ContactUs = lazy(() => import('views/ContactUs'));
const EmailVerification = lazy(() => import('views/EmailVerification'));
const Error404 = lazy(() => import('./views/Error404'));
const RedeemPoints = lazy(() => import('./views/RedeemPoints'))
const PreviewCourses = lazy(() => import('./views/PreviewCourses'))
const MasterBudget = lazy(() => import('./views/MasterBudget'))
export default function AppRouter() {
  const element = useRoutes([
    {
      path: '/',
      element: <Navigate to="/public/home" />
    },
    {
      path: '*',
      element: <Navigate to="/public/home" />
    },
    {
      route: '*',
      element: <MainLayout auth />,
      children: [
        {
          path: 'create',
          element: <CreateContest />

        },
        {
          path: 'create-classroom',
          element: <CreateClassRoom />
        },
        {
          path: 'lessons',
          element: <Lessons />

        },
        {
          path: 'assessment',
          element: <Assessment />

        },
        {
          path: 'join',
          element: <JoinContest />

        },
        {
          path: 'join-classroom',
          element: <JoinClassRoom />
        },
        {
          path: 'join-fintron-challenge',
          element: <JoinFinTronChallenge />

        },
        {
          path: 'my-contests',
          element: <MyContests />

        },
        {
          path: 'my-classrooms',
          element: <MyClassrooms />
        },
        {
          path: 'contest/:id',
          element: <Contest />
        },
        {
          path: 'classroom/:id',
          element: <Classroom />
        },
        {
          path: 'contest-order-history/:userId/:contestId',
          element: <ContestOrderHistory />

        },
        {
          path: 'place-order/:orderType/:contestId/:securityId',
          element: <PlaceOrder />

        },
        {
          path: 'contest-portfolio/:id',
          element: <ContestPortfolio />

        },
        {
          path: 'portfolio-stats/:userId/:contestId',
          element: <PortfolioStats />

        },
        {
          path: 'logout',
          element: <Logout />

        },
        {
          path: 'learn-and-earn',
          element: <LearnAndEarn />
        },
        {
          path: 'redeem',
          element: <RedeemPoints />
        },
        {
          path: 'budgeting',
          element: <MasterBudget />
        },
      ]
    },
    {
      path: '/public', element: <MainLayout auth={false} />,
      children: [
        {
          path: 'development',
          element: <Development />

        },
        {
          path: 'fake',
          element: <FakeView />

        },
        {
          path: 'home',
          element: <EduHome />

        },
        {
          path: 'login',
          element: <Login />

        },
        {
          path: 'signup',
          element: <Signup />

        },
        {
          path: 'signup-teacher',
          element: <SignupTeacher />

        },
        {
          path: 'reset-password',
          element: <ResetPassword />

        },
        {
          path: 'support',
          element: <Support />

        },
        {
          path: 'blogs/:alias',
          element: <BlogDetail />

        },
        {
          path: 'lessons/:alias',
          element: <BlogDetail />

        },
        {
          path: 'contact-us',
          element: <ContactUs />

        },
        {
          path: 'email-verification',
          element: <EmailVerification />
        },
        {
          path: 'preview-courses',
          element: <PreviewCourses />
        },
      ]
    },
    {
      path: '/errors', element: <ErrorLayout />,
      children: [
        { path: 'error-404', element: <Error404 /> },
      ]
    },
  ])
  return element
}
