import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Dialog, Typography, IconButton, colors, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { COLORS } from 'config/constants';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 0,
      borderRadius: 26,
      overflowX: 'hidden',
      [theme.breakpoints.down('sm')]: {
        overflowX: 'hidden',
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
    }, 
  },
  paper: {
    borderRadius: 26,
    maxWidth: '100%',
    width: 'fit-content',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  longWrap: {
    width: '100vw',
    maxWidth: 'unset',
    overflowX: 'hidden !important',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  wrapper: {
    backgroundColor: COLORS.white,
    maxWidth: 462,
    width: '100%',
    minWidth: 462,
    padding: '25px 30px',
    borderRadius: 26,
    [theme.breakpoints.down('md')]: {
      minWidth: 400,
      // maxWidth: 560,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      maxWidth: 360,
      padding: theme.spacing(3),
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  underlinedHeader: {
    paddingBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.grey[300],
  },
  title: {
    fontWeight: 'bold',
    color: COLORS.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  body: {
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-line',
    fontFamily: 'Roboto, sans-serif',
  },
  bodyTxt: {
    color: COLORS.black,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '22px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 0.28,
  },
  footer: {
    marginTop: theme.spacing(4),
  },
  icon: {
    marginRight: 10,
    imageRendering: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

const Modal = props => {
  const classes = useStyles();
  const {
    open, title, bordered, hasTransition, body, footer, icon, iconClose,
    classes: { root: rootCls, wrap: wrapCls, title: titleCls, body: bodyCls, footer: footerCls },
    ...rest
  } = props;

  return (
    <Dialog
      PaperProps={{className: classes.paper}}
      className={clsx(classes.root, rootCls, props.className)}
      onClose={props.onClose}
      open={open}
      {...rest}
    >
      <div className={hasTransition ? clsx(classes.longWrap, wrapCls) : clsx(classes.wrapper, wrapCls)}>
        {(!!title || !!icon) && (
          <div className={clsx(classes.header, bordered && classes.underlinedHeader, titleCls, 'debug--container')}>
            {icon && (
              <img
                alt={'icon'}
                className={classes.icon}
                src={icon}
              />
            )}
            <Typography
              className={classes.title}
              variant="h5"
            >
              {title}
            </Typography>
            {iconClose && (
              <IconButton
                aria-label="close"
                className={classes.closeIcon}
                onClick={props.onClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}

        <div className={clsx(classes.body, bodyCls)}>
          {typeof body === 'string' ?
            (
              <Typography
                className={classes.bodyTxt}
                variant="body1"
              >
                {body}
              </Typography>
            ) :
            body
          }
        </div>

        {footer && (
          <div className={clsx(classes.footer, footerCls)}>
            {footer}
          </div>
        )}
      </div>
    </Dialog>
  );
};

Modal.defaultProps = {
  open: false,
  title: '',
  classes: {
    titleCls: 'text-left',
  },
};

Modal.propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  bordered: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.any,
    footer: PropTypes.any,
    title: PropTypes.any,
    body: PropTypes.any,
    wrap: PropTypes.any,
  }),
  footer: PropTypes.node,
  hasTransition: PropTypes.bool,
  icon: PropTypes.string,
  iconClose: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node,
};

export default Modal;
