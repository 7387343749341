import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@vx/shape';
import { Group } from '@vx/group';
import { RadialGradient, LinearGradient } from '@vx/gradient';
import { scaleOrdinal } from '@vx/scale';
import { Typography } from '@material-ui/core';
import { LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend';
import { makeStyles } from '@material-ui/styles';

import { useDimensions } from 'utils';
import { PieChartFallback, PieArc  } from './components';

const useStyles = makeStyles((theme) => ({
  pieContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  labelItm: {
    backgroundColor: 'transparent',
    lineHeight: '29px',
    fontSize: '22px',
    borderRadius: '5px',
    padding: '0 4px',
    margin: '2px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      transition: 'all 300ms ease-in'
    },
    [theme.breakpoints.down('md')]: {
      lineHeight: '21px',
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '17px',
      fontSize: '14px',
    },
  },
  sourcesLbl: {
    fontWeight: 700,
    lineHeight: '29px',
    letterSpacing: 0.56,
    fontSize: 28,
    color: colors.black,
    [theme.breakpoints.down('md')]: {
      lineHeight: '27px',
      fontSize: '22px',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '21px',
      fontSize: '18px',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  pieChartTitle: {
    fontWeight: 700,
    lineHeight: '29px',
    textAlign: 'center',
    margin: '24px 0',
    letterSpacing: 0.56,
    fontSize: 28,
    color: colors.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 21,
      letterSpacing: 0.36,
      lineHeight: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      letterSpacing: 0.28,
      lineHeight: '18px',
    },
  },
}))
const value = d => d.value;

const colors = [
  '#3E4B9E',
  '#26A8DF',
  '#96DBF8',
  'rgb(50,121,191)',
  '#FAB963',
];

function PieChart({ data, label }) {
  const classes = useStyles();
  const [ref, { width, height }] = useDimensions();
  const hasData = data.some((itm) => itm.value > 0);

  const defaultMargin = { top: 20, right: 10, bottom: 15, left: 20 };
  let innerWidth = width - defaultMargin.left
  let innerHeight = height - defaultMargin.top;
  const yxMin = Math.min(height === 0 ? width : height, width);
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;

  const radius = Math.min(innerWidth, innerHeight) / 2;
  const sortedArr = (arr) => arr.sort((a,b) => b.value - a.value);
  const newData = sortedArr(data.filter((itm) => itm.value > 0));
  const scale = scaleOrdinal({
    domain: newData.map(pieItm => pieItm.key),
    range: colors
  });

  const [hoveredIdx, setHoveredIdx] = useState(null);

  return (
    <div
      className={classes.pieContainer}
      height={yxMin}
    >
      <Typography
        className={classes.pieChartTitle}
        variant={'h5'}
      >
        {label}
      </Typography>
      <div>
        <div
          className={classes.pieContainer}
        >
          <svg
            height={height}
            preserveAspectRatio="xMidYMid meet"
            ref={ref}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            width={width}
          >
            {!hasData ? (
              <PieChartFallback />
            ) : (
              <>
                <RadialGradient
                  from="#3E4B9E"
                  fromOffset={'30%'}
                  id="gradient-0"
                  r="50%"
                  to="#3279BF"
                />
                <LinearGradient
                  from="#3278BE"
                  id="gradient-1"
                  to="#26A7DF"
                />
                <LinearGradient
                  from="#92D3EF"
                  id="gradient-2"
                  to="#26A7DF"
                />
                <LinearGradient
                  from="#3E4B9E"
                  id="gradient-3"
                  to="#26A7DF"
                />
              </>
            )}
            <Group
              left={centerX + defaultMargin.left - defaultMargin.right}
              top={centerY + defaultMargin.top - defaultMargin.bottom}
            >
              <Pie
                data={newData}
                endAngle={Math.PI * 2}
                innerRadius={(25 / 100) * radius}
                outerRadius={radius}
                padAngle={newData.length > 1 ? 0.035 : 0}
                pieValue={value}
                startAngle={newData.length > 1 ? -0.03 : 0}
              >
                {pie => {
                  return pie.arcs.map((arc, idx) => {
                    return <PieArc
                      arc={arc}
                      fill={`url(#gradient-${idx})`}
                      idx={idx}
                      onMouseEnter={() => setHoveredIdx(idx)}
                      onMouseLeave={() => setHoveredIdx(null)}
                      pie={pie}
                      style={hoveredIdx === idx ? {transition: 'all 300ms ease-in'} : {transition: 'all 300ms ease-out',}}
                      transform={hoveredIdx === idx ? 'scale(1.05)' : 'scale(0.95)'}
                    />
                  });
                }}
              </Pie>
            </Group>
          </svg>
          <LegendOrdinal
            direction="column"
            fill={({ datum }) => scale(datum)}
            scale={scale}
            shape="rect"
          >
            {labels => {
              return (
                <div className={classes.flexColumn}>
                  <LegendItem
                    margin={'20px 5px 10px'}
                    width="100%"
                  >
                    <LegendLabel
                      align={'center'}
                      margin={'0 0 0 4px'}
                    >
                      <h5 className={classes.sourcesLbl}>
                    Sources
                      </h5>
                    </LegendLabel>
                  </LegendItem>
                  {hasData && labels.map((label, i) => {
                    return (
                      <LegendItem
                        key={`legend-quantile-${i}`}
                        margin={'0 5px'}
                        width="100%"
                      >
                        <LegendLabel
                          align={'left'}
                          margin={'0 0 0 4px'}
                        >
                          <div
                            className={classes.labelItm}
                            onMouseEnter={() => setHoveredIdx(i)}
                            onMouseLeave={() => setHoveredIdx(null)}

                            style={hoveredIdx === i ?
                              {
                                color: label.value,
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                transition: 'all 300ms ease-in'
                              }
                              :
                              { color: label.value  }}
                          >
                            {label.text}
                          </div>
                        </LegendLabel>
                      </LegendItem>
                    );
                  })}
                </div>
              );
            }}
          </LegendOrdinal>
        </div>
      </div>
    </div>
  );
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  label: PropTypes.string,
};

export default PieChart;

