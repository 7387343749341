import React, { ReactElement, MouseEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { COLORS } from 'config/constants';
import { Typography } from '@material-ui/core';

export type ZogoModule = {
  imgSrc: string,
  name: string,
  id: number,
  onClick: () => MouseEvent<HTMLDivElement>
}

interface ZogoLinkedModulesProps {
  data: Array<ZogoModule>,
  completed: Array<number>,
}

function ZogoLinkedModules({ data, completed }: ZogoLinkedModulesProps): ReactElement {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <div className={classes.linkedModulesWrapper}>
      {data.map((itm: ZogoModule, idx: number) => (
        <div
          className={classes.zogoModuleLink}
          key={`${itm.name}-${idx}`}
          onClick={() => navigate(`/learn-and-earn?widget_type=deep_link&module_id=${itm.id}`,{state: {url: location.pathname}})}
        >
          <div
            className={classes.circle}
            style={completed.includes(itm.id) ? {borderColor: '#466DB1'} : {opacity: 0.7, borderColor: '#f1f1f1'}}
          >
            <img
              alt="zogo-module"
              className={classes.modImg}
              src={itm.imgSrc}
            />
            <Typography>{itm.name}</Typography>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ZogoLinkedModules;

const useStyles = makeStyles((theme: Theme) => ({
  linkedModulesWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: 460,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: 'fit-content',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: -25,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      justifyContent: 'unset',
      marginLeft: 'unset',
      marginBottom: 50,
    }
  },
  modImg: {
    imageRendering: 'auto',
    width: 50,
    height: 50,
    alignSelf: 'center',
    margin: '20px',
    order: 'unset !important' as any,
  },
  circle: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 5,
    '&:hover': {
      cursor: 'pointer'
    },
    '& > p': {
      textAlign: 'center',
      marginTop: 5, 
      color: COLORS.white,
    }
  },
  zogoModuleLink: {
    margin: '35px 25px',
    order: 'unset !important' as any,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '75px 25px',
    }
  },
}));