import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { TextFieldWrapper } from './Wrapper';

export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_URL = 'url';
export const TYPE_TELEPHONE = 'tel';
export const TYPE_DATE = 'date';
export const TYPE_DATETIME_LOCAL = 'datetime-local';
export const TYPE_MONTH = 'month';
export const TYPE_TIME = 'time';
export const TYPE_WEEK = 'week';

export default function TextField(props) {
  const { name, fieldProps, ...rest} = props;

  return (
    <Field
      name={name}
      render={({ input, meta}) => (
        <TextFieldWrapper
          input={input}
          meta={meta}
          {...rest}
        />
      )}
      {...fieldProps}
    />
  )
}

TextField.propTypes = {
  name: PropTypes.string,
  fieldProps: PropTypes.any
};
