import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line, Bar, LinePath, AreaClosed } from '@vx/shape';
import { curveMonotoneX } from '@vx/curve';
import { LinearGradient } from '@vx/gradient';
import { GridRows, GridColumns } from '@vx/grid';
import { Group } from '@vx/group';
import { scaleLinear, scaleBand } from '@vx/scale';
import { withTooltip, Tooltip } from '@vx/tooltip';
import { timeFormat } from 'd3-time-format';
import { useTheme } from '@material-ui/core/styles';
import { thousandFormat } from 'utils/functions';
import { DESIGN_UTILS } from 'utils';
import { COLORS } from 'config/constants';
import { useMediaQuery } from '@material-ui/core';

// util
const formatDate = timeFormat('%m/%d/%Y %H:%M');
const min = (arr, fn) => Math.min(...arr.map(fn));
const max = (arr, fn) => Math.max(...arr.map(fn));

// accessors
const xSelector = d => new Date(d.date);
const ySelector = d => d.price;
const tiSelector = (d, tiKey) => d[tiKey];
const xLocaleSelector = d => {
  const d0 = new Date(d.date);
  return new Date(d0);
};

const chartColors = {
  'SMA': '#c48fa1',
  'Real Upper Band': '#d2caff',
  'Real Lower Band': '#a9abe6',
  'Real Middle Band': '#c48fa1',
};

const StockPriceAreaChart = (props) => {
  const {
    width,
    height,
    margin,
    hideTooltip,
    tooltipData,
    tooltipTop,
    tooltipLeft,
    primaryData,
    showTooltip,
    indicator,
    secondaryData
  } = props;

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [tiKeys, setTiKeys] = useState([]);

  useEffect(() => {
    if (secondaryData?.length === 0 || !indicator) return null;

    switch (indicator) {
      case 'SMA':
        setTiKeys(['SMA']);
        break;
      case 'BBANDS':
        setTiKeys(['Real Upper Band', 'Real Lower Band', 'Real Middle Band']);
        break;
      default:
        setTiKeys([]);
        break;
    }
  }, [secondaryData, indicator])

  if (width < 10) return null;

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  // scales
  const xScale = scaleBand({
    range: [0, xMax],
    domain: primaryData.map(xSelector),
  });
  const yScale =
    scaleLinear({
      range: [yMax, 0],
      domain: [
        min(primaryData, ySelector) - 1,
        max(primaryData, ySelector) + 1,
      ],
      nice: true,
    })

  const viewBoxDims = () => {
    if (width === 476) return '0 0 476 275'
    else if (width === 385) return '0 0 385 400'
    else if (width === 324) return '0 0 324 243'
    else if (width === 350) return '0 0 350 250'
    else return '0 0 365 220'
  };
  const handleTooltip = (event, xScale, yScale, primaryData) => {
    if (primaryData) {
      showTooltip({
        tooltipData: primaryData,
        tooltipLeft: xScale(xSelector(primaryData)),
        tooltipTop: yScale(ySelector(primaryData)),
      });
    }
  };

  // calculation to handle positioning tooltip when viewbox size changes (md & sm screens)
  const adjustedTooltipPosition = largeScreen ? tooltipLeft : tooltipLeft * 0.77
  return (
    <div
      style={{
        marginTop: 15,
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        paddingBottom: '60%',
        verticalAlign: 'top',
        overflow: 'visible',
        borderRadius: 26,
        boxShadow: '0px 2px 4px #00000040',
        alignItems: 'center'
      }}
    >
      <svg
        preserveAspectRatio="xMidYMid meet"
        style={{
          display: 'inline-block',
          position: 'absolute',
          top: 0,
          left: 0,
          maxWidth: '100%',
          maxHeight: '100%',
        }}
        viewBox={viewBoxDims()}
      >
        <rect
          fill="transparent"
          rx={14}
          x={0}
          y={0}
        />
        <LinearGradient
          from="#4B87C4"
          id="gradient"
          to="#e4f2ff"
          toOpacity={0.05}
        />
        <GridRows
          lineStyle={{ pointerEvents: 'none' }}
          scale={yScale}
          stroke={COLORS.transparent}
          strokeDasharray="2,2"
          width={xMax}
        />
        <GridColumns
          height={yMax}
          lineStyle={{ pointerEvents: 'none' }}
          scale={xScale}
          stroke={COLORS.transparent}
          strokeDasharray="2,2"
        />
        <LinePath
          data={primaryData}
          stroke="#404C99"
          strokeWidth={1}
          x={d => xScale(xSelector(d))}
          y={d => yScale(ySelector(d))}
        />
        <AreaClosed
          data={primaryData}
          fill="url(#gradient)"
          stroke="url(#gradient)"
          strokeWidth={1}
          x={d => xScale(xSelector(d))}
          y={d => yScale(ySelector(d))}
          yScale={yScale}
        />
        {tiKeys && tiKeys.length > 0 && tiKeys.map(tiKey => (
          <LinePath
            curve={curveMonotoneX}
            data={secondaryData}
            key={tiKey}
            stroke={chartColors[tiKey]}
            strokeWidth={2}
            x={d => xScale(xSelector(d))}
            y={d => yScale(tiSelector(d, tiKey))}
          />
        )
        )}

        <Group>
          {primaryData.map((d, idx) => {
            const letter = xSelector(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax;
            const barX = xScale(letter);
            const barY = yMax - barHeight;
            return (
              <Bar
                fill="transparent"
                height={barHeight}
                key={`bar-${d.date}-${idx}`}
                onMouseLeave={() => hideTooltip()}
                onMouseMove={event =>
                  handleTooltip(
                    event,
                    xScale,
                    yScale,
                    d
                  )}
                onTouchMove={event =>
                  handleTooltip(
                    event,
                    xScale,
                    yScale,
                    d
                  )}
                onTouchStart={event =>
                  handleTooltip(
                    event,
                    xScale,
                    yScale,
                    d
                  )}
                width={barWidth}
                x={barX}
                y={barY}
              />
            );
          })}
        </Group>
        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              stroke="rgba(92, 119, 235, 1.000)"
              strokeDasharray="2,2"
              strokeWidth={2}
              style={{ pointerEvents: 'none' }}
              to={{ x: tooltipLeft, y: yMax }}
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop + 1}
              fill="black"
              fillOpacity={0.1}
              r={4}
              stroke="black"
              strokeOpacity={0.1}
              strokeWidth={2}
              style={{ pointerEvents: 'none' }}
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              fill="rgba(92, 119, 235, 1.000)"
              r={4}
              stroke="white"
              strokeWidth={2}
              style={{ pointerEvents: 'none' }}
            />
          </g>
        )}
      </svg>

      {tooltipData && (
        <div style={{alignSelf: 'center'}}>
          <Tooltip
            left={DESIGN_UTILS.getTooltipPosition(adjustedTooltipPosition, xMax)}
            style={{
              backgroundColor: 'rgba(92, 119, 235, 1.000)',
              color: 'white',
              width: 'fit-content',
              transform: 'translateX(-50%)',
            }}
            top={tooltipTop - 55}
          >
            {`$${thousandFormat(ySelector(tooltipData))}`}
          </Tooltip>
          <Tooltip
            left={DESIGN_UTILS.getTooltipPosition(adjustedTooltipPosition, xMax)}
            style={{
              transform: 'translateX(-50%)',
              zIndex: 999
            }}
            top={yMax - 14}
          >
            {formatDate(xLocaleSelector(tooltipData))}
          </Tooltip>
        </div>
      )}
    </div>
  );
}
StockPriceAreaChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number
  }),
  primaryData: PropTypes.arrayOf(PropTypes.any),
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  tooltipData: PropTypes.any,
  tooltipTop: PropTypes.number,
  tooltipLeft: PropTypes.number,
  indicator: PropTypes.string,
  secondaryData: PropTypes.arrayOf(PropTypes.any)

}
export default withTooltip(StockPriceAreaChart);
