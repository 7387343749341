import http from './http';

const ClassRoom = {
  createOne: (data) => http.post('/api/classrooms/classrooms/', { data }),
  getOne: (id) => http.get(`/api/classrooms/classrooms/${id}/`),
  list: (params = {}) => http.get('/api/classrooms/classrooms/', { params }),
  join: (data) => http.post('/api/classrooms/classrooms/join/', { data }),
  invite: (id, emails) => http.post(`/api/classrooms/classrooms/${id}/invite/`, { data: { emails } }),
  classroomCourses: (id) => http.get(`/api/classrooms/classrooms/${id}/courses/`),
  courses: () => http.get('/api/learn/courses/'),
  courseLessons: (id) => http.get(`/api/learn/lessons/?course=${id}`),
  getPreviewCourses: () => http.get('api/learn/courses/preview/?page_size=50'),
  getLesson: (id) => http.get(`/api/learn/lessons/${id}/`),
  getCompletedModules: (lessonId) => http.get(`/api/learn/lessons/${lessonId}/completed-modules/`),
  remindLesson: (classroomId, lessonId) => http.post(`/api/classrooms/classrooms/${classroomId}/lessons/${lessonId}/remind/`),
  enableLessons:  (classroomId, data) => http.post(`/api/classrooms/classrooms/${classroomId}/lessons/`, { data }),
  updateClassroomLesson: (classroomId, lessonId, data) => http.patch(`/api/classrooms/classrooms/${classroomId}/lessons/${lessonId}/`, { data }),
  getClassroomStudents: (id) => http.get(`/api/classrooms/classrooms/${id}/students/`),
  getCourseStatics: (classroomId, course, category) => http.post(`/api/classrooms/classrooms/${classroomId}/statistics/`, { data: { course, category } }),
  getOverDueDates: (classroomId, course) => http.post(`/api/classrooms/classrooms/${classroomId}/overdue-lessons/`, { data: { course } }),
  getCoursePosts: (classroomId, params) => http.get(`/api/classrooms/classrooms/${classroomId}/posts/`, { params }),
  removeStudent: (classroomId, userId) => http.delete(`/api/classrooms/classrooms/${classroomId}/users/${userId}/`),
  removeClassroom: (classroomId) => http.delete(`/api/classrooms/classrooms/${classroomId}/`),
  freezeClassroom: (classroomId) => http.patch(`/api/classrooms/classrooms/${classroomId}/freeze`),
  grantCertificate: (classroomId, data) => http.post(`/api/classrooms/classrooms/${classroomId}/certificates/grant/`, { data }),
  getClassroomCertificates: (classroomId, data) => http.post(`/api/classrooms/classrooms/${classroomId}/certificates/get/`, { data }),
  getUserCertificate: (classroomId) => http.get(`api/sertifier/recipients/?classroom=${classroomId}&page_size=100`),
};

export default ClassRoom;
