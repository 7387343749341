/* eslint-disable no-undef */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Footer } from '../../components';
import { useLocation } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  footer: {

  }
}));

const Page = props => {
  const { title, children, ...rest } = props;

  const location = useLocation();
  const classes = useStyles();
  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
      {!location.pathname?.includes('/learn-and-earn') && (
        <div className={classes.footer}>
          <Footer />
        </div>
      )}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
