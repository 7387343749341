import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const grey = '#a9a4a4';
const black = '#000000';
const blue = '#26b1e6';
const darkBlue = '#0c233f';
const middleDarkBule = '#09223D';
const lightBlue = '#26b1e6';
const darkGray = colors.blueGrey[900];
const linkBlue = 'rgb(28, 126, 171)';
const grayishLightBlue = '#e6e9eb';
const red = '#F45531';
const darkRed = '#B01700';
const green = '#21CE99';
const pink = '#f70bff';
const bluish = '#26a9e0';
const darkGreen = '#45BA65';
const midBlue = '#26A8DF';
const eduBlue = '#26a7df';
const appStoreBlue = '#0070c9';
const resumeColor = '#0B243F';

export default {
  black,
  white,
  grey,
  blue,
  darkBlue,
  middleDarkBule,
  lightBlue,
  darkGray,
  linkBlue,
  grayishLightBlue,
  transparent: 'transparent',
  green,
  red,
  darkRed,
  pink,
  bluish,
  darkGreen,
  midBlue,
  eduBlue,
  appStoreBlue,
  resumeColor,
};
