import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import uuid from 'uuid/v1';

import { useNavigate } from 'react-router-dom';
import palette from 'theme/palette';
import { CardView, GridContainer, GridItem } from '../index';

const useStyles = makeStyles(theme => ({
  descWrap: {
    padding: theme.spacing(1)
  },
  description: {
    color: palette.primary,
  },
  blogsWrap: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0)
    }
  },
}));

const FBlogSection = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { description, blogs } = props.data;

  return (
    <div className={clsx(classes.root, props.className)}>
      <GridContainer>
        <GridItem
          lg={2}
        />
        <GridItem
          lg={9}
          xs={12}
        >
          <div className={classes.descWrap}>
            <Typography
              className={classes.description}
              variant="body2"
            >
              {description}
            </Typography>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer
        className={classes.blogsWrap}
        justifyContent="center"
      >
        <GridItem
          container
          lg={10}
          xs={12}
        >
          {blogs.map(item => (
            <GridItem
              key={uuid()}
              lg={4}
              md={4}
              xs={12}
            >
              <CardView
                buttonText={item.buttonText.toLowerCase() !== 'read the blog' ? item.buttonText : ''}
                cardStyle={props.cardStyle}
                description={item.description}
                image={item.image}
                key={uuid()}
                onClick={() => navigate(item.path)}
                onSecondClick={() => window.open(item.secondPath, '_blank')}
                secondButtonText={item.secondButtonText}
                title={item.title}
              />
            </GridItem>
          ))}
        </GridItem>
      </GridContainer>
    </div>
  );
};

FBlogSection.defaultProps = {
  className: null
};

FBlogSection.propTypes = {
  cardStyle: PropTypes.any,
  className: PropTypes.string,
  data: PropTypes.shape({
    description: PropTypes.string,
    blogs: PropTypes.array
  })
};

export default FBlogSection;
