import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import clsx from 'clsx';
import { Paper, TableContainer } from '@material-ui/core';
import { darkTheme } from 'theme';

const useStyles = makeStyles(() => ({}));

const TableDarkContainer = ({ className, ...rest}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
      <TableContainer
        className={clsx(classes.root, className)}
        component={Paper}
        {...rest}
      />
    </ThemeProvider>
  );
};

TableDarkContainer.defaultProps = {
  className: null
};

TableDarkContainer.propTypes = {
  className: PropTypes.string
};

export default TableDarkContainer;
