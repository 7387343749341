import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';
import { useWindowDimensions, useIsomorphicLayoutEffect } from 'utils';

const useStyles = makeStyles(styles);

const DURATIONS = {
  Today: '1D',
  Week: '1W',
  Month: '1M',
  Year: '1Y',
  'ALL': '5Y',
};
const MOBILEDURATIONS = {
  D: '1D',
  W: '1W',
  M: '1M',
  Y: '1Y',
  'ALL': '5Y',
}
const DurationChooser = (props) => {
  const classes = useStyles();
  const { selected } = props;
  const { nonActive, active } = props.classes;
  const { width } = useWindowDimensions();
  const [durations, setDurations] = useState({})

  useIsomorphicLayoutEffect(() => {
    if (width < 475)
      setDurations(MOBILEDURATIONS);
    else
      setDurations(DURATIONS);
  }, [width])

  function handleSelect(key) {
    if (durations[key] !== props.selected) {
      props.onSelect(durations[key]);
    }
  }

  return (
    <div className={clsx('debug--container', classes.root, props.className)}>
      {durations && Object.keys(durations).map(item => {
        const isSelected = selected === durations[item];
        return (
          <Button
            className={clsx(
              nonActive ? nonActive : classes.btn,
              isSelected && (active ? active : classes.selected),
            )}
            disableElevation
            key={item}
            onClick={() => handleSelect(item)}
            size="small"
            variant={isSelected ? 'contained' : 'text'}
          >
            {item}
          </Button>
        )
      })}
    </div>
  );
};

DurationChooser.defaultProps = {
  className: null,
  selected: 1,
  classes: {
    active: null,
    nonActive: null
  },
};

DurationChooser.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    active: PropTypes.string,
    nonActive: PropTypes.string
  }),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string
};

export default DurationChooser;
