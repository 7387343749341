import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 0),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 0)
    },
  },
  onlyTop: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    },
  }
}));

const FBlogWrap = ({ children, onlyTop }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, onlyTop && classes.onlyTop)}>
      {children}
    </div>
  );
};

FBlogWrap.defaultProps = {
  children: null,
};

FBlogWrap.propTypes = {
  children: PropTypes.node,
  onlyTop: PropTypes.bool,
};

export default FBlogWrap;
