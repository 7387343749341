
export default {
  gridSpacing: {
    xl: 6,
    lg: 4,
    md: 2,
    sm: 2,
    xs: 2
  },
  pageSpacing: {
    xl: 11,
    lg: 11,
    md: 8,
    sm: 4,
    xs: 3
  },
  themeSpacing: 8,
  slidesToShow: {
    xl: 8,
    lg: 6,
    md: 6,
    sm: 5,
    xs: 4
  }
}
