import React, { ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.7)',
    },
    '70%': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)'
    },
  },
  pointsBox: {
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
  },
  zogoBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    marginLeft: 40,
    [theme.breakpoints.down('sm')]: {
      marginRight: 20
    },
    '& img': {
      imageRendering: 'auto',
    },
  },
  zogoBtnSm: {
    height: 22,
    width: '100%',
    maxWidth: 'fit-content',
    minWidth: 62,
    cursor: 'pointer',
    '&:hover': {
      '& :first-child': {
        cursor: 'pointer',
        transform: 'rotate(360deg)',
        transition: '0.3s ease-in-out'
      }
    },
    '& img': {
      width: 'unset !important',
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight:'bold',
      fontSize: 12,
      lineHeight: '15px',
      fontFamily: 'Roboto, sans-serif',
      width: '100%',
      textAlign: 'center',
    }
  },
  zogoBtnLg: {
    height: 60,
    width: '100%',
    maxWidth: 'fit-content',
    minWidth: 168,
    marginLeft: '30%',
    '& img': {
      maxWidth: '40px',
      objectFit: 'scale-down'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight:'bold',
      fontSize: 31,
      lineHeight: '39px',
      fontFamily: 'Roboto, sans-serif',
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '5%',
      minWidth: 140,
      '& p': {
        fontSize: 24
      }
    }
  },
}));
interface ZogoPointsButtonProps {
  size: string,
  imgSrc: string,
  points: number,
  shouldAnimate: boolean
}
function ZogoPointsButton({ size, imgSrc, points = 0, shouldAnimate = false }: ZogoPointsButtonProps): ReactElement {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const nf = new Intl.NumberFormat('en-US');
  return (
    <div
      className={clsx(shouldAnimate && size !== 'lg' && classes.pointsBox, classes.zogoBtn, size === 'lg' ? classes.zogoBtnLg : classes.zogoBtnSm)}
      data-testid="pts-btn"
      onClick={() => {
        if (location.pathname !== '/redeem') navigate('/redeem')
      }}
    >
      <img
        alt="fire"
        src={imgSrc ? imgSrc : '/images/fire.png'}
      />
      <p>{nf.format(points)}</p>
    </div>
  )
}
export default ZogoPointsButton;
