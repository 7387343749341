import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const CheckIcon = props => {
  const classes = useStyles();
  const { className } = props;

  return (
    <span className={clsx(classes.root, className)} />
  );
};

CheckIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  borderWidth: PropTypes.number,
};

export default CheckIcon;

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    width: 20,
    height: 31,
    transform: 'matrix(0.71, 0.71, -0.71, 0.71, 0, 0)',
    borderBottom: '7px solid #26A7DF',
    borderRight: '7px solid #26A7DF',
    opacity: 1
  }
}))
