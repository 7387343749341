import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import clsx from 'clsx';
import { FONTS } from 'config/constants';

const useStyles = makeStyles(theme => ({
  avatar: {
    border: `2px solid ${theme.palette.primary}`,
    height: 110,
    width: 110,
    backgroundColor: teal[50],
    [theme.breakpoints.down('sm')]: {
      height: 80,
      width: 80
    }
  },
  textAvatar: {
    color: theme.palette.getContrastText(teal[50]),
    fontSize: 40,
    fontFamily: FONTS.Oswald,
    textTransform: 'uppercase'
  }
}));

const ProfileAvatar = ({ className, data, ...rest}) => {
  const classes = useStyles();

  function generateSymbol() {
    return data.first_name && `${data.first_name.charAt(0)}${data.last_name.charAt(0)}`
  }

  if (data.avatar) {
    return (
      <Avatar
        alt="Profile"
        className={clsx(classes.avatar, className)}
        src={data.avatar}
        {...rest}
      />
    );
  }

  return (
    <Avatar
      alt="profile"
      className={clsx(classes.avatar, classes.textAvatar, className)}
      {...rest}
    >
      {generateSymbol()}
    </Avatar>
  )
  
};

ProfileAvatar.defaultProps = {
  className: null
};

ProfileAvatar.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired
};

export default ProfileAvatar;
