import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import rootReducer from './reducers';
import rootSaga from './sagas';

export default function configureStore(history = {}, preloadedState = {}) {
  const routeMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const enhancers = [];
  enhancers.push(applyMiddleware(...[routeMiddleware, sagaMiddleware]));
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION') {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
      stateTransformer(state) {
        return state;
      },
      actionTransformer(action) {
        return action;
      },
      // eslint-disable-next-line no-unused-vars
      configureScopeWithState(scope, state) {}
    });
    enhancers.push(sentryReduxEnhancer);
  }

  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(compose(...enhancers))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
