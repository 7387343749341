import React, { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles, ThemeProvider } from '@material-ui/styles';
import { FormControl, Select, MenuItem, Grid, createTheme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { ExtraApi } from 'api';
import { RequestHelper } from 'utils';
import { PortfolioContext } from 'context/PortfolioContext';
import { DurationChooser, SmallProgress } from 'components';
import { COLORS } from 'config/constants';
import StockPriceAreaChart from '../StockPriceAreaChart';

const options = [
  { key: 1, label: 'SMA', value: 'SMA' },
  { key: 2, label: 'BBANDS', value: 'BBANDS' },
];

const styles = theme => ({
  close: {},
  duration: {
    width: '100% !important',
    minWidth: 476,
    maxWidth: 476,
    '& button': {
      minWidth: 75,
      maxWidth: 75,
      height: 25,
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontFamily: 'Roboto, sans-serif',
      lineHeight: '11px',
      letterSpacing: 1,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '350px !important',
      maxWidth: '385px !important',
      '& button': {
        minWidth: 55,
        maxWidth: 60,
        height: 25,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 8px 0px !important',
      minWidth: '350px !important',
      maxWidth: '385px !important',
      '& button': {
        height: 29,
        marginRight: 5,
        minWidth: 48,
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 8px 0px !important',
      backgroundColor: COLORS.white,
      minWidth: '265px !important',
      maxWidth: '330px !important',
      '& button': {
        height: 29,
        marginRight: 5,
        minWidth: 48,
      },
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: -theme.spacing(2)
  },
  chartControlWrap: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: 'auto',
    minWidth: 200,
  },
  chartWrap: {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
  },
  chartLoadingWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: '50%',
    height: '100%',
    width: '100%',
  },
  indicatorSelect: {
    boxShadow: '0px 2px 4px #00000040 !important',
    borderRadius: '23px !important',
    width: 134,
    maxWidth: '134px !important',
    height: 25,
    color: '#26A7DF',
    fontWeight: 'bold',
    fontSize: 10,
    lineHeight: '11px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 1,
    textTransform: 'uppercase',
    position: 'relative',
    marginLeft: 320,
    '& fieldset': {
      borderColor: 'white !important',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 280,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '65%',
      minWidth: '65px',
      maxWidth: '108px !important',
    },
  },
  indItem: {
    height: 25,
    minHeight: 25,
    minWidth: '44px',
    marginTop: '8px',
    maxWidth: 'max-content',
    backgroundColor: 'white !important',
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 23,
    color: '#26A7DF',
    fontSize: 10,
    lineHeight: '11px',
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
    '&:hover': {
      backgroundColor: '#26A7DF !important',
      color: 'white !important',
    },
    '&:active': {
      backgroundColor: '#26A7DF !important',
    },
  },
  selectPlacehold: {
    position: 'absolute',
    right: 50,
    top: 6,
    color: '#26A7DF',
    fontSize: 10,
    lineHeight: '11px',
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 1,
    [theme.breakpoints.down('xs')]: {
      right: '15%',
    },
  },
});

const theme = createTheme({
  overrides: {
    MuiPaper: {
      elevation8: {
        boxShadow: 'none',
      },
      root: {
        backgroundColor: 'transparent',
        '@media (max-width: 767.95px)': {
        }
      }
    },
    MuiMenu: {
      list: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingRight: 5,
        minWidth: 111,
        position: 'relative',
        marginTop: -20
      },
    },
    // Applied to the <li> elements
    MuiSelect: {
      select: {
        textAlign: 'center'
      },
      icon: {
        color: '#26A7DF'
      },
    },
  },
});

const StockPriceModal = (props) => {
  const [indicator, setIndicator] = useState('');
  const [duration, setDuration] = useState('1D');
  const [technicalData, setTechnicalData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { height, width, margin, security, classes } = props;

  const requestHelper = new RequestHelper();

  const { modal } = useContext(PortfolioContext);
  const handleDuration = dur => {
    setDuration(dur);
  };

  const handleIndicator = ({ target: { value } }) => {
    if (value) {
      if (value !== indicator) {
        setIndicator(value);
      }
    } else {
      setIndicator('');
      setTechnicalData([])
    }
  };

  const getStockPriceHistoricalData = (symbol, duration) => {
    const params = { duration, symbol };
    return requestHelper
      .registerRequest(ExtraApi.getStockPriceByParams(params))
      .then(res => res.data)
      .catch(err => {
        console.error(err)
      });
  };

  const getTechnicalIndicatorData = (symbol, indicator, duration) => {
    if (indicator) {
      const params = { indicator, duration, symbol };
      return requestHelper
        .registerRequest(ExtraApi.getTechnicalIndicatorByParams(params))
        .then(res => res.data)
        .catch(err => {
          console.error(err);
        });
    } else {
      setTechnicalData([])
      return Promise.resolve();
    }
  };

  const handleData = useCallback(() => {
    setLoading(true);
    Promise
      .all([
        getStockPriceHistoricalData(security?.symbol, duration),
        getTechnicalIndicatorData(security?.symbol, indicator, duration),
      ])
      .then(([historicData, IndicatorData]) => {
        setPriceData(historicData ? historicData?.results : []);
        setTechnicalData((duration !== '1D' && indicator) ? IndicatorData?.results : []);
        setLoading(false);
      })
      .catch(err => {
        console.log('~~~err', err);
        setLoading(false);
      })
  }, [indicator, duration, security])

  useEffect(() => {
    handleData();
  }, [indicator, duration, security])

  return (
    <>
      <div className={classes.chartWrap}>
        <ThemeProvider theme={theme}>
          <FormControl
            className={classes.formControl}
            variant="outlined"
          >
            {!indicator && modal.open && modal.selectedIdx === 3 && (
              <p className={classes.selectPlacehold}>Indicator</p>
            )}
            {modal.open && modal.selectedIdx === 3 && (
              <Select
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  // classes: {
                  //   list: classes.listWrappa,
                  // },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: undefined
                }}
                className={classes.indicatorSelect}
                onChange={handleIndicator}
                placeholder={!indicator && 'Indicator'}
                value={indicator}
              >
                <MenuItem
                  className={classes.indItem}
                  value="none"
                >
                  None
                </MenuItem>
                {options.map((item, index) => (
                  <MenuItem
                    className={classes.indItem}
                    key={`${item.key}-${index}`}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </ThemeProvider>

        <StockPriceAreaChart
          height={height > 400 ? 400 : height}
          indicator={indicator}
          margin={margin}
          primaryData={priceData}
          secondaryData={technicalData}
          security={security}
          width={width > 800 ? 800 : width}
        />
        {loading &&
          <div className={classes.chartLoadingWrap}>
            <SmallProgress />
          </div>
        }
      </div>

      <Grid
        className={classes.chartControlWrap}
        container
        justifyContent="center"
      >
        <Grid
          item
          lg={7}
          xs={12}
        >
          {modal.open && modal.selectedIdx === 3 && (
            <DurationChooser
              className={classes.duration}
              onSelect={handleDuration}
              selected={duration}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

StockPriceModal.defaultProps = {
  className: null,
  classes: null,
};

StockPriceModal.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  height: PropTypes.number,
  margin: PropTypes.number,
  width: PropTypes.number,
  security: PropTypes.shape({
    symbol: PropTypes.string,
  }),
};

export default withStyles(styles)(StockPriceModal);
