import React, { useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { darkTheme } from 'theme';
import { useWindowDimensions, useIsomorphicLayoutEffect } from 'utils';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  menu: {
    maxWidth: '100%',
    borderRadius: '26px',
    opacity: '0.92 !important',
    zIndex: 99,
    '& ul': {
      marginTop: '47px',

    },
    [theme.breakpoints.down('sm')]: {
      overflowY: 'hidden',
      '& ul': {
        marginTop: 'unset',
        '& li:first-of-type': {
          marginTop: '22px'
        },
      },
      width: 354,
      height: 124,
    }
  },
  menuDims: {
    width: 289,
    height: 212,
  },
  classMenuDims: {
    width: 910,
    height: 275,
    [theme.breakpoints.down('md')]: {
      maxWidth: 752,
      width: '100%',
      minWidth: 751,
      height: 265,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      width: 392,
      height: 412,
      minWidth: 354,
      minHeight: 124,
    },
  },
  menuItem: {
    backgroundColor: '#26A7DF',
    borderRadius: 26,
    margin: '0 22px 12px',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      minHeight: 33,
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: '2px solid #26A7DF',
      '& span': {
        color: '#26A7DF',
      }
    },
    '& span': {
      font: 'normal normal bold 14px/16px Roboto',
      color: '#FFFFFF',
      textAlign: 'center',
      letterSpacing: 1.4,
    }
  },
}));

const GenericMoreButton = props => {
  const classes = useStyles();
  const { data, isBlue, modalOpen, classroomItem, ...others } = props;
  const moreRef = useRef(null);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const onItemClick = (item) => {
    handleMenuClose();
    return item.onClick();
  };

  const { width } = useWindowDimensions();

  useIsomorphicLayoutEffect(() => {
    if (openMenu) {
      handleMenuClose();
    }
  }, [width])
  const ellipsisSrcProvider = (isBlue, isOpen) => {
    if (isBlue && isOpen) {
      return '/images/ellip-me-blue.png'
    } else if (!isOpen && (classroomItem || isBlue)) {
      return '/images/ellipsis-more-white.svg'
    } else {
      return '/images/ellip-me-blue.png'
    }
  }
  const anchorPosOffset = classroomItem ? { top: 4, left: 73 } : { top: 8, left: 68 };

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Tooltip title="More options">
          <IconButton
            style={modalOpen ? { zIndex: 1, pointerEvents: 'none' } : openMenu ? { zIndex: 1301 } : { zIndex: 1 }}
            {...others}
            onClick={!openMenu ? handleMenuOpen : handleMenuClose}
            ref={moreRef}
          >
            <img
              alt="ellipsis-img"
              src={ellipsisSrcProvider(isBlue, openMenu)}
              style={mobileDevice ? { marginTop: '-10px', imageRendering: 'auto' } : { imageRendering: 'auto', }}
            />
          </IconButton>
        </Tooltip>
      </ThemeProvider>
      <Menu
        PaperProps={{ 
          className: clsx(classes.menu, classroomItem ? classes.classMenuDims : classes.menuDims)
        }}
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorPosition={{
          top: moreRef?.current?.getBoundingClientRect().top - anchorPosOffset.top,
          left: moreRef?.current?.getBoundingClientRect().left + anchorPosOffset.left
        }}
        anchorReference="anchorPosition"
        keepMounted
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {data.map(item => (
          <MenuItem
            className={classes.menuItem}
            key={item.label}
            onClick={() => onItemClick(item)}
          >
            <ListItemText
              classes={item.classes}
              primary={item.label}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

GenericMoreButton.defaultProps = {
  className: null,
  isBlue: false,
  data: [],
  modalOpen: false,
  classroomItem: false,
};

GenericMoreButton.propTypes = {
  className: PropTypes.string,
  isBlue: PropTypes.bool,
  modalOpen: PropTypes.bool,
  classroomItem: PropTypes.bool,
  menuDims: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.any,
    label: PropTypes.string,
    onClick: PropTypes.func,
    classes: PropTypes.any,
  })),
};

export default memo(GenericMoreButton);
