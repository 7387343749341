import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { FormHelperText, Hidden, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorHelper, RequestHelper, useQuery } from 'utils';
import { RESTS } from 'config/constants';
import { GridContainer, GridItem, TextField, SubmitButton, makeValidate, CustomButton, SelectField } from 'components';
import { SupportTicketApi } from 'api';
import positions from './data'
import styles from'./styles';
import { useSelector } from 'react-redux';
import { CONSTANTS } from 'config/global';

const useStyles = makeStyles(styles);

const schema = Yup.object().shape({
  first_name: Yup.string()
    .required('Required'),
  last_name: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  position: Yup.string(),
  school_name: Yup.string().required('Required'),
  description: Yup.string()
    .required('Required')
});

const authdContactSalesSchema = Yup.object().shape({
  position: Yup.string(),
  school_name: Yup.string().required('Required'),
  description: Yup.string()
    .required('Required')
});

const unAuthdSchedEventSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Required'),
  last_name: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  position: Yup.string().required('Required'),
  anticipated_teacher_participation: Yup.string(),
  anticipated_student_participation: Yup.string(),
  school_name: Yup.string(),
  description: Yup.string()
    .required('Required'),
});

const authdSchedEventSchema = Yup.object().shape({
  position: Yup.string().required('Required'),
  anticipated_teacher_participation: Yup.string(),
  anticipated_student_participation: Yup.string(),
  school_name: Yup.string(),
  description: Yup.string()
    .required('Required'),
});

const handleSchema = (isAuth, isEvent) => {
  if (isAuth) {
    if (isEvent) {
      return authdSchedEventSchema;
    } else {
      return authdContactSalesSchema;
    }
  } else {
    if (isEvent) {
      return unAuthdSchedEventSchema;
    } else {
      return schema;
    }
  }
}

const ContactSales = props => {
  const classes = useStyles();
  const query = useQuery();
  const { authorized } = useSelector(({ session }) => session);
  const recaptchaRef = useRef();
  const INIT = {
    form: {
      loading: false,
      err: null
    }
  };

  const [form, setForm] = useState(INIT.form);
  const initialValues = {
    email: query.get('email') || '',
    first_name: query.get('first_name') || '',
    last_name: query.get('last_name') || '',
  }
  const requestHelper = new RequestHelper();

  const [rToken, setRToken] = useState({
    value: null,
    err: null,
  });
  const [showMessage, setShowMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const validate = makeValidate(handleSchema(authorized, props.scheduleEvent));

  const _handleRecaptchaChange = (v) => {
    if (v) {
      setRToken({
        value: v,
        err: null,
      });
    } else {
      setRToken({
        value: null,
        err: 'Verify that you are human.',
      });
    }
  }

  const _onSubmit = (v, form) => {
    setSubmitting(true);
    setForm({
      ...INIT.form,
      loading: true
    });
    let newVals = { ...v };
    if (!authorized) newVals = {...newVals, g_recaptcha_token: rToken.value };
    if (!rToken.value && !authorized) {
      return setRToken({
        value: null,
        err: 'Verify that you are human.'
      })
    }
    let apiCall = props.scheduleEvent
      ? SupportTicketApi.scheduleEvent(newVals)
      : SupportTicketApi.contactSales(newVals)
    requestHelper
      .registerRequest(apiCall)
      .then(() => {
        setForm({
          ...INIT.form,
          g_recaptcha_token: rToken.value,
        })
        setSubmitting(false);
        props.handleStatus('success')
        setShowMessage('Thank you for your interest! One of our dedicated sales representatives will reach out to you in the order that your inquiry was received.')
        form.restart();
      })
      .catch(err => {
        setForm({ loading: false, err });
        setSubmitting(false);
        props.handleStatus('failed')
        let formattedErrTxt = ErrorHelper.getErrString(err).includes('unknown error') ? 
          'An unknown error occurred. Please refresh your browser and try again.' : ErrorHelper.getErrString(err);
        setShowMessage(formattedErrTxt);
        console.error(ErrorHelper.getErrString(err))
      })
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(CONSTANTS.EMAIL_PATTERN);
  };

  const isValid = ({ first_name, last_name, email, description, rToken, school_name, position }) => {
    if (!authorized) {
      return !first_name || !last_name || !email || !validateEmail(email) || !description || !rToken.value || !school_name || !position;
    } else {
      if (props.scheduleEvent) {
        return !description || !position;
      }
      return !description || !school_name;
    }
  }

  return (
    <>
      <Backdrop
        open={submitting}
        style={{ color: '#fff', zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showMessage.length > 0 ? (
        <div className={classes.contactSalesRespWrapper}>
          <Typography
            className={classes.formRespText}
            variant={'h5'}
          >
            {showMessage}
          </Typography>
          <CustomButton
            className={classes.formRespBtn}
            onClick={props.onClose}
            rounded
            title="CLOSE"
          />
        </div>
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={_onSubmit}
          render={({ handleSubmit, values: {first_name, last_name, email, description, school_name, position} }) => (
            <form
              className={clsx(classes.root, props.className, 'debug--container')}
              noValidate
              onSubmit={handleSubmit}
            >
              <GridContainer
                className={!authorized ? classes.row : classes.spacedRow}
                hidden={authorized}
              >
                <GridItem
                  component={Hidden}
                  implementation="css"
                  lg={1}
                  mdDown
                />
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={5}
                  md={5}
                  sm={10}
                  xs={8}
                >
                  <TextField
                    label="First Name"
                    name="first_name"
                    placeholder="Enter First Name"
                  />
                </GridItem>
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={5}
                  md={5}
                  sm={10}
                  xs={8}
                >
                  <TextField
                    label="Last Name"
                    name="last_name"
                    placeholder="Enter Last Name"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                className={classes.row}
                hidden={authorized}
              >
                <GridItem
                  component={Hidden}
                  implementation="css"
                  lg={1}
                  mdDown
                />
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={10}
                  md={10}
                  sm={10}
                  xs={8}
                >
                  <TextField
                    label="Email"
                    name="email"
                    placeholder="Enter Email Address"
                    type="email"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.row}>
                <GridItem
                  component={Hidden}
                  implementation="css"
                  lg={1}
                  mdDown
                />
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={5}
                  md={5}
                  sm={10}
                  xs={8}
                >
                  <SelectField
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      classes: {
                        list: classes.supportOptsList,
                        paper: classes.selectOptsListPaper,
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: undefined
                    }}
                    className={classes.selectBox}
                    data={positions}
                    label="Position"
                    name="position"
                    placeholder="Choose Position"
                  />
                </GridItem>
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={5}
                  md={5}
                  sm={10}
                  xs={8}
                >
                  <TextField
                    label="School Name"
                    name="school_name"
                    placeholder="Enter School Name"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                className={classes.nameRow}
                hidden={!props.scheduleEvent}
              >
                <GridItem
                  component={Hidden}
                  implementation="css"
                  lg={1}
                  mdDown
                />
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={5}
                  md={5}
                  sm={10}
                  xs={8}
                >
                  <TextField
                    label="Anticipated Teacher Participation"
                    name="anticipated_teacher_participation"
                    placeholder="Enter Number of Teachers"
                    type="number"
                  />
                </GridItem>
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={5}
                  md={5}
                  sm={10}
                  xs={8}
                >
                  <TextField
                    label="Anticipated Student Participation"
                    name="anticipated_student_participation"
                    placeholder="Enter Number of Students"
                    type="number"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.descriptionRow}>
                <GridItem
                  component={Hidden}
                  implementation="css"
                  lg={1}
                  mdDown
                />
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={10}
                  md={10}
                  sm={11}
                  xs={6}
                >
                  <TextField
                    label="Your message"
                    multiline
                    name="description"
                    placeholder="Type Your Message"
                    rows={10}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                className={classes.row}
                hidden={authorized}
              >
                <GridItem
                  component={Hidden}
                  implementation="css"
                  lg={1}
                  mdDown
                />
                <GridItem
                  className={classes.inputWrapperPadding}
                  lg={8}
                  md={8}
                  sm={11}
                  xs={6}
                >
                  <ReCAPTCHA
                    className={classes.margTop}
                    onChange={_handleRecaptchaChange}
                    ref={recaptchaRef}
                    sitekey={RESTS.RECAPTCHA_SITE_KEY}
                    size="normal"
                  />
                  <FormHelperText classes={{ root: classes.errorTxt }}> {rToken.err} </FormHelperText>
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.row}>
                <GridItem
                  component={Hidden}
                  implementation="css"
                  lg={1}
                  mdDown
                />
                <GridItem
                  className={classes.btnWrapper}
                  lg={8}
                  md={10}
                  sm={11}
                  xs={6}
                >
                  <SubmitButton
                    className={classes.submit}
                    disabled={form.submitting || isValid({first_name, last_name, email, description, rToken, school_name, position})}
                    fullWidth
                    loading={form.submitting}
                    type="submit"
                  >
                Submit
                  </SubmitButton>
                  <CustomButton
                    className={classes.closeModalBtn}
                    onClick={props.onClose}
                    rounded
                    title="CLOSE"
                  />
                </GridItem>
              </GridContainer>
            </form>
          )}
          validate={validate}
        />
      )}
    </>
  );
};

ContactSales.defaultProps = {
  className: null,
  scheduleEvent: false,
  handleStatus: () => {}
};

ContactSales.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  scheduleEvent: PropTypes.bool,
  handleStatus: PropTypes.func,
};

export default ContactSales;
