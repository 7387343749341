import http from './http'

const Security = {
  findById: (id) => http.get(`/api/securities/${id}/`),

  getByParams: (params) => http.get('/api/securities/', { params }),

  getNews: (id) => http.get(`/api/securities/${id}/news/`)
}

export default Security