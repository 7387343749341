import http from './http'

const Auth = {

  login: (data) => http.post('/api-token-auth/', { data }),

  verifyToken: (data) => http.post('/api-token-verify/', { data }),

  resetPasswordRequest: (data) => http.post('/api/auth/send-reset-password-email/', { data }),

  resetPassword: (data) => http.post('/api/auth/reset-password/', { data }),

  verifyEmail: (data) => http.post('/api/auth/verify-email/', {data}),

  sendVerificationEmail: (data) => http.post('/api/auth/send-verification-email/', { data }),

  getZogoToken: () => http.get('/api/auth/zogo-token/'),

};

export default Auth;
