import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { COLORS } from 'config/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  greet: {
    // marginLeft: -6,
    textTransform: 'uppercase',
    fontWeight: 300,
    fontSize: '48px',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      marginLeft: -2,
      fontSize: 44
    }
  },
  note: {
    marginTop: theme.spacing(2),
    color: COLORS.white,
    lineHeight: 1.4,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    fontFamily: 'Roboto, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '16px',
      width: 364,
      padding: '0 5px'
    },
  }
}));
const Greet = props => {
  const { children, greet, note, } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.row, props.className)}>
      <Typography
        className={clsx(classes.greet, props.classes.greet)}
        variant="h1"
      >
        {greet} {children}
      </Typography>
      <Typography
        className={classes.note}
        variant={'h5'}
      >
        {note}
      </Typography>
    </div>
  );
};

Greet.defaultProps = {
  classes: {}
}

Greet.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  greet: PropTypes.string,
  note: PropTypes.string,
};

export default Greet;
