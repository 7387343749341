import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { COLORS } from 'config/constants';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
  },
  cover: {
    height: '100%',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      backgroundPosition: '22% top',
    },
    alignItems: 'center',
    minWidth: theme.breakpoints.sm,
    backgroundColor: COLORS.darkBlue
  },
  content: {
    flex: 1
  },
  logoWrapper: {
    position: 'absolute',
    top: '-25%',
    bottom: 0,
    right: 0,
    left: 0,
  },
  transparentLogo: {
    width: '120%',
    maxWidth: 1400
  },
  gradient: {
    background: 'linear-gradient(130deg, #26A8DF 0%, #F719FF 100%)'
  }
}));

const Banner = props => {
  const classes = useStyles();
  const { className } = props;
  return (
    <div className={classes.root}>
      <div className={clsx(classes.cover, props.gradient && classes.gradient, className)}>
        {
          props.hasLogo && (
            <Box className={clsx(classes.logoWrapper, props.classes.logo)}>
              <img
                alt="transparent logo"
                className={classes.transparentLogo}
                src="/images/white_fintron.png"
              />
            </Box>
          )
        }
        <div className={classes.content}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

Banner.defaultProps = {
  classes: {
    logo: null
  },
  gradient: false,
  hasLogo: false,
};

Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.shape({
    logo: PropTypes.string,
  }),
  gradient: PropTypes.bool,
  hasLogo: PropTypes.bool,
};

export default Banner;
