import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { withWidth } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import uuid from 'uuid/v1';
import { COLORS, FONTS } from 'config/constants';
import { GridContainer, GridItem, MagicContainer } from '../..';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'url(/images/footer-right.png)  0% 0% no-repeat padding-box',
    backgroundSize: 'cover',
    color: theme.palette.white,
    position: 'absolute',
    height: '100%',
    minWidth: '375px',
    width: 525,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    right: 0,
    '& > :nth-child(1)': {
      alignSelf: 'flex-start',
      margin: '30%',
    },
    // '& > * + *': {
    //   marginTop: theme.spacing(5)
    // },
    [theme.breakpoints.down('md')]: {
      right: 5,
      justifyContent: 'center',
      width: 380,
      padding: theme.spacing(2, 0, 5),
      '& > :nth-child(1)': {
        alignSelf: 'flex-start',
        margin: '30% 41%',
        paddingRight: 'unset'
      },
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(-45deg)',
      position: 'unset',
      width: '100vw',
      height: '65vh',
      justifyContent: 'flex-start',
      backgroundSize: 'contain',
      marginBottom: '36px',
      minWidth: '375px',
      marginTop: -120,
      marginLeft: '35%',
      '& > :nth-child(1)': {
        transform: 'rotate(45deg)',
        alignSelf: 'normal',
        margin: '0% 0 10% 0',
        paddingRight: '24px'
      },
      '& > * + *': {
        //marginTop: theme.spacing(2)
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '30%',
      marginTop: -60
    }
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '102px',
    margin: '0 -50%',
    [theme.breakpoints.down('md')]: {
      margin: '0 98%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '-30% 10%',
    }
  },
  rightLink: {
    '& > p': {
      fontSize: '13px',
      fontWeight: 900,
      letterSpacing: '1.3px',
      '&:hover': {
        color: '#466DB1',

      },
    },
  },
  mainText: {
    color: COLORS.darkGray,
    fontFamily: 'Oswald',
    fontWeight: 400,
    fontSize: '32px',
    letterSpacing: '-0.2px',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(5)
  },
  listText: {
    fontFamily: FONTS.SourceSansPro,
    color: COLORS.white,
    width: '100%',
    fontSize: 15,
    [theme.breakpoints.down('md')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11
    }
  },
  description: {
    color: COLORS.white,
    fontSize: 12,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 11
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  topSpace: {
    paddingTop: theme.spacing(3),
  }
}));

const FooterRight = () => {

  const classes = useStyles();
  const links = [
    {
      text: 'Support',
      link: '/public/support'
    },
    {
      text: 'Contact Us',
      link: '/public/contact-us'
    },
    {
      text: 'Create',
      link: '/create'
    },
    {
      text: 'Join',
      link: '/join'
    },
    {
      text: 'Learn & Earn',
      link: '/learn-and-earn?widget_type=learn_and_earn'
    },
    {
      text: 'My Contests',
      link: '/my-contests'
    }
  ];

  return (
    <MagicContainer
      className={classes.root}
    >
      <GridContainer className={classes.content}>
        <GridItem
          lg={5}
          md={8}
          sm={8}
          xs={6}
        >
          <List
            className={clsx(classes.list)}
          >
            {links.map(item => (
              <ListItem
                className={classes.rightLink}
                component={Link}
                disableGutters
                key={uuid()}
                to={item.link}
              >
                <Typography className={classes.listText}>{item.text}</Typography>
              </ListItem>
            ))}
          </List>
        </GridItem>
      </GridContainer>
    </MagicContainer>
  );
};

export default withWidth()(FooterRight);
