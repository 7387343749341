import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import TextField from '@material-ui/core/TextField';
import {
  default as MuiAutocomplete,
} from '@material-ui/lab/Autocomplete';

export default function Autocomplete (props) {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      render={fieldRenderProps => (
        <AutocompleteWrapper
          {...fieldRenderProps}
          {...rest}
        />)}
      {...fieldProps}
    />
  );
}

Autocomplete.propTypes = {
  name: PropTypes.string,
  fieldProps: PropTypes.objectOf(PropTypes.any)
}

// eslint-disable-next-line react/no-multi-comp
const AutocompleteWrapper = (props) => {
  const {
    input: { onChange, value, ...restInput },
    meta,
    options,
    label,
    required,
    multiple,
    textFieldProps,
    getOptionValue,
    ...rest
  } = props;

  function getValue(values) {
    if (!getOptionValue) {
      return values;
    }

    // ternary hell...
    return multiple
      ? values
        ? values.map(getOptionValue)
        : undefined
      : values
        ? getOptionValue(values)
        : undefined;
  }

  const { helperText, ...lessrest } = rest;
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  const { variant, ...restTextFieldProps } = (textFieldProps) || {};

  // yuck...
  let defaultValue = undefined;

  if (!getOptionValue) {
    defaultValue = value;
  } else if (value !== undefined && value !== null) {
    options.forEach((option) => {
      const optionValue = getOptionValue(option);
      if (multiple) {
        if (!defaultValue) defaultValue = [];
        (value).forEach((v) => {
          if (v === optionValue) {
            defaultValue.push(option);
          }
        });
      } else {
        if (value === optionValue) {
          defaultValue = option;
        }
      }
    });
  }

  const onChangeFunc = (_e, values) => onChange(getValue(values));

  return (
    <MuiAutocomplete
      multiple={multiple}
      onChange={onChangeFunc}
      options={options}
      renderInput={(params) => (
        <TextField
          error={showError}
          fullWidth
          helperText={showError ? meta.error || meta.submitError : helperText}
          label={label}
          margin="normal"
          required={required}
          variant={variant}
          {...params}
          {...restInput}
          {...restTextFieldProps}
        />
      )}
      value={defaultValue}
      {...lessrest}
    />
  );
};

AutocompleteWrapper.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  meta: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  multiple: PropTypes.bool,
  fieldProps: PropTypes.objectOf(PropTypes.any),
  textFieldProps: PropTypes.objectOf(PropTypes.any),
  required: PropTypes.bool,
  name: PropTypes.string,
  getOptionValue: PropTypes.func,
  label: PropTypes.string
}