// eslint-disable-next-line no-undef
export const apiHost = process.env.REACT_APP_API_HOST;
export const websocketHost = process.env.REACT_APP_WEBSOCKET_HOST;
export const cmsApiHost = process.env.REACT_APP_CMS_API_HOST;
export const pageSize = 5;
export const INIT_DEPOSIT_AMOUNT = 100000;
export const CONSTANTS = {
  TOKEN: 'token',
  ZOGO: 'zogo',
  EMAIL_PATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
