import { colors } from '@material-ui/core';
import { COLORS } from 'config/constants';

const styles = (theme) => ({
  root: {
    '& label': {
      letterSpacing: 0.36,
      color: '#000000',
      lineHeight: '24px',
      fontSize: 18,
      [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        maxWidth: 215
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 200
      }
    },
    '& input': {
      boxShadow: '0px 2px 4px #00000040',
      '-webkit-box-shadow': '0px 2px 4px #00000040',
      '-webkit-appearance': 'none',
      padding: '11px 12px',
      borderRadius: 6,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 450
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 350
      },
      '&::placeholder': {
        fontSize: 18,
        lineHeight: '21px',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: 0.36,
        [theme.breakpoints.down('md')]: {
          letterSpacing: 0.28,
          fontSize: 14,
        },
      },
      '&:focus': {
        boxShadow: 'rgb(63 81 181 / 25%) 0 0 0 0rem',
        border: '2px solid #3f51b5'
      },
    },
    '& textarea': {
      boxShadow: '0px 2px 4px #00000040',
      borderRadius: 6,
      height: 125,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 95,
        minWidth: 200,
      },
      '&:focus': {
        '-webkit-appearance': 'none',
        boxShadow: '0px 2px 4px #00000040',
        '-webkit-box-shadow': '0px 2px 4px #00000040',
      },
      '-webkit-box-shadow': '0px 2px 4px #00000040',
      '-webkit-appearance': 'none',
      '&::placeholder': {
        fontSize: 18,
        lineHeight: '21px',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: 0.36,
        [theme.breakpoints.down('sm')]: {
          letterSpacing: 0.28,
          fontSize: 14,
        },
      },
    },
  },
  nameRow: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  row: {
    padding: '6px 0 4px'
  },
  spacedRow: {
    marginTop: 15,
  },
  descriptionRow: {
    paddingTop: theme.spacing(2),
  },
  selectBox: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 475
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 375
    },
    '& svg': {
      color: 'black !important',
      marginRight: 7,
    },
    '& input': {
      left: 'unset',
      bottom: 'unset',
      boxShadow: 'unset',
      border: 'none',
      padding: 10,
      opacity: 'unset',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 475
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 375
      },
      '&::placeholder': {
        fontSize: 18,
        lineHeight: '21px',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: 0.36,
        opacity: 0.5,
        [theme.breakpoints.down('md')]: {
          letterSpacing: 0.28,
          fontSize: 14,
        },
      },
    },
    '& :nth-child(1)': {
      boxShadow: '0px 2px 4px #00000040',
      borderRadius: 6,
    },
  },
  submit: {
    backgroundColor: '#26A7DF',
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 100,
    height: 44,
    width: 444,
    minWidth: 444,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'fit-content !important',
      width: '400px !important',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '300px !important',
      width: 'fit-content !important',
    },
    '&:hover': {
      backgroundColor: '#81d4fa !important',
    },
    '& span': {
      letterSpacing: 2.1,
      fontSize: 21,
      [theme.breakpoints.down('sm')]: {
        letterSpacing: 1.4,
        fontSize: 14,
        fontWeight: 'bold'
      },
    },
  },
  errorTxt: {
    color: colors.red[600]
  },
  closeModalBtn: {
    backgroundColor: COLORS.white,
    width: 444,
    height: 44,
    minWidth: 444,
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 100,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      minWidth: 400,
      width: 400,
      marginBottom: 15
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 300,
      width: 'fit-content',
      marginBottom: 15
    },
    '&:hover': {
      backgroundColor: '#81d4fa !important',
    },
    '& span': {
      letterSpacing: 2.1,
      fontSize: 21,
      color: COLORS.eduBlue,
      [theme.breakpoints.down('sm')]: {
        letterSpacing: 1.4,
        fontSize: 14,
        fontWeight: 'bold'
      },
    },
  },
  inputWrapperPadding: {
    padding: '6px 16px !important'
  },
  supportOptsList: {
    backgroundColor: 'transparent !important',
    paddingTop: 0,
    '& li': {
      boxShadow: '0px 2px 4px #00000040',
      borderRadius: 23,
      backgroundColor: '#FFFFFF !important',
      font: 'normal normal bold 14px/16px Roboto',
      letterSpacing: 1.4,
      color: '#26A7DF',
      margin: '10px 5px',
      paddingRight: 5,
      height: 36,
      minHeight: 36,
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal bold 11px/13px Roboto',
        letterSpacing: 1.1,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
        lineHeight: '13px',
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: 1.1,
      },
      '&:hover': {
        backgroundColor: '#26a7df !important',
        color: 'white'
      }
    }
  },
  selectOptsListPaper: {
    background: 'transparent',
    backgroundColor: 'transparent',
    boxShadow: 'unset',
  },
  btnWrapper: {
    width: 'fit-content',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '20px auto 0',
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      marginTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginBottom: 15,
      margin: '20px 0 0 0'
    }
  },
  margTop: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 5
    }
  },
  formRespText: {
    color: COLORS.black,
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    width: '100%',
    fontSize: 16,
    maxWidth: 705,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '385px',
      alignSelf: 'flex-start',
      textAlign: 'left',
      margin: 0
    }
  },
  contactSalesRespWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    minHeight: 'fit-content',
    margin: '0 auto 15px',
  },
  formRespBtn: {
    backgroundColor: COLORS.white,
    width: 'fit-content',
    height: 44,
    minWidth: 200,
    boxShadow: '0px 2px 4px #00000040',
    borderRadius: 100,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15
    },
    [theme.breakpoints.down('xs')]: {
      width: 'fit-content',
      marginBottom: 15
    },
    '&:hover': {
      backgroundColor: '#81d4fa !important',
    },
    '& span': {
      letterSpacing: 2.1,
      fontSize: 21,
      color: COLORS.eduBlue,
      [theme.breakpoints.down('sm')]: {
        letterSpacing: 1.4,
        fontSize: 14,
        fontWeight: 'bold'
      },
    },
  }
});

export default styles;