import React from 'react';
import CustomRouter from './components/CustomRouter'
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { PortfolioProvider } from 'context/PortfolioContext';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import theme from './theme';
import { analytics, sentry } from './utils';
import configureStore from './store';
import AppRouter from './routes';
import './assets/scss/index.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'react-quill/dist/quill.snow.css';

const history = createBrowserHistory();
const store = configureStore(history);

// eslint-disable-next-line no-unused-vars
history.listen((location) => {
  analytics.page();
});
sentry.init(history);
const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <PortfolioProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CustomRouter history={history}>
              <AppRouter />
            </CustomRouter>
          </MuiPickersUtilsProvider>
        </PortfolioProvider>
      </ThemeProvider>

    </StoreProvider>
  );
};

export default App;
