import { NumberFormatBase } from 'react-number-format';

function CurrencyInput (props) {
  const format = (numStr) => {
    if (numStr === '') return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(numStr);
  };
  return (
    <NumberFormatBase 
      {...props}
      format={format}
    />
  );
}
export default CurrencyInput;
