// eslint-disable-next-line no-unused-vars
import React from 'react';
import {withStyles} from '@material-ui/styles';
import {Tooltip} from '@material-ui/core';
import { COLORS, FONTS } from 'config/constants';

const FinTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    color: COLORS.white,
    fontSize: 12,
    maxWidth: 600,
    fontFamily: FONTS.Lato,
    padding: 8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      maxWidth: 300
    }
  },
}))(Tooltip);

export default FinTooltip;
