import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import buttonStyle from './styles';

const makeComponentStyles = makeStyles(() => ({
  ...buttonStyle
}));

const RegularButton = React.forwardRef((props, ref) => {
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    ...rest
  } = props;

  const classes = makeComponentStyles();

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });

  return (
    <Button
      {...rest}
      className={btnClasses}
      ref={ref}
    >
      {children}
    </Button>
  );
});

RegularButton.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'facebook',
    'twitter',
    'google',
    'github',
    'transparent'
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  justIcon: PropTypes.bool,
  link: PropTypes.bool,
  round: PropTypes.bool,
  simple: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
};
RegularButton.displayName = 'RegularButton';
export default RegularButton;
