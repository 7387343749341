import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { COLORS } from 'config/constants';

const useStyles = makeStyles(theme => ({
  btn: {
    fontWeight: 800,
    fontSize: 12,
    textDecoration: 'underline',
    color: COLORS.white,
    paddingVertical: 0,
    marginVertical: 0,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 11
    }
  },
}));

const LinkButton = ({ className, to, ...rest}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.btn, className)}
      component={to ? RouterLink: Button}
      size="small"
      to={to}
      {...rest}
    />
  );
};

LinkButton.defaultProps = {
  className: null
};

LinkButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string
};

export default LinkButton;
