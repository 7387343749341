import { createTheme } from '@material-ui/core/styles'
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import overrides from './overrides';
import { DESIGNS } from 'config/constants';
import styles from './styles';

const common = {
  spacing: DESIGNS.themeSpacing,
  typography,
  breakpoints,
  overrides,
  styles,
}
const theme = createTheme({
  ...common,
  palette,
});

export const darkTheme = createTheme({
  ...common,
  palette: {
    ...palette,
    type: 'dark',
    background: {
      default: '#F4F6F8',
      paper: 'transparent'
    },
  }
})

export default theme;
