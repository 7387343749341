import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Modal from '../Modal';
import CustomButton from '../Button/CustomButton';
import { Typography } from '@material-ui/core';
import { COLORS } from 'config/constants';


const VideoPlayerModal = (props) => {
  const classes = useStyles();
  const { open, title, subTitle, url, onClose, ...rest } = props;

  function renderTitle() {
    if (!subTitle) return <Typography className={classes.title}>{title}</Typography>;
    return (
      <div className={classes.titleLine}>
        <Typography
          className={classes.title}
          variant="h5"
        >{title}&nbsp;</Typography>
        <Typography
          className={classes.subtitle}
          variant="h5"
        >{subTitle}</Typography>
      </div>
    )
  }

  return (
    <Modal
      body={
        <ReactPlayer
          controls
          playing
          url={url}
          width="100%"
          {...rest}
        />
      }
      bordered
      classes={{
        wrap: classes.wrap,
        footer: classes.footer
      }}
      footer={
        <CustomButton
          classNames={{main: classes.button}}
          onClick={onClose}
          title="CLOSE"
        />
      }
      onClose={onClose}
      open={open}
      title={renderTitle()}
    />
  );
};

VideoPlayerModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default VideoPlayerModal;

const useStyles = makeStyles(theme => ({
  wrap: {
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      margin: '0px, 15px'
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
  player: {
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
  },
  titleLine: {
    display: 'flex',
  },
  title: {
    fontWeight: 'bold',
    color: COLORS.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  subtitle: {
    fontFamily: '500',
    color: COLORS.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  button: {
    maxWidth: 300,
  }
}))
