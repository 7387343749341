import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { COLORS } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: COLORS.middleDarkBule,
    fontSize: 14,
    maxWidth: 24
  },
  root: {
    ...theme.styles.shadow,
    display: 'flex',
    backgroundColor: '#26A8DF',
    borderRadius: 24,
    height: 60,
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    '&:last-child': {
      // marginLeft: 1
    }
  },
  flexContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    background: 'transparent',
    '& a': {
      textDecoration: 'none',
      color: COLORS.white,
      fontSize: 11,
      lineHeight: '13px',
      fontWeight: 600,
      letterSpacing: 0.22
    }
  },
  blueBuyBtn: {
    borderRadius: '19px 0px 0px 19px',
    backgroundColor: '#26A7DF',
    width: 41,
    boxShadow: '0px 2px 4px #00000040',
    height: 32,
    '& a': {
      marginLeft: 7,
    }
  },
  redButton: {
    backgroundColor: '#B3539F',
    borderRadius: '0px 19px 19px 0px',
    boxShadow: '0px 2px 4px #00000040',
    width: 41,
    height: 32,
    '& a': {
      marginRight: 7,
    }

  }
}));

const BuySellButton = props => {
  const classes = useStyles();
  return (
    <div className={classes.flexContent}>
      <div className={clsx(classes.root, classes.blueBuyBtn, props.className)}>
        <Link
          className={clsx(classes.actionButton, props.classes.link)}
          to={props.link.buy}
        >
          BUY
        </Link>
      </div>
      {/* {props.isOwner && ( */}
      <div
        className={clsx(classes.root, classes.redButton, props.className)}
        style={props.isOwner === false ? { opacity: 0.8, cursor: 'not-allowed' } : {}}
      >
        <Link
          className={classes.actionButton}
          style={props.isOwner === false ? { pointerEvents: 'none', color: 'gray' } : {}}
          to={props.link.sell}
        >
          SELL
        </Link>
      </div>
      {/* )} */}
    </div>
  );
};

BuySellButton.defaultProps = {
  className: null,
  isOwner: false,
  classes: {}
};

BuySellButton.propTypes = {
  className: PropTypes.string,
  link: PropTypes.shape({
    buy: PropTypes.string,
    sell: PropTypes.string
  }),
  isOwner: PropTypes.bool,
  classes: PropTypes.shape({
    link: PropTypes.string
  })
};

export default BuySellButton;
