import React, { ReactElement, MouseEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'config/constants';
import { Box, Typography } from '@material-ui/core';
import { CustomButton } from 'components'

interface EnrollClassProps {
    contactSales: () => MouseEvent<HTMLElement>,
    previewCourses: () => MouseEvent<HTMLElement>,
    coursePreviewPage: boolean
  }
function EnrollClass({ contactSales, previewCourses, coursePreviewPage = false }: EnrollClassProps): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.enrollSectionContainer}>
      <div className={classes.enrollBannerBg}>
        <Box>
          <Typography
            className={classes.enrollTitle}
            gutterBottom
            variant="h2"
          >
            {'ENROLL YOUR CLASS IN FINTRONU'}
          </Typography>
          <div className={classes.enrollBtnWrapper}>
            <CustomButton
              classNames={{
                main: classes.btnCourse
              }}
              onClick={contactSales}
              shadow
              title="Contact Enrollment"
              type="button"
            />
            {!coursePreviewPage && (
              <CustomButton
                classNames={{
                  main: classes.btnCourse
                }}
                onClick={previewCourses}
                shadow
                title="Preview Courses"
                type="button"
              />
            )}
          </div>
        </Box>
      </div>
    </div>
  )
}

export default EnrollClass;

const useStyles = makeStyles((theme: Theme) => ({
  enrollBannerBg: {
    display: 'flex',
    marginLeft: -7, 
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    height: 292, 
    marginBottom: 100,
    top: -70,
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    background: 'transparent url("/images/banner-enroll-class.png") 0% 0% padding-box',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50,
      marginLeft: 0
    }
  },
  enrollTitle: {
    marginTop: 74,
    textAlign: 'center',
    color: COLORS.white,
    fontSize: 44,
    fontFamily: 'Oswald',
    lineHeight: '65px',
    [theme.breakpoints.down('md')]: {
      fontSize: 39,
      lineHeight: '59px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      lineHeight: '41px',
    }
  },
  enrollBtnWrapper: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 9, 
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
  enrollSectionContainer: {
    position: 'relative',
    height: 282,
    width: '100%',
  },
  btnCourse: {
    backgroundColor: 'white',
    color: '#26a7df',
    letterSpacing: '2.1px',
    width: '100%',
    minWidth: '333px',
    borderRadius: '25px',
    boxShadow: '0px 3px 3px #00000026',
    fontWeight: 600,
    fontSize: 21,
    height: '50px',
    margin: '0 40px',
    '& > span': {
      whiteSpace: 'nowrap',
      padding: '0 10px'
    },
    '&:hover': {
      color: 'white',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      margin: '0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'fit-content',
      height: '36px',
      width: '100%',
      fontSize: 14,
      maxWidth: 350,
      margin: '0 auto 20px',
    }
  },
}));
