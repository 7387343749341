import React from 'react';
import PropTypes from 'prop-types';
import { Group } from '@vx/group';
import { Bar, Line, LinePath, AreaClosed } from '@vx/shape';
import { LinearGradient } from '@vx/gradient';
import { scaleLinear, scaleBand } from '@vx/scale';
import { Tooltip, withTooltip } from '@vx/tooltip';
import { timeFormat } from 'd3-time-format';
import clsx from 'clsx';

// accessors
const xSelector = d => new Date(d.created_at);
const ySelector = d => parseFloat(d.value).toFixed(2);

// util
const formatDate = timeFormat('%m/%d/%Y %H:%M');

const min = (arr, fn) => Math.min(...arr.map(fn));
const max = (arr, fn) => Math.max(...arr.map(fn));
//

const AccountValueChart = (props) => {
  const {
    width,
    height,
    margin,
    data = [],
    hideTooltip,
    tooltipData,
    tooltipTop,
    tooltipLeft,
    showTooltip
  } = props;

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yScale = scaleLinear({
    domain: [min(data, ySelector) - 10, max(data, ySelector) + 10],
    range: [yMax, 0],
    nice: true,
  });
  const xScale = scaleBand({
    domain: data.map(xSelector),
    range: [0, xMax],
    padding: 0.1,
  });

  const handleTooltip = (event, xScale, yScale, data) => {
    if (data) {
      showTooltip({
        tooltipData: data,
        tooltipLeft: xScale(xSelector(data)),
        tooltipTop: yScale(ySelector(data)),
      });
    }
  };

  return (
    <div
      className={clsx('debug--container')}
      style={{ paddingLeft: 5, position: 'relative', height: '100%', width: '100%', borderRadius: 26, backgroundColor: '#FFFFFF' }}
    >
      <div style={{ width, position: 'relative', left: '50%', transform: 'translateX(-50%)' }}>
        <svg
          height={height}
          width={width}
        >
          <LinearGradient
            from="#4B87C4"
            id="gradient"
            to="#e4f2ff"
            toOpacity={0.05}
          />
          <rect
            fill="transparent"
            height={height}
            rx={14}
            width={width}
            x={0}
            y={0}
          />
          <LinePath
            data={data}
            stroke="#404C99"
            strokeWidth={1}
            x={d => xScale(xSelector(d))}
            y={d => yScale(ySelector(d))}
          />

          <AreaClosed
            data={data}
            fill="url(#gradient)"
            stroke="url(#gradient)"
            strokeWidth={1}
            x={d => xScale(xSelector(d))}
            y={d => yScale(ySelector(d))}
            yScale={yScale}
          />

          <Group>
            {data.map((d) => {
              const letter = xSelector(d);
              const barWidth = xScale.bandwidth();
              // const barHeight = yMax - yScale(ySelector(d));
              const barHeight = yMax;
              const barX = xScale(letter);
              const barY = yMax - barHeight;
              return (
                <Bar
                  fill="transparent"
                  height={barHeight}
                  key={`bar-${d.id}`}
                  onMouseLeave={() => hideTooltip()}
                  onMouseMove={event =>
                    handleTooltip(
                      event,
                      xScale,
                      yScale,
                      d
                    )}
                  onTouchMove={event =>
                    handleTooltip(
                      event,
                      xScale,
                      yScale,
                      d
                    )}
                  onTouchStart={event =>
                    handleTooltip(
                      event,
                      xScale,
                      yScale,
                      d
                    )}
                  width={barWidth}
                  x={barX}
                  y={barY}
                />
              );
            })}
          </Group>

          {(tooltipData && tooltipTop && tooltipLeft) && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                stroke="rgba(92, 119, 235, 1.000)"
                strokeDasharray="2,2"
                strokeWidth={2}
                style={{ pointerEvents: 'none' }}
                to={{ x: tooltipLeft, y: yMax }}
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                fill="black"
                fillOpacity={0.1}
                r={4}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                style={{ pointerEvents: 'none' }}
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                fill="rgba(92, 119, 235, 1.000)"
                r={4}
                stroke="white"
                strokeWidth={2}
                style={{ pointerEvents: 'none' }}
              />
            </g>
          )}
        </svg>

        {(tooltipData && tooltipTop && tooltipLeft) ? (
          <div>
            <Tooltip
              left={tooltipLeft}
              style={{
                backgroundColor: 'white',
                color: '#21AAE1',
                transform: 'translateX(-50%)',
                padding: 15,
                borderRadius: 8,
                boxShadow: '2px 3px 5px 2px rgba(0, 0, 0, 0.5)',
              }}
              top={0}
            >
              {`$${ySelector(tooltipData)}`}
            </Tooltip>
            <Tooltip
              left={tooltipLeft}
              style={{
                transform: 'translateX(-50%)',
              }}
              top={yMax - 12}
            >
              {formatDate(xSelector(tooltipData))}
            </Tooltip>
          </div>
        ) : (
          undefined
        )}
      </div>

    </div>
  );
}

AccountValueChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number
  }),
  data: PropTypes.arrayOf(PropTypes.any),
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  tooltipData: PropTypes.any,
  tooltipTop: PropTypes.number,
  tooltipLeft: PropTypes.number,
}

export default withTooltip(AccountValueChart);
