import { RESTS } from 'config/constants'

const getErrString = (err) => {

  if (!err || !err.response) {
    return '';
  }
  const { data } = err.response;
  
  if (data[RESTS.NON_FIELD_ERRORS]) {
    return data[RESTS.NON_FIELD_ERRORS].join(',');
  } else if (data.errors) {
    return data.errors.map(i => i.message).join(', ');
  } else if (data.error) {
    return typeof data.error === 'string' ? data.error : data.error.map(k => Object.values(k)[0]).join('\n');
  } else if (Array.isArray(data)) {
    return data.join(', ');
  } else if (typeof data === 'string') {
    return data;
  } else {
    return Object.keys(data).map(i => {
      const item = data[i];
      if (Array.isArray(item)) {
        return item.join(',')
      } else {
        return item
      }
    }).join(',\n');
  }
};

export default {
  getErrString
}
