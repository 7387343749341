
const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(15, 0, 4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(28, 0, 15)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(15, 0, 10)
    },
  },
  logoWrapper: {
    position: 'absolute',
    marginTop: '-3%',
    bottom: 0,
    right: 0,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: '-10%',
    },
  },
  transparentLogo: {
    width: '120%',
    maxWidth: 1400,
    [theme.breakpoints.down('md')]: {
      marginLeft: -50,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  gradient: {
    background: 'transparent url("/images/hero-2.png") 0% 0% no-repeat border-box',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    zIndex: 9,
    [theme.breakpoints.down('sm')]: {
      marginTop: '-5px'
    },
  }
});

export default styles
