
export default {
  values: {
    xs: 0,
    sm: 560,
    md: 768,
    lg: 1200,
    xl: 1920
  }
}
