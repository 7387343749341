import React from 'react';
import PropTypes from 'prop-types';
import { Radio as MuiRadio } from '@material-ui/core';

export default function RadioWrapper(props) {
  const {
    input: { name, checked, value, onChange, ...restInput },
    // eslint-disable-next-line no-unused-vars
    meta,
    ...rest
  } = props;
  return (
    <MuiRadio
      checked={checked}
      inputProps={restInput}
      name={name}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
}

RadioWrapper.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  meta: PropTypes.objectOf(PropTypes.any),
}