import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Modal, ModalButton, MultiEmailInput } from 'components';

const useStyles = makeStyles(() => ({
  modalBody: {
    '& p': {
      letterSpacing: 0.28,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '26px',
      fontFamily: 'Roboto, sans-serif',
    },
    '& h5': {
      lineHeight: '24px',
    },
    '& input': {
      boxShadow: '0px 2px 4px #00000040',
      borderRadius: '6px',
      '&::placeholder': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '21px',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: 0.36
      }
    },
  },
  rightBtn: {
    margin: '18px auto',
    maxWidth: '334px',
    maxHeight: '45px',
    height: '100%',
    width: '100%',
    '& span': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 21,
      lineHeight: '24px',
      fontFamily: 'Roboto, sans-serif',
      letterSpacing: 2.1,
    }
  },
  icon: {
    width: 'unset',
    height: 'unset',
    marginRight: 'unset'
  }
}));

const InviteModal = ({ onSubmit, className, loading, whom, handleClose, ...rest }) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const _onSubmit = () => {
    if (inputRef.current) {
      const emails = inputRef.current.getValue();
      if (emails.length === 0) {
        inputRef.current.setErr('Please type email addresses and click `Enter`');
      } else {
        onSubmit(emails);
      }
    }
  }

  function generateBody() {
    return (
      <div className={classes.modalBody}>
        <p>{`Enter your ${whom.toLowerCase()}'s email addresses and we will get them to join!`}</p>
        <p>... One way or another</p>
        <MultiEmailInput
          ref={inputRef}
        />
      </div >
    )
  }

  return (
    <Modal
      body={generateBody()}
      bordered
      classes={{
        root: clsx(classes.root, className),
        footer: classes.footer
      }}
      footer={(
        <>
          <ModalButton
            className={classes.rightBtn}
            loading={loading}
            onClick={_onSubmit}
            rounded
            title={'Send'}
          />
          <ModalButton
            className={classes.rightBtn}
            loading={loading}
            onClick={handleClose}
            rounded
            title={'Close'}
          />
        </>
      )}
      icon={'/images/fintronu-popup-icon-invite.png'}
      title={`Invite ${whom}s`}
      {...rest}
    />
  );
};

InviteModal.defaultProps = {
  className: null,
  whom: ''
};

InviteModal.propTypes = {
  className: PropTypes.string,
  whom: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  handleClose: PropTypes.any
};

export default InviteModal;
