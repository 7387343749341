
const styles = theme => ({
  root: {
    padding: theme.spacing(5, 0),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  avatar: {
    borderRadius: 105,    
  },
  avatarWrap: {
    width: 210,
    height: 210,
    borderRadius: 105,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 130,
      height: 130,
      borderRadius: 65
    }
  },
  fullName: {
    fontWeight: 700,
    fontSize: 27,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  role: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  }
});

export default styles
