import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PortfolioContext = createContext();

export const PortfolioProvider = ({ children }) => {
  const [modal, setModal] = useState({open: false, security: null, selectedIdx: null});
  return (
    <PortfolioContext.Provider
      value={{
        modal,
        setModal
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

PortfolioProvider.propTypes = {
  children: PropTypes.any,
};
