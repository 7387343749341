import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Image from 'material-ui-image';
import { CircularProgress } from '@material-ui/core';
import { COLORS } from 'config/constants';

const useStyles = makeStyles(() => ({}));

const ColorCircularProgress = withStyles({
  root: {
    color: COLORS.lightBlue,
  },
})(CircularProgress);

const FImage = ({ className, objectFit = 'cover', ...rest}) => {
  const classes = useStyles();

  return (
    <Image 
      className={clsx(classes.root, className)}
      imageStyle={{ objectFit}} 
      loading={<ColorCircularProgress size={24}/>}
      {...rest}
    />
  );
};

FImage.defaultProps = {
  className: null
};

FImage.propTypes = {
  className: PropTypes.string,
  objectFit: PropTypes.string,
};

export default FImage;
