import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Prism from 'prismjs';
import ReactMarkdown from 'react-markdown/with-html';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import { FONTS } from 'config/constants';
import { DESIGN_UTILS } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& h1': {
      ...theme.typography.h1,
      fontFamily: FONTS.SourceSansPro,
      marginBottom: theme.spacing(1)
    },
    '& h2': {
      ...theme.typography.h2,
      fontFamily: FONTS.SourceSansPro,
      marginBottom: theme.spacing(1),
      fontWeight: 600,
      lineHeight: '1.6em',
      [theme.breakpoints.down('sm')]: {
        fontSize: DESIGN_UTILS.pxToRem(40)
      }
    },
    '& h3': {
      ...theme.typography.h3,
      fontFamily: FONTS.SourceSansPro,
      fontWeight: 600,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: DESIGN_UTILS.pxToRem(32)
      }
    },
    '& h4': {
      ...theme.typography.h4,
      marginBottom: theme.spacing(4),
      fontWeight: 600,
      fontSize: DESIGN_UTILS.pxToRem(24),
      fontFamily: FONTS.Lato,
      [theme.breakpoints.down('sm')]: {
        fontSize: DESIGN_UTILS.pxToRem(18)
      }
    },
    '& h5': {
      ...theme.typography.h5,
      marginBottom: theme.spacing(1)
    },
    '& h6': {
      ...theme.typography.h6,
      marginBottom: theme.spacing(1)
    },
    '& p': {
      ...theme.typography.body1,
      fontFamily: FONTS.Lato,
      fontSize: 18,
      lineHeight: '1.4em',
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: DESIGN_UTILS.pxToRem(14)
      },
      opacity: 0.87
    },
    '& ul': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    '& ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    '& li': {
      ...theme.typography.subtitle1,
      marginBottom: theme.spacing(1)
    },
    '& hr': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      backgroundColor: colors.grey[300],
      border: 0,
      height: 1
    },
    '& a': {
      color: theme.palette.link,
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& .blog-img': {
      '&-title': {},
      '&-item': {
        margin: theme.spacing(-7, 0),
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 0),
        }
      },
      '&--large': {
        width: 450,
        [theme.breakpoints.down('sm')]: {
          width: 300
        }
      },
      '&--medium': {
        width: 300,
        [theme.breakpoints.down('sm')]: {
          width: 250
        }
      } 
    },
    '& .blog-label': {
      // textAlign: 'center',
      fontSize: DESIGN_UTILS.pxToRem(15),
      [theme.breakpoints.down('sm')]: {
        fontSize: DESIGN_UTILS.pxToRem(12)
      },
      '&--title': {
        // width: '80%',
        maxWidth: 800,
        // margin: 'auto',
        marginTop: 30,
        marginBottom: 50
      }
    }
  }
}));

const Markdown = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className={clsx(classes.root, className)}>
      <ReactMarkdown {...rest} />
    </div>
  );
};

Markdown.propTypes = {
  className: PropTypes.string
};

export default Markdown;
