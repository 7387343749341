import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {  Paper, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { GridContainer } from '../index';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 400,
    backgroundColor: 'rgba(0,0,0,0.2)'
  }
}));

const GridLine = props => {
  const classes = useStyles();
  const arr = Array.from(Array(12).keys());

  return (
    <GridContainer
      className={classes.root}
      container
      justifyContent="center"
    >
      {arr.map(value => (
        <Grid
          item
          key={value}
          md={1}
        >
          <Paper className={clsx(classes.paper, props.className, 'debug--grid-design')} />
        </Grid>
      ))}
    </GridContainer>
  );
};

GridLine.defaultProps = {

};

GridLine.propTypes = {
  className: PropTypes.string,
  spacing: PropTypes.number
};

export default GridLine;
