import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Link, Typography } from '@material-ui/core';
import { COLORS } from '../../config/constants';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { DESIGN_UTILS } from 'utils';

const pxToRem = DESIGN_UTILS.pxToRem;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: `linear-gradient(90deg, ${COLORS.bluish} 50%, ${COLORS.pink} 50%)`,
  },
  bannerBox: {
    position: 'relative',
    display: 'flex',
    '& img': {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 84,
        objectFit: 'cover',
        objectPosition: 'right'
      }
    },
  },
  bannerContent: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: theme.spacing(6),
    left: 0,
    display: 'flex',
    flexDirection: 'column',    
    padding: theme.spacing(0, 2, 1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 0.5)
    }
  },
  link: {
    color: COLORS.white,
    bottom: theme.spacing(1),
    left: theme.spacing(3),
    textDecoration: 'underline',
    fontSize: pxToRem(18),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(16)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: pxToRem(11)
    },
  },
  title: {
    color: COLORS.white,
    fontSize: pxToRem(56),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(48)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(36)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(0.5),
      fontSize: pxToRem(26),
    }
  },
  subtitle: {
    color: COLORS.white,
    fontSize: pxToRem(20),
    // flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(18)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: pxToRem(12)
    }
  }
}));

const TradingBanner = (props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={clsx(classes.bannerBox, props.classes.bannerBox)}>
        <img
          alt="Invest Banner"
          src="/images/invest_banner_background.png"
        />
        <Box className={clsx(classes.bannerContent, props.classes.bannerContent)}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            flexGrow={1}
            justifyContent={'center'}
          >
            <Typography
              className={clsx(classes.title, props.classes.title)}
              variant={'h1'}
            >
              START TRADING FOR REAL!
            </Typography>
            <Typography
              className={clsx(classes.subtitle, props.classes.subtitle)}
              variant={'h5'}
            >
              Ready to get real? Take off the training wheels. Download the FinTron app now!
            </Typography>
          </Box>
          <Box display="inline">
            <Link
              className={clsx(classes.link, props.classes.link)}
              href="https://apps.apple.com/us/app/fintron-finance-reborn/id1517080230"
              target="_blank"
            >
            View in App Store (18+)
            </Link>
          </Box>
        </Box>
      </div>
    </div>
  );
};

TradingBanner.defaultProps = {
  className: '',
  classes: {},
};

TradingBanner.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
};

export default TradingBanner;
