import { all } from 'redux-saga/effects';
import session from './session/sagas';
import points from './points/sagas';

export default function* rootSaga() {
  yield all([
    session(),
    points(),
  ]);
}
