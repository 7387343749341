import { COLORS, FONTS } from 'config/constants';

const styles = (theme) => ({
  sendBtn: {
    width: 81,
    height: 30,
    backgroundColor: COLORS.eduBlue,
    borderRadius: 100,
    fontWeight: 700,
    fontFamily: FONTS.Roboto,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '1.8px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: COLORS.eduBlue,
      opacity: 0.5
    }
  },
  discussionBoardWrapper: {
    height: 383,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'fit-content'
    },
  },
  chatBoxSpacing: {
    padding: '0 41px 20px',
  },
  discussionBoardFooter: {
    display: 'flex',
    borderTop: '1px solid #707070',
    height: 50,
    justifyContent: 'space-around',
    width: '100%',
    padding: '10px 20px'
  },
  messageItem: {
    width: '100%',
    padding: '10px 20px',
    borderRadius: 11,
    fontSize: 16,
    letterSpacing: 0.32,
    wordBreak: 'break-word',
  },
  txtDate: {
    margin: '5px 0px',
    fontFamily: FONTS.Roboto,
    fontSize: 10,
    letterSpacing: 0.2,
    color: '#AAAAAA'
  },
  msgName: {
    fontFamily: FONTS.Roboto,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.24px',
    color: COLORS.eduBlue,
    margin: '5px 0px',
  },
  discussionContainer: {
    marginTop: 27,
    width: 686,
    border: '1px solid #707070',
    borderRadius: 22,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  discussionTitleWrapper: {
    height: 62,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid #707070',
  },
  discussionTitle: {
    fontFamily: FONTS.Roboto,
    lineHeight: '26px',
    fontSize: 20,
    fontWeight: 'bold',
    color: COLORS.eduBlue,
    letterSpacing: 0.4,
    textTransform: 'uppercase',

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  txtLessonName: {
    fontWeight: 'bold',
    color: COLORS.eduBlue,
    marginBottom: 10
  },
  subtitle: {
    fontFamily: FONTS.Roboto,
    fontSize: 19,
    lineHeight: '25px',
    letterSpacing: 0.38,
    color: 'black',
  },
  subTitleLine: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  disabledColor: {
    color: '#ADADAD !important'
  },
  disabledButton: {
    backgroundColor: '#ADADAD',
    color: '#FFFFFF !important'
  },
  flexedMsg: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
  }
});

export default styles;