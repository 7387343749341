import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { FinTooltip } from '../index';

const useStyles = makeStyles(() => ({
  icon: {
    paddingRight: 4,
    zIndex: 100
  }
}));

const TParagraph = props => {
  const classes = useStyles();
  const delimit = '&TP&';
  const strArr = props.children.split(delimit);

  if (strArr.length > 1) {
    return (
      <>
        {strArr[0]}
        <span>
          <FinTooltip
            arrow
            className={classes.tooltip}
            enterTouchDelay={200}
            title={strArr[1]}
          >
            <i
              aria-hidden="true"
              className={clsx('fa fa-question-circle', classes.icon )}
            />
          </FinTooltip>
        </span>
        {strArr[2]}
      </>
    )
  }
  return (
    <>{props.children}</>
  );
};

TParagraph.defaultProps = {
  className: null
};

TParagraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};

export default TParagraph;
