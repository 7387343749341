import http from './http';

const MasterBudget = {
  postMasterBudget: (data) => http.post('/api/recurring/master-budgets/', { data }),
  updateMasterBudget: (userId, data) => http.put(`/api/recurring/master-budgets/${userId}/`, { data }),
  deleteMasterBudget: (userId) => http.delete(`/api/recurring/master-budgets/${userId}/`),
  getUserBudget: (userId) => http.get(`/api/recurring/master-budgets/${userId}/`),
};

export default MasterBudget;
