import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  makeStyles
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { COLORS, FONTS } from 'config/constants';
import clsx from 'clsx';
import { CustomButton } from '../index';

const useStyles = makeStyles(theme => ({
  root: {

  },
  header: {
    color: COLORS.black,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  description: {
    color: COLORS.black,
    fontFamily: FONTS.Roboto,
    fontSize: 14,
    lineHeight: 1.57,
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: 1.33
    }
  },
  buttonDefault: {
    height: '75px',
    padding: '10px 8px',
    borderRadius: '25px',
    [theme.breakpoints.down('md')]: {
      height: '64px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '46px'
    }
  },
  card: {
    borderRadius: 0
  }
}));

const ButtonCard = props => {
  const { topic, description, action } = props.data;
  const { disabled, type, onClick } = props.buttonOptions;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, props.className)}>
      <Card
        className={classes.card}
        elevation={0}
      >
        <Typography
          className={clsx(classes.header, 'multiline')}
          variant="h2"
        >
          {topic}
        </Typography>
        <Typography className={clsx(classes.description, props.buttonDescription)}>
          {description}
        </Typography>
        <div className={clsx(classes.buttonDefault, props.buttonContainer)}>
          <CustomButton
            disabled={disabled}
            onClick={onClick}
            shadow
            title={action}
            type={type}
          />
        </div>
      </Card>
    </div>
  );
};

ButtonCard.defaultProps = {
  buttonOptions: {}
};

ButtonCard.propTypes = {
  buttonContainer: PropTypes.string,
  buttonDescription: PropTypes.any,
  buttonOptions: PropTypes.shape({
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string
  }),
  className: PropTypes.string,
  data: PropTypes.shape({
    topic: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.string,
  })
};

export default ButtonCard;
