import http from './http';

const User = {
  register: (formData) => http.post('/api/users/', { 
    formData, 
    options:  {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }}),
  updateById: (id, data) => http.put(`/api/users/${id}/`, { data }),

  findById: (id) => http.get(`/api/users/${id}/`),

  updateFormById: (id, formData) => http.put(`/api/users/${id}/`, {
    formData,
    options: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  }),
  getPointDetails: (id) => http.get(`/api/points/points/${id}/`),
}

export default User