import { COLORS } from 'config/constants';

const styles = theme => ({
  root: {
    padding: 12,
    display: 'flex',
    justifyContent: 'space-evenly',
    //width: '905px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 600,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 364,
      margin: '0 auto',
    }
  },
  btn: {
    borderRadius: 23,
    textTransform: 'uppercase',
    height: 33,
    width: 105,
    color: '#26A7DF',
    letterSpacing: 1.4,
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: COLORS.white,
    boxShadow: '0px 2px 4px #00000040',
    '&:hover': {
      backgroundColor: '#81d4fa',
      color: COLORS.white,
    },
    [theme.breakpoints.down('sm')]: {
      width: 51,
      height: 29,
    },
  },
  selected: {
    backgroundColor: '#26A7DF',
    color: COLORS.white,
  }
});

export default styles
