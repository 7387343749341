import { handleActions } from 'redux-actions'

const initState = {
  points: 0
};

export default handleActions(
  {
    SET_LATEST_POINTS: (state, action) => {
      return {
        ...state,
        points: action.payload,
      };
    },
  },
  initState,
);
