
import http from './http'

const Extra = {
  getStockPriceByParams: (params) => http.get('/api/stock-price/', { params }),

  createPortfolioStats: (data) => http.post('/api/portfolio-stats/', { data }),

  getTechnicalIndicatorByParams: (params) => http.get('/api/technical-indicator/', { params }),

  getAppStoreInformation: () => http.get('/api/appstore/'),
};

export default Extra
