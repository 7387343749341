import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { COLORS } from 'config/constants';
import styles from './styles';

const useStyles = makeStyles(styles);

const MessageRow = ({ data, index, style }) => {
  const classes = useStyles();
  const { user } = useSelector(({ session }) => session);
  return (
    <div
      className={classes.chatBoxSpacing}
      key={index}
      style={style}
    >
      <div
        className={classes.flexedMsg}
        style={user.id === data[index]?.userId ? { marginLeft: '30%' } : { marginRight: '30%' }}
      >
        <Typography className={classes.msgName}>
          {data[index]?.name}
        </Typography>
        <div
          className={classes.messageItem}
          style={
            user.id === data[index]?.userId
              ? { color: COLORS.white, backgroundColor: COLORS.eduBlue }
              : { color: COLORS.black, backgroundColor: '#EBEBEB' }
          }
        >
          {data[index]?.message}
        </div>
        <Typography className={classes.txtDate}>
          {`Sent at ${moment(data[index]?.createdAt).format('hh:mm a MM-DD-YY')}`}
        </Typography>
      </div>
    </div>
  );
}
MessageRow.defaultProps = {
  data: {},
  style: {}
}
  
MessageRow.propTypes = {
  data: PropTypes.shape({
    userId: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  index: PropTypes.number,
  style: PropTypes.any,
}
export default MessageRow;