import { COLORS } from 'config/constants';

const styles = theme => ({
  root: {
    padding: theme.spacing(0),
   
  },
  newTheadRow: {
    maxWidth: 1000,
    '& :nth-child(5)': {
      minWidth: 105,
      width: '110px !important'
    },
    '& :nth-child(6)': {
      width: '100px !important',
      minWidth: '90px !important'
    },
    '& :nth-child(7)': {
      minWidth: '100px !important',
      width: '110px !important',
    },
    '& :nth-child(8)': {
      maxWidth: 100,
      width: '90px !important',
      paddingLeft: 8,
      paddingRight: 35,
    },
    '& :nth-child(9)': {
      maxWidth: 100,
      width: '90px !important',
      paddingLeft: 0,
      paddingRight: 20
    },
    '& :nth-child(10)': {
      maxWidth: 105,
      width: 95,
    },
    '& :nth-child(11)': {
      maxWidth: 105,
      width: 95,
    },
  },
  logo: {
    maxHeight: 60,
    left: '50%!important',
    top: '50%!important',
    transform: 'translate(-50%, -50%)'
  },
  actionButton: {
    fontSize: 13,
    color: `${COLORS.middleDarkBule}!important`,
  },
  loader: {
    padding: theme.spacing(0),
  },
  buyButton: {
    justifyContent: 'center',
  },
  blueBuy: {
    color: COLORS.middleDarkBule,
  },
  stockModal: {
    '& .MuiDialog-paper': {
      maxWidth: 900,
      borderRadius: theme.spacing(8)
    }
  },
  securityLink: {
    '&& span': {
      color: COLORS.middleDarkBule,
    }
  },
  txtGreen: {
    color: COLORS.darkGreen,
  },
  bgGreen: {
    backgroundColor: COLORS.darkGreen,
  },
  bgTransparent: {
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
});

export default styles
