import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const Error = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

export default Error;
